import { useState, ReactNode, useRef, useEffect } from 'react';

interface AccordionItem {
  title: string;
  content: ReactNode;
  defaultOpened?: boolean;
}

interface AccordionProps {
  items: AccordionItem[];
  className?: string;
}

const Accordion: React.FC<AccordionProps> = ({ items, className }) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const contentRefs = useRef<(HTMLDivElement | null)[]>([]);

  const toggleAccordion = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  useEffect(() => {
    items.forEach((item, index) => {
      if (item.defaultOpened) {
        setOpenIndex(index);
      }
    });
  }, []);

  return (
    <div className={`space-y-4 w-full ${className}`}>
      {items.map((item, index) => (
        <div
          key={index}
          className="border border-gray-300 rounded-lg overflow-hidden shadow-sm transition-all duration-300"
        >
          <button
            type="button"
            onClick={() => toggleAccordion(index)}
            className="w-full text-left px-4 py-3 bg-gradient-to-r-darkGreen hover:opacity-90 text-white font-medium focus:outline-none focus:ring focus:ring-darkGreen"
          >
            <div className="flex items-center justify-between">
              <span>{item.title}</span>
              <svg
                className={`w-5 h-5 transition-transform transform ${
                  openIndex === index ? 'rotate-180' : 'rotate-0'
                }`}
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M5.23 7.21a.75.75 0 011.06-.02L10 10.94l3.71-3.75a.75.75 0 111.08 1.04l-4.25 4.3a.75.75 0 01-1.08 0L5.23 8.23a.75.75 0 01-.02-1.06z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </button>
          <div
            ref={(el) => (contentRefs.current[index] = el)}
            style={{
              height:
                openIndex === index
                  ? contentRefs.current[index]?.scrollHeight
                  : 0,
            }}
            className={`overflow-hidden transition-height duration-300`}
          >
            <div className="px-4 py-4 bg-white text-gray-700">
              {item.content}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
