import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useIsAuth } from "../../hooks/useIsAuth"
import Layout from "../components/Layout"
import { useParams } from "react-router-dom";
import { useEffect } from 'react'
import { sistemaActions } from "../recucer";
import { ListHeader } from "../components/ListHeader";
import { DefaultButton } from "../components/DefaultButton";
import Loader from "../components/Loader";
import { UserForm } from "../components/UserForm";
import { UserUnidades } from "../components/UserUnidades";
import { useTranslation } from "react-i18next";

export const UsuarioEdit = () => {
  useIsAuth();

  const { id } = useParams();

  const usuario = useSelector((state: RootState) => state.sistema.usuario || null)
  const isLoading = useSelector((state: RootState) => !!state.sistema.isLoading)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!id) return;

    dispatch(sistemaActions.usuarioRequest(id))
  }, [dispatch, id])

  const { t } = useTranslation();

  return (
    <Layout>
      <ListHeader label={t('UsuarioEdit')}>
        <DefaultButton ml={4} to="/usuarios">{t('SistemaVoltar')}</DefaultButton>
        {id && id !== 'novo' && <UserUnidades user_id={id} />}
      </ListHeader>

      <Loader isLoading={isLoading} />
      <UserForm currentItem={usuario} />
    </Layout>
  )
}