import { Button, Flex, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next";
import imagesUrl from "../../utils/imagesUrl";

export const LinguagemPadrao = () => {
  const linguas = [
    {
      name: 'Português',
      value: 'pt-BR',
      flag: `${imagesUrl}icons/ptbrFlag.svg`,
    },
    {
      name: 'English',
      value: 'en',
      flag: `${imagesUrl}icons/enFlag.svg`,
    },
    {
      name: 'Spanish',
      value: 'es',
      flag: `${imagesUrl}icons/esFlag.svg`,
    },
    {
      name: 'German',
      value: 'de',
      flag: `${imagesUrl}icons/deFlag.svg`,
    },
  ]

  const { t, i18n } = useTranslation()

  return (
    <Flex px={2} direction={"column"}>
      <Text mx={"auto"}>{t('MenuSelectYourLanguage')}</Text>
      <Flex width={"full"} wrap={"wrap"} bg="white">
        {linguas.map((i) =>
        <Flex key={i.name} width={"50%"} px={0.5}>
          <Button rounded={'sm'} zIndex={214748364} colorScheme={i18n.language == i.value ? 'blackAlpha' : 'gray'} onClick={() => i18n.changeLanguage(i.value)} mb={1} p={0} size="xs" width={"full"} mx={"auto"}>
            <Flex justifyContent={"center"} pr={1} width={"50%"}>
              <img src={i.flag} width={"25px"} alt="" />
            </Flex>
            <Flex width={"75%"} justifyContent={"left"}>
              {i.name}
            </Flex>
          </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}