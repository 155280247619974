import imagesUrl from "../../utils/imagesUrl";

export const getGifSiloVolume = (porcentagem: number, tipo_silo: string) => {
  const porcentagemAjustada = parseInt(porcentagem.toFixed(0));

  const cilindrico = (tipo_silo ?? '').trim() == 'Cilindrico';

  const gifs = [
    {
      range1: 0,
      range2: 10,
      gif: cilindrico ? `${imagesUrl}armazenamentos/0_10.gif` : `${imagesUrl}armazenamentos/0_20_predio.gif`,
    },
    {
      range1: 11,
      range2: 20,
      gif: cilindrico ? `${imagesUrl}armazenamentos/10_20.gif` : `${imagesUrl}armazenamentos/0_20_predio.gif`,
    },
    {
      range1: 21,
      range2: 30,
      gif: cilindrico ? `${imagesUrl}armazenamentos/20_30.gif` : `${imagesUrl}armazenamentos/20_40_predio.gif`,
    },
    {
      range1: 31,
      range2: 40,
      gif: cilindrico ? `${imagesUrl}armazenamentos/30_40.gif` : `${imagesUrl}armazenamentos/20_40_predio.gif`,
    },
    {
      range1: 41,
      range2: 50,
      gif: cilindrico ? `${imagesUrl}armazenamentos/40_50.gif` : `${imagesUrl}armazenamentos/40_60_predio.gif`,
    },
    {
      range1: 51,
      range2: 60,
      gif: cilindrico ? `${imagesUrl}armazenamentos/50_60.gif` : `${imagesUrl}armazenamentos/40_60_predio.gif`,
    },
    {
      range1: 61,
      range2: 70,
      gif: cilindrico ? `${imagesUrl}armazenamentos/60_70.gif` : `${imagesUrl}armazenamentos/60_80_predio.gif`,
    },
    {
      range1: 71,
      range2: 80,
      gif: cilindrico ? `${imagesUrl}armazenamentos/70_80.gif` : `${imagesUrl}armazenamentos/60_80_predio.gif`,
    },
    {
      range1: 81,
      range2: 90,
      gif: cilindrico ? `${imagesUrl}armazenamentos/80_90.gif` : `${imagesUrl}armazenamentos/80_100_predio.gif`,
    },
    {
      range1: 91,
      range2: 100,
      gif: cilindrico ? `${imagesUrl}armazenamentos/90_100.gif` : `${imagesUrl}armazenamentos/80_100_predio.gif`,
    },
  ];

  const gif = gifs.find(
    (i) => i.range1 <= porcentagemAjustada && i.range2 >= porcentagemAjustada
  );

  if (gif) return gif.gif;

  return `${imagesUrl}armazenamentos/0_10.gif`;
};
