import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useIsAuth } from "../../hooks/useIsAuth"
import Layout from "../components/Layout"
import { useParams } from "react-router-dom";
import { useEffect } from 'react'
import { sistemaActions } from "../recucer";
import { ListHeader } from "../components/ListHeader";
import { DefaultButton } from "../components/DefaultButton";
import Loader from "../components/Loader";
import { Formik, Form } from 'formik'
import validateForm from "../../utils/validateForm";
import InputField from "../components/InputField";
import InputCheck from "../components/InputCheck";
import { Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import InputImage from "../components/InputImage";
import InputNumber from "../components/InputNumber";
import InputSelect from "../components/InputSelect";
import InputEditor from "../components/InputEditor";
import { ListHeaderSecondary } from "../components/ListHeaderSecondary";

export const InsetosEdit = () => {
  useIsAuth();

  const { id } = useParams();

  const inseto = useSelector((state: RootState) => state.sistema.inseto)
  const isLoading = useSelector((state: RootState) => !!state.sistema.isLoading)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!id) return;

    dispatch(sistemaActions.insetoRequest(id))
  }, [dispatch, id])

  const { t } = useTranslation();

  return (
    <Layout>
      <ListHeader label={t('InsetosEditTitulo')}>
        <DefaultButton ml={4} to="/insetos"> {t('SistemaVoltar')} </DefaultButton>
      </ListHeader>

      <Loader isLoading={isLoading} />

      {inseto && <Formik
        initialValues={inseto}
        enableReinitialize
        onSubmit={(values, { setErrors }) => {
          const validation = validateForm({
            'nome': 'required',
            'ppm_mortalidade': 'required',
            'horas_mortalidade': 'required',
            'imagem': 'required',
          }, values, t);

          if (Object.keys(validation).length > 0) {
            setErrors(validation)
            return;
          }
          console.log("inseto form", values);
          dispatch(sistemaActions.saveInsetosRequest({
            ...values,
            changed: true,
          }));
        }}
      >
        {({ values }) => (
          <Form>
            <Flex width="full" wrap={"wrap"}>
              <InputField label={t('ClienteEditInputNome')} name="nome" autoFocus />
              <InputNumber decimalPlaces={0} name="horas_mortalidade" label={t('InsetosEditHorasMortalidade')} width={"25%"} />
              <InputSelect name="ppm_mortalidade" label={t('InsetosEditPPMMortalidade')} width={"25%"}>
                <option value="400">400ppm</option>
                <option value="500">500ppm</option>
                <option value="600">600ppm</option>
                <option value="700">700ppm</option>
                <option value="800">800ppm</option>
              </InputSelect>

              <InputSelect name="tipo" label={t('InsetosEditTipo')} width={"25%"}>
                <option value="1">{t('InsetosEditTipo1')}</option>
                <option value="2">{t('InsetosEditTipo2')}</option>
              </InputSelect>
              <InputSelect name="resistencia" label={t('InsetosEditResistencia')} width={"25%"}>
                <option value="menor">{t('InsetosEditResistenciamenor')}</option>
                <option value="maior">{t('InsetosEditResistenciamaior')}</option>
              </InputSelect>
              <InputImage name="imagem" label={t('InsetosEditImagem')} />

              <ListHeaderSecondary label="Larva, Pulpa e Ovos" />
              <InputImage name="larvaimagem" label={t('InsetosEditImagem')} />
              <InputNumber decimalPlaces={0} name="larva_horas_mortalidade" label={t('InsetosEditHorasMortalidade')} width={"25%"} />
              <InputSelect name="larva_ppm_mortalidade" label={t('InsetosEditPPMMortalidade')} width={"25%"}>
                <option value="400">400ppm</option>
                <option value="500">500ppm</option>
                <option value="600">600ppm</option>
                <option value="700">700ppm</option>
                <option value="800">800ppm</option>
              </InputSelect>

              <InputCheck label={t('InsetosEditAtivo')} name="ativo" />
              <InputCheck label={t('InsetosEditImagemGrande')} name="imagem_grande" />


              <div style={{ width: '100%' }}></div>

              <InputEditor name="descricao_html" label={t('InsetosEditDescricaoDetalhada') + ' (Português)'} />
              <InputEditor name="descricao_html_espanhol" label={t('InsetosEditDescricaoDetalhada') + ' (Spanish)'} />
              <InputEditor name="descricao_html_alemao" label={t('InsetosEditDescricaoDetalhada') + ' (German)'} />
              <InputEditor name="descricao_html_ingles" label={t('InsetosEditDescricaoDetalhada') + ' (English)'} />
            </Flex>
            <DefaultButton type="submit" mt={2}>{t('SistemaSalvar')} {t('InsetosEditTitulo')}</DefaultButton>
          </Form>
        )}
      </Formik>}
    </Layout>
  )
}