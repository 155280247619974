import { BodyCenterVisit } from "../types/body_center_visit";

export const newBodyCenterVisit = (): BodyCenterVisit => {
  return {
    date: "",
    observation: "",
    client_id: "",
    client_unit_id: "",
    occurrences: [],
    nonconformities: [],
    sightings: [],
    traces: [],
  };
};
