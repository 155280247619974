import { FiBook } from "react-icons/fi"
import { DefaultButton } from "../../sistema/components/DefaultButton"
import { Button, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react"
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary"
import { useTranslation } from "react-i18next"
import { PreparacaoInicialForm } from "./PreparacaoInicialForm"
import { useSelector } from "react-redux"
import { RootState } from "../../app/mainReducer"

export const DashboardAvaliacaoInicial = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { t } = useTranslation();
  const dashboardleitura = useSelector((state: RootState) => state?.leituras?.dashboard_leituras);

  return (
    <>
      {!!dashboardleitura?.preparacaoInicial?.relatorio &&
        <Link target="_blank" alignItems={"center"} href={dashboardleitura?.preparacaoInicial?.relatorio}>
          <DefaultButton className="no-print" size={"xs"} alignItems={"center"}>

            <FiBook />&nbsp; {t('DashboardAvaliacaoInicial')}
          </DefaultButton>
        </Link>}

      <Modal autoFocus={false} returnFocusOnClose={false} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"3xl"}>
        <ModalOverlay />
        <ModalContent top={-10}>
          <ModalHeader>
            <ListHeaderSecondary label={t('DashboardAvaliacaoInicial')} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <PreparacaoInicialForm />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blackAlpha" onClick={onClose}>{t('SistemaVoltar')}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}