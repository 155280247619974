import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/mainReducer";
import { useIndexedDB } from "react-indexed-db-hook";
import { leituraActions } from "../leituras/reducer";

export function useDeleteImagesIndexDb() {
  const imagemIdsParaApagar = useSelector((state: RootState) => state.leituras?.imagemIdsParaApagar);
  const dispatch = useDispatch();

  const { deleteRecord } = useIndexedDB('images');

  useEffect(() => {
    const deleteItem = async (key: string) => {
      return await deleteRecord(key)
    }

    (imagemIdsParaApagar ?? []).forEach(async (i: any) => {
      const retorno = await deleteItem(i);

      console.log('deleteRecordImage', retorno);

      dispatch(leituraActions.removeImagemLocalmente(i))
    })

    console.log(imagemIdsParaApagar)
  }, [imagemIdsParaApagar, deleteRecord, dispatch]);
}
