import { Form, Formik } from "formik";
import { User } from "../types/user"
import { Button, Flex } from "@chakra-ui/react";
import validateForm from "../../utils/validateForm";
import InputField from "./InputField";
import { DefaultButton } from "./DefaultButton";
import InputCheck from "./InputCheck";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import React, { useEffect } from 'react'
import { sistemaActions } from "../recucer";
import InputSelect from "./InputSelect";
import InputMultiSelect from "./InpurMultiSelect";
import { useTranslation } from 'react-i18next'
import InputCheckInteiro from "./InputCheckInteiro";
import Accordion from "./Accordion";
import ResponsiveTable, { TableHeaders } from "./ResponsiveTable";
import { formatData } from "../../utils/data";
import { Filhos } from "./Filhos";
import { newUserBodyCenterAccess } from "../data/user_body_center_access";
import { UserBodyCenterAccess } from "../types/user_body_center_access";

interface IProps {
  currentItem: User | null;
  onClose?: () => void;
}

export const UserForm = ({ currentItem, onClose }: IProps) => {
  const todosClientes = useSelector((state: RootState) => state.sistema.todosClientes || []);
  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);

  const { t } = useTranslation();

  const eAdminsitrador = useSelector((state: RootState) => (state.sistema.user as any)?.administrador == 1)

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(sistemaActions.todosClientesRequest())
  }, [dispatch])

  const tamanhoCampo = isMobile ? '100%' : '50%';
  const tamanhoCampoCheck = isMobile ? '100%' : 'auto';

  return (
    <>
      {currentItem && <Formik
        enableReinitialize
        initialValues={currentItem}
        onSubmit={(val, { setErrors }) => {
          const validation = validateForm({ name: 'required' }, val, t)
          if (Object.keys(validation).length > 0) {
            setErrors(validation)
            return;
          }

          dispatch(sistemaActions.saveUsuarioRequest(val));
        }}
      >
        {({ values }) => (
          <Form>

            <Flex width="full" wrap="wrap">
              <InputField label={t('UsuarioNome')} name="name" width={tamanhoCampo} />
              <InputField label={t('UsuarioCelular')} name="celular" type="tel" width={tamanhoCampo} />
              <InputField label={t('UsuarioEmail')} name="email" type="email" width={tamanhoCampo} />
              <InputField label={t('UsuarioSenha')} name="password" type="password" width={tamanhoCampo} />
              <InputSelect label={t('UserRedirectToAfterLogin')} name="redirect_to" width={'full'}>
                <option value="/">{t('UserRedirectToAfterLoginStartPage')}</option>
                <option value="/dashboard">{t('UserRedirectToAfterLoginDashboard')}</option>
                <option value="/body-center-dashboard">{t('UserRedirectToAfterLoginBodyCenter')}</option>
              </InputSelect>

              <Accordion className="mt-4" items={[
                {
                  title: 'Permissões do Cliente',
                  content: (
                    <div className="w-full flex flex-wrap pb-48">
                      <InputSelect label={t('UsuarioCliente')} name="cliente_id" width={tamanhoCampo}>
                        {todosClientes && todosClientes.map((i) => (
                          <option value={i.id} key={i.id}>{i.nome}</option>
                        ))}
                      </InputSelect>
                      {values.unidades && <InputMultiSelect label={t('UsuarioResponsávelQuaisUnidadesCliente')} name="unidades" options={
                        !todosClientes ? [] : (todosClientes.find((i: any) => i.id == values.cliente_id)?.unidades ?? []).map((i) => {
                          return {
                            label: i.nome_referencia,
                            value: i.id as number,
                          }
                        })
                      } width={tamanhoCampo} />}


                      <Filhos
                        minH={''}
                        name="unitsWithAccessInBodyCenter"
                        tituloForm={t('UserUnitsWithAccessInBodyCenter')}
                        editTituloForm={t('UserAccess')}
                        canEdit={false}
                        novoItemObject={newUserBodyCenterAccess()}
                        validation={{ client_id: "required", client_unit_id: "required" }}
                        formatValuesBeforeSave={(values) => ({
                          ...values,
                          client: todosClientes.find((i: any) => i.id == values.client_id),
                          clientUnit: todosClientes.find((i: any) => i.id == values.client_id)?.unidades.find((i: any) => i.id == values.client_unit_id),
                        })}
                        headers={[
                          {
                            label: t('UsuarioCliente'),
                            wrapped: false,
                            render: (item) => <React.Fragment>
                              {item?.client?.nome} <br />
                              {item?.clientUnit?.nome_referencia}
                            </React.Fragment>,
                          },
                        ] as TableHeaders<UserBodyCenterAccess>[]}
                        form={(valuesLocal) => (
                          <Flex width={"full"} wrap={"wrap"}>
                            <InputSelect label={t('UsuarioCliente')} name="client_id" width={tamanhoCampo}>
                              {todosClientes && todosClientes.map((i) => (
                                <option value={i.id} key={i.id}>{i.nome} </option>
                              ))}
                            </InputSelect>

                            <InputSelect label={t('DashboardDadosClienteUnidade')} name="client_unit_id" width={tamanhoCampo}>
                              {todosClientes && (todosClientes.find((i: any) => i.id == valuesLocal.client_id)?.unidades ?? []).filter((j) => ! (values.unitsWithAccessInBodyCenter ?? []).some((i => i.client_unit_id == j.id))).map((i) => (
                                <option value={i.id} key={i.id}>{i.nome_referencia} - {i.cidade} </option>
                              ))}
                            </InputSelect>
                          </Flex>
                        )}
                      />
                    </div>
                  ),
                },
                {
                  title: 'Permissões do Usuário',
                  content: (
                    <div className="w-full flex flex-wrap gap-3">
                      <InputCheck width={tamanhoCampoCheck} ml={1} label={t('UsuarioAtivo')} name="ativo" />
                      {eAdminsitrador && <InputCheck ml={1} width={tamanhoCampoCheck} label={t('UsuarioAdministrador')} name="administrador" />}
                      {eAdminsitrador && <InputCheck ml={1} width={tamanhoCampoCheck} label={t('UsuarioDesenvolvedor')} name="e_desenvolvedor" />}
                      {eAdminsitrador && <InputCheck ml={1} width={tamanhoCampoCheck} label={t('UsuarioOperador')} name="e_operador" />}
                      {eAdminsitrador && <InputCheckInteiro mt={1} width={tamanhoCampoCheck} label={t('UsuarioExterno')} name="usuario_externo" />}
                    </div>
                  ),
                },
                {
                  title: 'Acessos',
                  content: (
                    <div>
                      {values?.acessos && <ResponsiveTable isSmall headers={[
                        {
                          label: t('UserLoginsData'),
                          wrapped: true,
                          render: (item) => formatData(item.created_at as string),
                          notClicable: true,
                        },
                        {
                          label: t('UserLoginsDispositivo'),
                          wrapped: false,
                          render: (item) => item.user_agent,
                          notClicable: true,
                        },
                      ]} data={values.acessos} />}
                    </div>
                  ),
                }
              ]} />
              <hr style={{ width: '100%' }} />
              <DefaultButton type="submit" mt={4}>{t('DashboardSalvar')} {t('UsuarioEdit')}</DefaultButton>
              {typeof onClose === 'function' && <Button onClick={onClose} ml={2} mt={4} colorScheme="blackAlpha">{t('DashboardVoltar')}</Button>}
            </Flex>
          </Form>)}
      </Formik>}
    </>
  )
}