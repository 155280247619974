import React from "react";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";
import { useField } from "formik";
import { InputHTMLAttributes } from "react";
import Select from 'react-select';
import { useTranslation } from "react-i18next";

export type InputLocalMultiSearchProps = InputHTMLAttributes<HTMLSelectElement> & {
  name: string;
  label: string;
  options: {
    label: string;
    value: string | number;
  }[];
  bgNone?: boolean;
};

const InputLocalMultiSearch: React.FC<InputLocalMultiSearchProps> = ({
  label,
  options,
  bgNone = false,
  size: _,
  width,
  ...props
}) => {
  const [field, { error }, { setValue }] = useField(props as any);
  const { t } = useTranslation();

  // Encontra os valores selecionados baseado no array de valores do campo
  const selectedValues = options.filter(option =>
    field.value && field.value.includes(option.value)
  );

  return (
    <FormControl isInvalid={!!error} width={width} p={1}>
      <FormLabel htmlFor={field.name}>{label}</FormLabel>
      <Select
        isMulti
        options={options}
        value={selectedValues}
        onChange={(selectedOptions) => {
          setValue(selectedOptions ? selectedOptions.map(option => option.value) : [])
        }}
        placeholder={t('SelectPlaceholder')}
        isClearable
        noOptionsMessage={() => t('NoOptionsMessage')}
        styles={{
          menu: (provided) => ({
            ...provided,
            zIndex: 9999
          })
        }}
      />
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};

export default InputLocalMultiSearch;