import { BodyCenterVisitSighting } from "../types/body_center_visit_sighting";

export const newBodyCenterVisitSighting = (): BodyCenterVisitSighting => {
  return {
    body_center_visits_id: "",
    sector: "",
    quantity_alive: 0,
    quantity_death: 0,
  };
};
