import { useField, Formik, Form } from "formik";
import { Button, Flex, Heading, Img, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { useState } from 'react'
import { FiTrash } from "react-icons/fi";
import { RootState } from "../../app/mainReducer";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ResponsiveTable, { TableHeaders } from "../../sistema/components/ResponsiveTable";
import { AvaliacaoInicialInseto } from "../types/avaliacao_inicial_inseto";
import { novaAvaliacaoInicialInseto } from "../data/avaliacao_inicial_inseto";
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import imagesUrl from "../../utils/imagesUrl";
import InputNumber from "../../sistema/components/InputNumber";
import InputImageIndexDb from "../../sistema/components/InputImageIndexDb";

const Sitophilus = imagesUrl + "sitophilus.png"
const Rhyzopertha = imagesUrl + "rhyzopertia.png"
const Tribollium = imagesUrl + "tribolium.png"
const Oryzaephilus = imagesUrl + "oryzaephilus.png"
const Cryptolestes = imagesUrl + "cryptolestes.png"

export const AvaliacaoInicialProdutoArmazenadoInsetos = ({ name }: { name: string }) => {
  const [, { value }, { setValue }] = useField(name);
  const eDesenvolvedor = useSelector((state: RootState) => (state.sistema.user as any)?.e_desenvolvedor == 1);
  const { t } = useTranslation()

  const insetos = [
    {
      name: 'Sitophilus',
      image: Sitophilus,
      key: 'qt_sitophilus',
    },
    {
      name: 'Rhyzopertha',
      image: Rhyzopertha,
      key: 'qt_rhyzopertha',
    },
    {
      name: 'Tribollium',
      image: Tribollium,
      key: 'qt_tribolium',
    },
    {
      name: 'Oryzaephilus',
      image: Oryzaephilus,
      key: 'qt_oryzaephilus',
    },
    {
      name: 'Cryptolestes',
      image: Cryptolestes,
      key: 'qt_cryptolestes',
    },
  ]


  const headers: TableHeaders<AvaliacaoInicialInseto>[] = [
    {
      label: "N°",
      wrapped: false,
      render: (item) => item.sequencial
    },
    {
      label: 'Sitophilus',
      wrapped: false,
      render: (item) => item.qt_sitophilus,
    },
    {
      label: 'Rhyzopertha',
      wrapped: false,
      render: (item) => item.qt_rhyzopertha,
    },
    {
      label: 'Tribollium',
      wrapped: false,
      render: (item) => item.qt_tribolium,
    },
    {
      label: 'Oryzaephilus',
      wrapped: false,
      render: (item) => item.qt_oryzaephilus,
    },
    {
      label: 'Cryptolestes',
      wrapped: false,
      render: (item) => item.qt_cryptolestes,
    },
    {
      label: t('SistemaAções'),
      wrapped: true,
      render: (item: any, index: any) => <>{<Button size={"sm"} colorScheme="red" onClick={() => onDeleteItem(index)}><FiTrash /></Button>}</>,
      notClicable: true,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [currentItem, setCurrentItem] = useState<AvaliacaoInicialInseto | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure()

  const onOpenModal = (index: string | number) => {
    if (index === 'novo') {
      setCurrentIndex(-1)
      setCurrentItem({
        ...novaAvaliacaoInicialInseto(),
        sequencial: value.length + 1,
      })
      onOpen()
      return;
    }

    setCurrentIndex(index as number);
    setCurrentItem(value[index]);
    onOpen()
  }

  const onSaveModal = (form: AvaliacaoInicialInseto) => {
    const values = JSON.parse(JSON.stringify(value)).filter((i: any) => !i?.deleted);
    const valuesDeleted = JSON.parse(JSON.stringify(value)).filter((i: any) => i?.deleted);

    form = {
      ...form,
      changed: true,
    }

    if (currentIndex === -1) {
      const valuesToPush = [
        ...values,
        ...valuesDeleted,
      ]
      valuesToPush.push(form);
      setValue(valuesToPush)
      onClose();
      setCurrentItem(null);
      verificaPontuacao(valuesToPush)
      return;
    }

    values[currentIndex] = {
      ...values[currentIndex],
      ...form,
    };
    setValue([
      ...values,
      ...valuesDeleted,
    ])
    onClose();
    setCurrentItem(null);
    setCurrentIndex(-1);
    verificaPontuacao(values)
  }

  const onDeleteItem = (index: number) => {
    const values = JSON.parse(JSON.stringify(value)).filter((i: any) => !i?.deleted);
    const valuesDeleted = JSON.parse(JSON.stringify(value)).filter((i: any) => i?.deleted);

    if (index === -1) return;

    if (!values[index]?.id) {
      values.splice(index, 1);
      setValue([
        ...values,
        ...valuesDeleted,
      ])
      return;
    }

    values[index] = {
      ...values[index],
      deleted: true,
      changed: true,
    };

    setValue([
      ...values,
      ...valuesDeleted,
    ])
  }

  const [, { value: valuepontuacao_insetos }, { setValue: setvaluepontuacao_insetos }] = useField('pontuacao_insetos');

  const getPontuacao = (quantidadeTotal: number) => {
    if (quantidadeTotal <= 14) return 0;
    if (quantidadeTotal <= 29) return 1;
    if (quantidadeTotal <= 49) return 2;
    if (quantidadeTotal <= 99) return 3;
    return 4;
  }

  const verificaPontuacao = (naoDeletados: AvaliacaoInicialInseto[]) => {
    if (name == 'insetos_final') return;
    let quantidadeTotal = 0;
    for (const inseto of (naoDeletados ?? [])) {
      quantidadeTotal += (inseto.qt_cryptolestes * 1 + inseto.qt_oryzaephilus * 1 + inseto.qt_rhyzopertha * 1 + inseto.qt_sitophilus * 1 + inseto.qt_tribolium * 1)
    }
    setvaluepontuacao_insetos(getPontuacao(quantidadeTotal));
  }

  return (
    <>
      <Flex width={"full"} justifyContent={"center"}>
        <Heading size={"md"} mt={6} mb={2} mx={"auto"}>
          {t("AvaliacaoInicialAmostrasInsetos")}
        </Heading>
      </Flex>
      {value && <ResponsiveTable onClick={(item: any) => onOpenModal(item.index)} headers={headers} data={value.filter((i: any) => !i?.deleted)} />}
      <DefaultButton mx={"auto"} mt={1} onClick={() => onOpenModal('novo')}>
        {t('AvaliacaoInicialNovaAmostra')}
      </DefaultButton>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent top={-10}>
          <ModalHeader>
            <ListHeaderSecondary label={currentItem?.id ? t('SistemaEditar') + ' ' + t('AvaliacaoInicialAmostra') : t('SistemaNova') + ' ' + t('AvaliacaoInicialAmostra')} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {currentItem && <Formik
              enableReinitialize
              initialValues={currentItem}
              onSubmit={(val, { setErrors }) => {
                onSaveModal(val);
              }}
            >
              {({ values }) => (
                <Form style={{
                  maxHeight: "calc(100vh - 220px)",
                  overflowY: 'auto'
                }}>
                  <Flex width="full" wrap="wrap">
                    <Flex width={"full"} justifyContent={"center"} py={2}>
                      <InputImageIndexDb name="imagem" label={`${t('AvaliacaoInicialFoto')} ${t('DashboardAmostraMin')}`} />
                    </Flex>
                    <Flex direction={"column"} width={"full"}>
                      {insetos.map((i, index) => (
                        <Flex key={`${index}`} width={"full"} wrap={"wrap"}>
                          <Flex width={"30%"} justifyContent={"center"}>
                            <Img src={i.image} width={"auto"} height={"80px"} />
                          </Flex>
                          <Flex width={"70%"}>
                            <InputNumber decimalPlaces={0} type="tel" label={i.name} name={i.key} />
                          </Flex>
                        </Flex>
                      ))}
                    </Flex>

                    <hr style={{ width: '100%' }} />
                    <Flex width={"full"} justifyContent={"right"}>
                      <DefaultButton type="submit" mt={4}>{t('SistemaSalvar')} {t('AvaliacaoInicialAmostra')}</DefaultButton>
                      <Button onClick={onClose} ml={2} mt={4} colorScheme="blackAlpha">{t('SistemaFechar')}</Button>
                    </Flex>
                  </Flex>
                </Form>)}
            </Formik>}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}