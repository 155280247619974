import { Box, Flex } from "@chakra-ui/react"

export const Sinaleiro = ({ cor, animation, largura = '15px', onClick, mx }: { cor: string; animation?: string; largura?: string; onClick?: (val: number) => void; mx?: string }) => {
  const sinais = {
    'red': {
      colorActive: '#FF0000',
      colorInactive: '#4a0101',
    },
    'yellow': {
      colorActive: '#FFFF00',
      colorInactive: '#808003',
    },
    'green': {
      colorActive: '#00FF00',
      colorInactive: '#013301',
    },
  }

  const active = 'inset 0px 10px 10px rgba(255, 255, 255, 0.5)'
  const inactive = "inset 0px 5px 5px rgba(0, 0, 0, 0.3)"

  return (
    <Flex mx={mx}>
      <Flex direction={"column"} px={2} py={2} borderRadius={"10px 10px 10px 10px"} bg={"#1e1e1f"}>
        <Box cursor={typeof onClick === 'function' ? 'pointer' : 'default'} onClick={() => typeof onClick === 'function' ? onClick(1) : () => { }} animation={cor == 'red' ? animation : ''} width={largura} boxShadow={cor == 'red' ? active : inactive} height={largura} mb={0.5} borderRadius={"50%"} bg={cor == 'red' ? sinais.red.colorActive : sinais.red.colorInactive}></Box>
        <Box cursor={typeof onClick === 'function' ? 'pointer' : 'default'} onClick={() => typeof onClick === 'function' ? onClick(2) : () => { }} animation={cor == 'yellow' ? animation : ''} width={largura} boxShadow={cor == 'yellow' ? active : inactive} height={largura} mb={0.5} borderRadius={"50%"} bg={cor == 'yellow' ? sinais.yellow.colorActive : sinais.yellow.colorInactive}></Box>
        <Box cursor={typeof onClick === 'function' ? 'pointer' : 'default'} onClick={() => typeof onClick === 'function' ? onClick(3) : () => { }} animation={cor == 'green' ? animation : ''} width={largura} boxShadow={cor == 'green' ? active : inactive} height={largura} mb={0.5} borderRadius={"50%"} bg={cor == 'green' ? sinais.green.colorActive : sinais.green.colorInactive}></Box>
      </Flex>
    </Flex>
  )
}