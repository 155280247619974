import { useField, Formik, Form, useFormikContext } from "formik";
import { Box, Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { useState } from 'react'
import { FiTrash } from "react-icons/fi";
import ResponsiveTable, { TableHeaders } from "../../sistema/components/ResponsiveTable";
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import { LeituraSensor } from "../types/leitura_sensor";
import { novaLeituraSensor } from "../data/leitura_sensor";
import InputNumber from "../../sistema/components/InputNumber";
import InputSelect from "../../sistema/components/InputSelect";
import { Silo } from "./Silo";
import { RootState } from "../../app/mainReducer";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

interface IProps {
  tipo_silo?: string;
}

export const LeiturasSensores = ({ tipo_silo = "" }: IProps) => {
  const [, { value }, { setValue }] = useField('sensores');
  const eDesenvolvedor = useSelector((state: RootState) => (state.sistema.user as any)?.e_desenvolvedor == 1);
  const { t } = useTranslation()

  const headers: TableHeaders<LeituraSensor>[] = [
    {
      label: t('LeiturasSensoresProfundidade'),
      wrapped: true,
      render: (item) => `${item.profundidade} ${t('SistemaMetros')}`,
    },
    {
      label: t('LeiturasSensoresSensor'),
      wrapped: true,
      render: (item) => item.sensor,
    },
    {
      label: t('LeiturasSensoresConfiguração'),
      wrapped: true,
      render: (item) => !item.configuracao ?  '' : (item.configuracao.trim() == 'Injeção inferior' ? t('DashboardInpecaoInferior') : (item.configuracao.trim() == 'Injeção superior' ? t('DashboardInpecaoSuperior') : ((item.configuracao.trim() == 'Retorno' ? t('DashboardRetorno') : '')))),
    },
    {
      label: t('SistemaAções'),
      wrapped: true,
      render: (item: any, index: any) => <>{eDesenvolvedor && <Button size={"sm"} colorScheme="red" onClick={() => onDeleteItem(index)}><FiTrash /></Button>} </>,
      notClicable: true,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [currentItem, setCurrentItem] = useState<LeituraSensor | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure()

  const onOpenModal = (index: string | number) => {
    if (index === 'novo') {
      setCurrentIndex(-1)
      setCurrentItem(novaLeituraSensor())
      onOpen()
      return;
    }

    setCurrentIndex(index as number);
    setCurrentItem(value[index]);
    onOpen()
  }

  const onSaveModal = (form: LeituraSensor) => {
    const values = JSON.parse(JSON.stringify(value)).filter((i: any) => !i?.deleted);
    const valuesDeleted = JSON.parse(JSON.stringify(value)).filter((i: any) => i?.deleted);

    form = {
      ...form,
      changed: true,
    }

    if (currentIndex === -1) {
      const valuesToPush = [
        ...values,
        ...valuesDeleted,
      ]
      valuesToPush.push(form);
      setValue(valuesToPush)
      onClose();
      setCurrentItem(null);
      return;
    }

    values[currentIndex] = {
      ...values[currentIndex],
      ...form,
    };
    setValue([
      ...values,
      ...valuesDeleted,
    ])
    onClose();
    setCurrentItem(null);
    setCurrentIndex(-1);
  }

  const onDeleteItem = (index: number) => {
    const values = JSON.parse(JSON.stringify(value)).filter((i: any) => !i?.deleted);
    const valuesDeleted = JSON.parse(JSON.stringify(value)).filter((i: any) => i?.deleted);

    if (index === -1) return;

    if (!values[index]?.id) {
      values.splice(index, 1);
      setValue([
        ...values,
        ...valuesDeleted,
      ])
      return;
    }

    values[index] = {
      ...values[index],
      deleted: true,
      changed: true,
    };

    setValue([
      ...values,
      ...valuesDeleted,
    ])
  }

  const sensores = [
    {
      name: 'P1',
      value: 1,
    },
    {
      name: 'P2',
      value: 2,
    },
    {
      name: 'P3',
      value: 3,
    },
    {
      name: 'P4',
      value: 4,
    },
    {
      name: 'P5',
      value: 5,
    },
    {
      name: 'P6',
      value: 6,
    },
    {
      name: 'P7',
      value: 7,
    },
    {
      name: 'P8',
      value: 8,
    },
    {
      name: 'P9',
      value: 9,
    },
    {
      name: 'P10',
      value: 10,
    },
    {
      name: 'P11',
      value: 11,
    },
    {
      name: 'P12',
      value: 12,
    },
    {
      name: 'P13',
      value: 13,
    },
    {
      name: 'P14',
      value: 14,
    },
    {
      name: 'P15',
      value: 15,
    },
    {
      name: 'P16',
      value: 16,
    },
  ]

  const posicoes = [
    {
      name: 1,
    },
    {
      name: 2,
    },
    {
      name: 3,
    },
    {
      name: 4,
    },
    {
      name: 5,
    },
    {
      name: 6,
    },
    {
      name: 7,
    },
    {
      name: 8,
    },
    {
      name: 9,
    },
    {
      name: 10,
    },
    {
      name: 11,
    },
    {
      name: 12,
    },
    {
      name: 13,
    },
    {
      name: 14,
    },
    {
      name: 15,
    },
    {
      name: 16,
    },
  ]

  const SiloView = () => {
    const formik = useFormikContext();

    const values = formik.values as LeituraSensor;

    const setaPosicaoSensor = (position: number) => {
      const sensor = sensores.find((i) => i.value == position)?.name as string;

      formik.setValues({
        ...values,
        posicao: position,
        sensor,
      })
    }

    const selecteds = [
      {
        value: values.posicao,
        sensor: values.sensor,
      }
    ]
    return (
      <Silo selecteds={selecteds} onChange={setaPosicaoSensor} eDashboard={false} imagem={tipo_silo} />
    )
  }

  const SelectPosition = () => {
    const formik = useFormikContext();

    const values = formik.values as LeituraSensor;

    const setaPosicaoSensor = (position: any) => {
      const sensor = sensores.find((i) => i.value == position)?.name as string;

      formik.setValues({
        ...values,
        posicao: position,
        sensor,
      })
    }

    return (
      <InputSelect onChange={(event) => setaPosicaoSensor(event.target.value)} name="posicao" label={t('LeiturasSensoresPosição')}>
        {posicoes.map((i) => (
          <option value={i.name} key={i.name}>{i.name}</option>
        ))}
      </InputSelect>
    )
  }

  return (
    <>
      <ListHeaderSecondary label={t('LeiturasSensoresTitulo')}>
        <DefaultButton size="sm" ml={4} onClick={() => onOpenModal('novo')}> {t('SistemaNovo')} </DefaultButton>
      </ListHeaderSecondary>
      {value && <ResponsiveTable onClick={(item: any) => onOpenModal(item.index)} headers={headers} data={value.filter((i: any) => !i?.deleted)} />}

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"2xl"}>
        <ModalOverlay />
        <ModalContent top={-10}>
          <ModalHeader>
            <ListHeaderSecondary label={currentItem?.id ? t('SistemaEditar') + ' ' + t('LeiturasSensoresTituloMin') : t('SistemaNovo') + ' ' + t('LeiturasSensoresTituloMin')} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {currentItem && <Formik
              enableReinitialize
              initialValues={currentItem}
              onSubmit={(val) => {
                onSaveModal(val);
              }}
            >
              {({ values }) => (
                <Form style={{
                  maxHeight: "calc(100vh - 220px)",
                  overflowY: 'auto'
                }}>
                  <Flex width="full" wrap="wrap">
                    <Box width={'50%'}>
                      <InputNumber name="profundidade" decimalPlaces={2} label={t('LeiturasSensoresEditProfundidade')} />
                      <SelectPosition />
                      <InputSelect name="configuracao" label={t('LeiturasSensoresConfiguração')}>
                        <option value="">{t('LeiturasSensoresNenhum')}</option>
                        <option value="Injeção superior">{t('DashboardInpecaoSuperior')}</option>
                        <option value="Injeção inferior">{t('DashboardInpecaoInferior')}</option>
                        <option value="Retorno">{t('DashboardRetorno')}</option>
                      </InputSelect>
                    </Box>

                    <Flex width={'50%'}>
                      <SiloView />
                    </Flex>
                    <DefaultButton type="submit" mt={4}>{t('SistemaSalvar')}</DefaultButton>
                    <Button onClick={onClose} ml={2} mt={4} colorScheme="blackAlpha">{t('SistemaFechar')}</Button>
                  </Flex>
                </Form>)}
            </Formik>}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}