import { Button, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spacer, useDisclosure } from "@chakra-ui/react";
import { DefaultButton } from "./DefaultButton";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  url: string;
  label: ReactNode;
}

const InputVerImagem = ({ url, label }: IProps) => {

  const { isOpen, onOpen, onClose } = useDisclosure()

  const { t } = useTranslation()

  return (
    <>
      <DefaultButton p={0} onClick={onOpen} size={"xs"}>
        {label}
      </DefaultButton>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('InputImageVisualizacao')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Image src={url} alt='imagem' />
            <Spacer marginY={5} />
            <Button colorScheme="teal" onClick={onClose}>{t('SistemaFechar')}</Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default InputVerImagem;