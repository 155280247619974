import { useField, Formik, Form } from "formik";
import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { useState } from 'react'
import validateForm from "../../utils/validateForm";
import { FiTrash } from "react-icons/fi";
import { LeituraDados } from "../types/leitura_dados";
import ResponsiveTable, { TableHeaders } from "../../sistema/components/ResponsiveTable";
import { novaLeituraDados } from "../data/leitura_dados";
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import InputField from "../../sistema/components/InputField";
import { RootState } from "../../app/mainReducer";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { formatData } from "../../utils/data";

export const LeiturasDados = () => {
  const [, { value }, { setValue }] = useField('dados');
  const eDesenvolvedor = useSelector((state: RootState) => (state.sistema.user as any)?.e_desenvolvedor == 1);

  const { t } = useTranslation();

  const headers: TableHeaders<LeituraDados>[] = [
    {
      label: t('LeiturasDataHora'),
      wrapped: false,
      render: (item) => `${formatData(item.data)}`,
    },
    {
      label: t('SistemaAções'),
      wrapped: true,
      render: (item: any, index: any) => <>{eDesenvolvedor && <Button size={"sm"} colorScheme="red" onClick={() => onDeleteItem(index)}><FiTrash /></Button>} </>,
      notClicable: true,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [currentItem, setCurrentItem] = useState<LeituraDados | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure()

  const onOpenModal = (index: string | number) => {
    if (index === 'novo') {
      setCurrentIndex(-1)
      setCurrentItem(novaLeituraDados())
      onOpen()
      return;
    }

    let data = JSON.parse(JSON.stringify(value[index]));
    for (const field of Object.keys(data)) {
      if (data[field] === null) {
        data[field] = "";
      }
    }

    setCurrentIndex(index as number);
    setCurrentItem(data);
    onOpen()
  }

  const onSaveModal = (form: LeituraDados) => {
    const values = JSON.parse(JSON.stringify(value)).filter((i: any) => !i?.deleted);
    const valuesDeleted = JSON.parse(JSON.stringify(value)).filter((i: any) => i?.deleted);

    form = {
      ...form,
      changed: true,
    }

    if (currentIndex === -1) {
      const valuesToPush = [
        ...values,
        ...valuesDeleted,
      ]
      valuesToPush.push(form);
      setValue(valuesToPush)
      onClose();
      setCurrentItem(null);
      return;
    }

    values[currentIndex] = {
      ...values[currentIndex],
      ...form,
    };
    setValue([
      ...values,
      ...valuesDeleted,
    ])
    onClose();
    setCurrentItem(null);
    setCurrentIndex(-1);
  }

  const onDeleteItem = (index: number) => {
    const values = JSON.parse(JSON.stringify(value)).filter((i: any) => !i?.deleted);
    const valuesDeleted = JSON.parse(JSON.stringify(value)).filter((i: any) => i?.deleted);

    if (index === -1) return;

    if (!values[index]?.id) {
      values.splice(index, 1);
      setValue([
        ...values,
        ...valuesDeleted,
      ])
      return;
    }

    values[index] = {
      ...values[index],
      deleted: true,
      changed: true,
    };

    setValue([
      ...values,
      ...valuesDeleted,
    ])
  }

  return (
    <>
      <ListHeaderSecondary label={t('LeiturasDadosTitulo')}>
        <DefaultButton size="sm" ml={4} onClick={() => onOpenModal('novo')}> {t('SistemaNovo')} </DefaultButton>
      </ListHeaderSecondary>
      {value && <ResponsiveTable onClick={(item: any) => onOpenModal(item.index)} headers={headers} data={value.filter((i: any) => !i?.deleted)} />}

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"2xl"}>
        <ModalOverlay />
        <ModalContent top={-10}>
          <ModalHeader>
            <ListHeaderSecondary label={currentItem?.id ? t('SistemaEditar') : t('SistemaNovo')} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {currentItem && <Formik
              enableReinitialize
              initialValues={currentItem}
              onSubmit={(val, { setErrors }) => {
                onSaveModal(val);
              }}
            >
              {({ submitForm }) => (
                <Form style={{
                  maxHeight: "calc(100vh - 220px)",
                  overflowY: 'auto'
                }}>
                  <Flex width="full" wrap="wrap">
                    <InputField width={"100%"} name="data" type="datetime-local" label={t('LeiturasDataHora')} />
                    <InputField width={"50%"} name="c1" label="Valor 1 (P1)" type="number" />
                    <InputField width={"50%"} name="t1" label="Tempo 1 (T1)" />
                    <InputField width={"50%"} name="c2" label="Valor 2 (P2)" type="number" />
                    <InputField width={"50%"} name="t2" label="Tempo 2 (T2)" />
                    <InputField width={"50%"} name="c3" label="Valor 3 (P3)" type="number" />
                    <InputField width={"50%"} name="t3" label="Tempo 3 (T3)" />
                    <InputField width={"50%"} name="c4" label="Valor 4 (P4)" type="number" />
                    <InputField width={"50%"} name="t4" label="Tempo 4 (T4)" />
                    <InputField width={"50%"} name="c5" label="Valor 5 (P5)" type="number" />
                    <InputField width={"50%"} name="t5" label="Tempo 5 (T5)" />
                    <InputField width={"50%"} name="c6" label="Valor 6 (P6)" type="number" />
                    <InputField width={"50%"} name="t6" label="Tempo 6 (T6)" />
                    <InputField width={"50%"} name="c7" label="Valor 7 (P7)" type="number" />
                    <InputField width={"50%"} name="t7" label="Tempo 7 (T7)" />
                    <InputField width={"50%"} name="c8" label="Valor 8 (P8)" type="number" />
                    <InputField width={"50%"} name="t8" label="Tempo 8 (T8)" />
                    <InputField width={"50%"} name="c9" label="Valor 9 (P9)" type="number" />
                    <InputField width={"50%"} name="t9" label="Tempo 9 (T9)" />
                    <InputField width={"50%"} name="c10" label="Valor 10 (P10)" type="number" />
                    <InputField width={"50%"} name="t10" label="Tempo 10 (T10)" />
                    <InputField width={"50%"} name="c11" label="Valor 11 (P11)" type="number" />
                    <InputField width={"50%"} name="t11" label="Tempo 11 (T11)" />
                    <InputField width={"50%"} name="c12" label="Valor 12 (P12)" type="number" />
                    <InputField width={"50%"} name="t12" label="Tempo 12 (T12)" />
                    <InputField width={"50%"} name="c12" label="Valor 13 (P13)" type="number" />
                    <InputField width={"50%"} name="t13" label="Tempo 13 (T13)" />
                    <InputField width={"50%"} name="c14" label="Valor 14 (P14)" type="number" />
                    <InputField width={"50%"} name="t14" label="Tempo 14 (T14)" />
                    <InputField width={"50%"} name="c15" label="Valor 15 (P15)" type="number" />
                    <InputField width={"50%"} name="t15" label="Tempo 15 (T15)" />
                    <InputField width={"50%"} name="c16" label="Valor 16 (P16)" type="number" />
                    <InputField width={"50%"} name="t16" label="Tempo 16 (T16)" />

                    <DefaultButton type="button" onClick={submitForm} mt={4}>{t('SistemaSalvar')}</DefaultButton>
                    <Button onClick={onClose} ml={2} mt={4} colorScheme="blackAlpha">{t('SistemaFechar')}</Button>
                  </Flex>
                </Form>)}
            </Formik>}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}