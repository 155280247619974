import { useTranslation } from "react-i18next";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import { useIndexedDB } from "react-indexed-db-hook";
import { Form, Formik, useField, useFormikContext } from "formik";
import { AvaliacaoInicialAssinatura } from "../types/avaliacao_inicial_assinatura";
import { Box, Button, Flex, Grid, Img, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react";
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import validateForm from "../../utils/validateForm";
import InputAssinatura from "../../sistema/components/InputAssinatura";
import { FiX } from "react-icons/fi";
import { useState, useEffect } from 'react'
import { novaAvaliacaoInicialAssinatura } from "../data/avaliacao_inicial_assinatura";
import InputSelect from "../../sistema/components/InputSelect";
import { useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import InputField from "../../sistema/components/InputField";

interface IProps {
  etapa: string;
}

export const AvaliacaoInicialAssinaturas = ({ etapa }: IProps) => {
  const { t } = useTranslation()

  const { add, getAll } = useIndexedDB('images');

  const salvarImagem = async (base64: string) => {
    return new Promise((resolve) => {
      add({ name: base64 }).then(
        (event: any) => {
          console.log("ID Generated: ", event);
          resolve(event)
        },
        (error) => {
          resolve('');
          console.log(error);
        },
      );
    })
  }

  const [, { value }, { setValue }] = useField('assinaturas');

  const nomeAssinatura = etapa == 'inicial' ? t('AvaliacaoInicialAssinaturasIniciais') : t('AvaliacaoInicialAssinaturasFinais')

  const [imagesDb, setImagesDb] = useState<any>([]);

  const getQuantidadeImagem = (imagens: AvaliacaoInicialAssinatura[]) => {
    return ((imagens ?? []) as AvaliacaoInicialAssinatura[]).filter((i) => i.etapa == etapa).length;
  }

  const getImagensDoTipo = (imagens: AvaliacaoInicialAssinatura[]) => {
    return ((imagens ?? []) as AvaliacaoInicialAssinatura[]).filter((i) => i.etapa == etapa);
  }

  const getImagensDoTipoNaoDeletadas = (imagens: AvaliacaoInicialAssinatura[]) => {
    return ((imagens ?? []) as AvaliacaoInicialAssinatura[]).filter((i) => i.etapa == etapa).filter((i) => !i.deleted);
  }

  const getImagensDiferenteDoTipo = (imagens: AvaliacaoInicialAssinatura[]) => {
    return ((imagens ?? []) as AvaliacaoInicialAssinatura[]).filter((i) => i.etapa != etapa);
  }

  const onDeleteIamge = (index: number, imagens: AvaliacaoInicialAssinatura[], setImagens: any) => {
    const values = JSON.parse(JSON.stringify(getImagensDoTipo(imagens))).filter((i: any) => !i?.deleted);
    const valuesDeleted = JSON.parse(JSON.stringify(getImagensDoTipo(imagens))).filter((i: any) => i?.deleted);
    const resto = JSON.parse(JSON.stringify(getImagensDiferenteDoTipo(imagens)));

    if (index === -1) return;

    if (!values[index]?.id) {
      values.splice(index, 1);
      setImagens([
        ...values,
        ...valuesDeleted,
        ...resto,
      ])
      return;
    }

    values[index] = {
      ...values[index],
      deleted: true,
      changed: true,
    };

    setImagens([
      ...values,
      ...valuesDeleted,
      ...resto,
    ])
  }

  let ultimaImagem = "";
  useEffect(() => {
    const getData = async () => {
      if (ultimaImagem != JSON.stringify(value)) {
        const data = await getAll();
        setImagesDb(data);
        ultimaImagem = JSON.stringify(value);
        console.log(`getData AvaliacaoInicialAssinaturas`)
      }
    }
    getData();
  }, [value, getAll])

  const [currentItem, setCurrentItem] = useState<AvaliacaoInicialAssinatura | null>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const onSaveImagem = async (values: AvaliacaoInicialAssinatura) => {
    console.log(values, `onSaveImagem`);
    setValue([
      {
        ...values,
        assinaturaId: await salvarImagem(values.assinatura),
        assinatura: '',
        changed: true,
      },
      ...(value ?? []),
    ])
    setCurrentItem(null);
    setTimeout(() => {
      setCurrentItem({
        ...novaAvaliacaoInicialAssinatura(),
        etapa: etapa,
      })
    }, 100)
  }

  const onOpenImagens = () => {
    setCurrentItem({
      ...novaAvaliacaoInicialAssinatura(),
      etapa: etapa,
    })
    onOpen()
  }
  const leituraDados = useSelector((state: RootState) => state.leituras.leituraDadosSelectsInputs)

  const InputUsuario = ({ values }: { values: AvaliacaoInicialAssinatura }) => {
    const [, { value }, { setValue }] = useField('assinaturas');

    const formik = useFormikContext()

    const onchangelocal = (val: string) => {
      const usuarioEncontrado = leituraDados?.administradores?.find((i) => i.id == val as any);

      if (!usuarioEncontrado) {
        formik.setFieldValue('nome', '')
        return;
      }

      formik.setFieldValue('nome', usuarioEncontrado.name)
    }

    return (
      <>
        {
          values.tipo_pessoa == 'ecozone' && <InputSelect onChangeVal={onchangelocal} width={"50%"} name="user_id" label={t('AvaliacaoInicialOperador')}>
            {leituraDados && leituraDados.administradores.map((i) => (
              <option key={i.id} value={i.id}>{i.name} - {i.celular}</option>
            ))}
          </InputSelect>
        }
      </>
    )
  }

  const InputTipoPessoa = () => {
    const formik = useFormikContext();

    const onchangelocal = (val: string) => {
      formik.setFieldValue('nome', '')
      formik.setFieldValue('user_id', '')
    }

    return (
      <InputSelect onChangeVal={onchangelocal} width={"50%"} label={t('AvaliacaoInicialTipoPessoa')} name="tipo_pessoa">
        <option value="ecozone">
          Ecozone
        </option>
        <option value="cliente">
          {t('DashboardCliente')}
        </option>
      </InputSelect>
    )
  }

  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);


  return (
    <>
      <ListHeaderSecondary label={nomeAssinatura} />
      <Flex width={"full"} />
      <DefaultButton mx={"auto"} mt={6} onClick={onOpenImagens} position={"relative"}>
        {t('AvaliacaoInicialRegistrar')} {nomeAssinatura}
        <Text position={"absolute"} py={1} px={2} fontSize={"12px"} fontWeight={700} color={"black"} top={"-5px"} right={"-5px"} bg={"white"} borderRadius={"50px"}>
          {getQuantidadeImagem(value)}
        </Text>
      </DefaultButton>

      <Modal autoFocus={false} returnFocusOnClose={false} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"2xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ListHeaderSecondary label={t('AvaliacaoInicialAssinar')}>
              <DefaultButton onClick={() => { setCurrentItem(null); onClose(); }} ml={2}>{t('DashboardVoltar')}</DefaultButton>
            </ListHeaderSecondary>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={2}>
            {currentItem && <Formik
              initialValues={currentItem}
              enableReinitialize
              onSubmit={(values, { setErrors }) => {
                let requireds = {};

                if (values.tipo_pessoa == 'ecozone') {
                  requireds = {
                    user_id: 'required',
                  }
                } else {
                  requireds = {
                    nome: 'required',
                  }
                }

                const validation = validateForm({
                  assinatura: 'required',
                  tipo_pessoa: 'required',
                  ...requireds,
                }, values, t)
                if (Object.keys(validation).length > 0) {
                  setErrors(validation)
                  return;
                }

                onSaveImagem(values)
              }}
            >
              {({ values }) => (
                <Form>
                  <Flex width="full" direction={"column"} wrap={"wrap"} textAlign={"center"} alignItems={"center"} justifyContent={"center"}>
                    <Box>
                      <InputAssinatura width={isMobile ? '340' : '500'} name="assinatura" label="" />
                    </Box>

                    <Flex width={"full"} wrap={"wrap"}>
                      <InputTipoPessoa />

                      <InputUsuario values={values} />
                      {values.tipo_pessoa == 'cliente' && <InputField width={"50%"} name="nome" label={t('AvaliacaoInicialNomeCliente')} />}
                    </Flex>

                    <DefaultButton mx={"auto"} mt={4} type="submit">
                      {t('AvaliacaoInicialRegistrar')} &nbsp;
                      {t('AvaliacaoInicialAssinatura')}
                    </DefaultButton>
                  </Flex>
                </Form>)}
            </Formik>}

            {getImagensDoTipoNaoDeletadas(value).length > 0 && <>
              <ListHeaderSecondary label={t(`AvaliacaoInicialAssinaturas`)} />
              <Box width={`full`}></Box>
              <Grid templateColumns={`repeat(${isMobile ? 1 : 2}, 1fr)`} gap={3} width={"full"}>
                {getImagensDoTipoNaoDeletadas(value).map((i: AvaliacaoInicialAssinatura, index: number) => (
                  <Flex justifyContent={"center"} key={`${index}`} p={3} position={"relative"} wrap={"wrap"}>
                    <Img src={!i.assinaturaId ? i.assinatura : imagesDb.find((j: any) => j.id == i.assinaturaId)?.name} />
                    <Button onClick={() => onDeleteIamge(index, value, setValue)} title={t(`AvaliacaoInicialRemoverFoto`)} size={"sm"} colorScheme="red" position={"absolute"} top={"5px"} right={"5px"}>
                      <FiX />
                    </Button>
                    <Text textAlign={"center"} width={"full"} fontSize={"18px"} mx={"auto"} fontWeight={700} mt={2}>
                      {t('DashboardNome')}: {i.nome} ({i.tipo_pessoa.trim() == 'cliente' ? t('DashboardCliente') : 'Ecozone'})
                    </Text>
                  </Flex>
                ))}
              </Grid>
            </>}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}