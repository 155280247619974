export const getBaitsTypes = () => {
  return [
    {
      "name": "BaitType1Name",
      "value": 1,
      "occurrences": [
        {
          "name": "BaitType1Occurrence1",
          "value": 1,
          "isConsumed": 0
        },
        {
          "name": "BaitType1Occurrence2",
          "value": 2,
          "isConsumed": 1
        },
        {
          "name": "BaitType1Occurrence3",
          "value": 3,
          "isConsumed": 0
        },
        {
          "name": "BaitType1Occurrence4",
          "value": 4,
          "isConsumed": 0
        },
        {
          "name": "BaitType1Occurrence5",
          "value": 5,
          "isConsumed": 0
        },
        {
          "name": "BaitType1Occurrence6",
          "value": 6,
          "isConsumed": 0
        },
        {
          "name": "BaitType1Occurrence7",
          "value": 7,
          "isConsumed": 0
        },
        {
          "name": "BaitType1Occurrence8",
          "value": 8,
          "isConsumed": 0
        },
        {
          "name": "BaitType1Occurrence9",
          "value": 9,
          "isConsumed": 0
        },
        {
          "name": "BaitType1Occurrence10",
          "value": 10,
          "isConsumed": 1
        },
        {
          "name": "BaitType1Occurrence11",
          "value": 11,
          "isConsumed": 0
        },
        {
          "name": "BaitType1Occurrence12",
          "value": 12,
          "isConsumed": 0
        },
      ]
    },
    {
      "name": "BaitType2Name",
      "value": 2,
      "occurrences": [
        {
          "name": "BaitType2Occurrence1",
          "value": 1,
          "isConsumed": 1
        },
        {
          "name": "BaitType2Occurrence2",
          "value": 2,
          "isConsumed": 0
        },
        {
          "name": "BaitType2Occurrence3",
          "value": 3,
          "isConsumed": 0
        },
        {
          "name": "BaitType2Occurrence4",
          "value": 4,
          "isConsumed": 0
        },
        {
          "name": "BaitType2Occurrence5",
          "value": 5,
          "isConsumed": 0
        },
        {
          "name": "BaitType2Occurrence6",
          "value": 6,
          "isConsumed": 0
        },
        {
          "name": "BaitType2Occurrence7",
          "value": 7,
          "isConsumed": 0
        },
        {
          "name": "BaitType2Occurrence8",
          "value": 8,
          "isConsumed": 0
        },
        {
          "name": "BaitType2Occurrence9",
          "value": 9,
          "isConsumed": 0
        },
        {
          "name": "BaitType2Occurrence10",
          "value": 10,
          "isConsumed": 0
        },
        {
          "name": "BaitType2Occurrence11",
          "value": 11,
          "isConsumed": 0
        },
      ]
    },
    {
      "name": "BaitType3Name",
      "value": 3,
      "occurrences": [
        {
          "name": "BaitType3Occurrence1",
          "value": 1,
          "isConsumed": 1
        },
        {
          "name": "BaitType3Occurrence2",
          "value": 2,
          "isConsumed": 1
        },
        {
          "name": "BaitType3Occurrence3",
          "value": 3,
          "isConsumed": 0
        },
        {
          "name": "BaitType3Occurrence4",
          "value": 4,
          "isConsumed": 0
        },
        {
          "name": "BaitType3Occurrence5",
          "value": 5,
          "isConsumed": 0
        },
        {
          "name": "BaitType3Occurrence6",
          "value": 6,
          "isConsumed": 0
        },
        {
          "name": "BaitType3Occurrence7",
          "value": 7,
          "isConsumed": 0
        },
        {
          "name": "BaitType3Occurrence8",
          "value": 8,
          "isConsumed": 0
        },
        {
          "name": "BaitType3Occurrence9",
          "value": 9,
          "isConsumed": 0
        },
      ]
    },
    {
      "name": "BaitType4Name",
      "value": 4,
      "occurrences": [
        {
          "name": "BaitType4Occurrence1",
          "value": 1,
          "isConsumed": 0
        },
        {
          "name": "BaitType4Occurrence2",
          "value": 2,
          "isConsumed": 1
        },
        {
          "name": "BaitType4Occurrence3",
          "value": 3,
          "isConsumed": 0
        },
        {
          "name": "BaitType4Occurrence4",
          "value": 4,
          "isConsumed": 0
        },
        {
          "name": "BaitType4Occurrence5",
          "value": 5,
          "isConsumed": 0
        },
        {
          "name": "BaitType4Occurrence6",
          "value": 6,
          "isConsumed": 0
        },
        {
          "name": "BaitType4Occurrence7",
          "value": 7,
          "isConsumed": 0
        },
        {
          "name": "BaitType4Occurrence8",
          "value": 8,
          "isConsumed": 0
        },
        {
          "name": "BaitType4Occurrence9",
          "value": 9,
          "isConsumed": 0
        },
        {
          "name": "BaitType4Occurrence10",
          "value": 10,
          "isConsumed": 0
        },
        {
          "name": "BaitType4Occurrence11",
          "value": 11,
          "isConsumed": 0
        },
        {
          "name": "BaitType4Occurrence12",
          "value": 12,
          "isConsumed": 0
        },
        {
          "name": "BaitType4Occurrence13",
          "value": 13,
          "isConsumed": 0
        },
        {
          "name": "BaitType4Occurrence14",
          "value": 14,
          "isConsumed": 0
        },
        {
          "name": "BaitType4Occurrence15",
          "value": 15,
          "isConsumed": 1
        },
        {
          "name": "BaitType4Occurrence16",
          "value": 16,
          "isConsumed": 0
        },
        {
          "name": "BaitType4Occurrence17",
          "value": 17,
          "isConsumed": 0
        },
        {
          "name": "BaitType4Occurrence18",
          "value": 18,
          "isConsumed": 0
        },
      ]
    },
  ]
}