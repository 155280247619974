import { ClienteUnidade } from "../../sistema/types/cliente_unidade";
import { newBodyCenterVisitOccurrence } from "../data/body_center_visit_occurrence";
import { BodyCenterVisit } from "../types/body_center_visit";

export const getConfigurationOfPointsInMap = (bodyCenter: BodyCenterVisit, clientUnit?: ClienteUnidade) => {
  const client = clientUnit ? clientUnit : bodyCenter.clientUnit;
  const configuredPoints = (client?.body_center_map_points ?? []).filter((i) => !i.src).map((i) => i.text);

  const currentOccurrences = (bodyCenter.occurrences ?? []);

  const currentPoints = currentOccurrences.map(o => o.point);

  const existingPoints = currentOccurrences.map(occurrence => ({
    ...occurrence,
    deleted: !configuredPoints.includes(occurrence.point)
  })).filter(i => {
    if (i.deleted && !i.id) {
      return false;
    }

    return true;
  });

  const newPoints = configuredPoints
  .filter(point => !currentPoints.includes(point))
  .map(point => ({
    ...newBodyCenterVisitOccurrence(),
    point,
    deleted: false,
    changed: true,
  }));

  return [...existingPoints, ...newPoints].sort((a, b) => a.point - b.point);
}
