import { getCurrentData } from "../../utils/data";
import { LeituraAmostra } from "../types/leitura_amostra";

export const novaLeituraAmostra = (sequencial = 0): LeituraAmostra => {
  const dataHora = getCurrentData("yyyy-MM-dd HH:mm");

  return {
    leitura_id: 0,
    sequencial,
    tipo_leitura: "Inicial",
    concentracao: 0,
    data_hora: dataHora,
    imagem: "",
    qt_hora: 0,
  };
};
