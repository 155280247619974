import { useTranslation } from "react-i18next";
import config from "../../../config";
import { useIsAuth } from "../../hooks/useIsAuth";
import Layout from "../components/Layout";
import { Flex, Text, Img, useMediaQuery } from "@chakra-ui/react";

const Home = () => {
  useIsAuth();

  const isMobile = useMediaQuery('(max-width: 768px)');

  const { t } = useTranslation();

  return (
    <Layout>
      <Flex height={"50vh"} width={"full"} wrap={"wrap"} justifyContent={"center"} alignItems={"center"}>
        <Flex textAlign={"center"} direction={"column"}>
          <Img mx={"auto"} height={{ base: '180px', md: 'auto' }} width={isMobile ? 'auto' : "180px"} src={config.urlLogo} />

          <Text mt={6} fontSize={"20px"}>
            {t('HomeWelcome')}
          </Text>
        </Flex>
      </Flex>
    </Layout>
  )
}

export default Home;
