import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useIsAuth } from "../../hooks/useIsAuth"
import Layout from "../components/Layout"
import { useParams } from "react-router-dom";
import { useEffect } from 'react'
import { sistemaActions } from "../recucer";
import { ListHeader } from "../components/ListHeader";
import { DefaultButton } from "../components/DefaultButton";
import Loader from "../components/Loader";
import { Formik, Form } from 'formik'
import validateForm from "../../utils/validateForm";
import InputField from "../components/InputField";
import InputCheck from "../components/InputCheck";
import { Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import InputSelect from "../components/InputSelect";
import { tipoQuestao } from "../arrays/tipo_questao";
import InputImage from "../components/InputImage";
import InputCheckInteiro from "../components/InputCheckInteiro";

export const AvaliacaoInicialQuestaoEdit = () => {
  useIsAuth();

  const { id } = useParams();

  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);
  const value = useSelector((state: RootState) => state.sistema.avaliacaoQuestao)
  const isLoading = useSelector((state: RootState) => !!state.sistema.isLoading)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!id) return;

    dispatch(sistemaActions.avaliacaoQuestaoRequest(id))
  }, [dispatch, id])

  const { t } = useTranslation();

  const getOrdenacoes = () => {
    const retorno = [];

    for (let index = 1; index <= 30; index++) {
      retorno.push({
        name: index,
        value: index
      })
    }

    return retorno;
  }

  return (
    <Layout>
      <ListHeader label={t('AvaliacaoInicialQuestaoEditTitulo')}>
        <DefaultButton ml={4} to="/avaliacao-questoes"> {t('SistemaVoltar')} </DefaultButton>
      </ListHeader>

      <Loader isLoading={isLoading} />

      {value && <Formik
        initialValues={value}
        enableReinitialize
        onSubmit={(values, { setErrors }) => {
          const validation = validateForm({
            'nome_questao': 'required',
            'ordem': 'required',
            'tipo': 'required',
          }, values, t);

          if (Object.keys(validation).length > 0) {
            setErrors(validation)
            return;
          }
          console.log("questão form", values);
          dispatch(sistemaActions.saveAvaliacaoQuestaoRequest({
            ...values,
            changed: true,
          }));
        }}
      >
        {({ values }) => (
          <Form>
            <Flex width="full" wrap={"wrap"}>
              <InputField label={t('ClienteEditInputNome')} name="nome_questao" width={isMobile ? '50%' : "33.33%"} autoFocus />
              <InputSelect name="ordem" label={t('AvaliacaoInicialQuestaoEditOrdem')} width={isMobile ? '50%' : "33.33%"}>
                {getOrdenacoes().map((i) => (
                  <option key={`${i.value}`} value={i.value}>{i.name}</option>
                ))}
              </InputSelect>
              <InputSelect name="tipo" label={t('AvaliacaoInicialQuestaoEditTipo')} width={isMobile ? '50%' : "33.33%"}>
                {tipoQuestao().map((i) => (<option key={`${i.value}`} value={i.value}>{t(i.name)}</option>))}
              </InputSelect>
              <InputImage name="imagem" label={t('SistemaImagem')} />
              <InputCheck label={t('AvaliacaoInicialQuestaoEditESinaleiro')} name="e_sinaleiro" />
              <InputCheck label={t('AvaliacaoInicialQuestaoEditAtivo')} name="ativo" />
              <InputCheckInteiro label={t('AvaliacaoInicialQuestaoEditNaoMostraPredio')} name="nao_mostra_no_predio" />
            </Flex>
            <DefaultButton type="submit" mt={2}>{t('SistemaSalvar')} {t('AvaliacaoInicialQuestaoEditTitulo')}</DefaultButton>
          </Form>
        )}
      </Formik>}
    </Layout>
  )
}