import { useTranslation } from "react-i18next";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import { FiEye } from "react-icons/fi";

interface IProps {
  onOpen: () => void;
  marginBottom?: string;
}

export const DashboardVerMais = ({ onOpen, marginBottom }: IProps) => {
  const { t } = useTranslation();

  return (
    <DefaultButton className="no-print" marginBottom={marginBottom} size={"xs"} onClick={onOpen}> <FiEye />&nbsp;{t('DashboardVerMais')} </DefaultButton>
  )
}