import { Form, Formik } from "formik"
import { EquipamentoCiclo } from "../../sistema/types/equipamento_ciclo"
import { Box, Button, Flex, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { formatData } from "../../utils/data"
import { useTranslation } from 'react-i18next'

export const DashboardCiclo = ({ ciclo, pontos, onClose }: { ciclo: EquipamentoCiclo, pontos?: any, onClose: () => void; }) => {
  const { t } = useTranslation()

  const infos = [
    {
      event: 'READ_CONCENTRATION',
      info: t('DashboardStatusLeuConcentracao') + ': ',
    },
    {
      event: 'READ_TIMESTAMP',
      info: t('DashboardStatusLeuConcentracao') + ': ',
    },
    {
      event: 'READ_STABILITY',
      info: t('DashboardStatusConcentracaoEstabilizou') + ' ',
    },
    {
      event: 'WAIT',
      info: t('DashboardStatusAguardar') + ' '
    },
    {
      event: 'START_MACHINE',
      info: t('DashboardStatusIniciouCiclo') + ' '
    },
    {
      event: 'FINISH_MACHINE',
      info: t('DashboardStatusFinalizouCiclo') + ' '
    },
    {
      event: 'SET_TIME_POINT_CHANGE',
      info: t('DashboardStatusSetouTempoTrocaPonto') + ' '
    },
    {
      event: 'SET_TIME_PURGE',
      info: t('DashboardStatusSetouTempoPurge') + ' '
    },
    {
      event: 'SET_TIME_MEASUREMENT',
      info: t('DashboardStatusSetouTempoLeitura') + ' '
    },
  ]

  const localPontos = [
    {
      name: 'P1',
      pointModBus: 17,
      isOn: 'TURN_ON_SOLENOID',
      ifOff: 'TURN_OFF_SOLENOID',
      info: t('DashboardPonto') + ' 1',
    },
    {
      name: 'P2',
      pointModBus: 18,
      isOn: 'TURN_ON_SOLENOID',
      ifOff: 'TURN_OFF_SOLENOID',
      info: t('DashboardPonto') + ' 2',
    },
    {
      name: 'P3',
      pointModBus: 19,
      isOn: 'TURN_ON_SOLENOID',
      ifOff: 'TURN_OFF_SOLENOID',
      info: t('DashboardPonto') + ' 3',
    },
    {
      name: 'P4',
      pointModBus: 20,
      isOn: 'TURN_ON_SOLENOID',
      ifOff: 'TURN_OFF_SOLENOID',
      info: t('DashboardPonto') + ' 4',
    },
    {
      name: 'P5',
      pointModBus: 21,
      isOn: 'TURN_ON_SOLENOID',
      ifOff: 'TURN_OFF_SOLENOID',
      info: t('DashboardPonto') + ' 5',
    },
    {
      name: 'P6',
      pointModBus: 22,
      isOn: 'TURN_ON_SOLENOID',
      ifOff: 'TURN_OFF_SOLENOID',
      info: t('DashboardPonto') + ' 6',
    },
    {
      name: 'P7',
      pointModBus: 23, // Desse pra baixo ainda nao funciona
      isOn: 'TURN_ON_SOLENOID',
      ifOff: 'TURN_OFF_SOLENOID',
      info: t('DashboardPonto') + ' 7',
    },
    {
      name: 'P8',
      pointModBus: 24,
      isOn: 'TURN_ON_SOLENOID',
      ifOff: 'TURN_OFF_SOLENOID',
      info: t('DashboardPonto') + ' 8',
    },
    {
      name: 'P9',
      pointModBus: 25,
      isOn: 'TURN_ON_SOLENOID',
      ifOff: 'TURN_OFF_SOLENOID',
      info: t('DashboardPonto') + ' 9',
    },
    {
      name: 'P10',
      pointModBus: 26,
      isOn: 'TURN_ON_SOLENOID',
      ifOff: 'TURN_OFF_SOLENOID',
      info: t('DashboardPonto') + ' 10',
    },
    {
      name: 'P11',
      pointModBus: 27,
      isOn: 'TURN_ON_SOLENOID',
      ifOff: 'TURN_OFF_SOLENOID',
      info: t('DashboardPonto') + ' 11',
    },
    {
      name: 'P12',
      pointModBus: 28,
      isOn: 'TURN_ON_SOLENOID',
      ifOff: 'TURN_OFF_SOLENOID',
      info: t('DashboardPonto') + ' 12',
    },
    {
      name: 'P13',
      pointModBus: 29,
      isOn: 'TURN_ON_SOLENOID',
      ifOff: 'TURN_OFF_SOLENOID',
      info: t('DashboardPonto') + ' 13',
    },
    {
      name: 'P14',
      pointModBus: 30,
      isOn: 'TURN_ON_SOLENOID',
      ifOff: 'TURN_OFF_SOLENOID',
      info: t('DashboardPonto') + ' 14',
    },
    {
      name: 'P15',
      pointModBus: 31,
      isOn: 'TURN_ON_SOLENOID',
      ifOff: 'TURN_OFF_SOLENOID',
      info: t('DashboardPonto') + ' 15',
    },
    {
      name: 'P16',
      pointModBus: 32,
      isOn: 'TURN_ON_SOLENOID',
      ifOff: 'TURN_OFF_SOLENOID',
      info: t('DashboardPonto') + ' 16',
    },
    {
      name: 'Purge',
      isOn: 'TURN_ON_SOLENOID_CLEANING',
      ifOff: 'TURN_OFF_SOLENOID_CLEANING',
      info: t('DashboardStatusPurge'),
    },
    {
      name: 'Bomba',
      isOn: 'TURN_ON_PUMP',
      ifOff: 'TURN_OFF_PUMP',
      info: t('DashboardStatusBomba'),
    },
  ]

  if (!pontos) {
    pontos = localPontos;
  }

  return (
    <>
      {ciclo && <Formik
        enableReinitialize
        initialValues={ciclo as EquipamentoCiclo}
        onSubmit={(values) => { }}
      >
        {({ values }) => (
          <Form style={{
            maxHeight: "calc(100vh - 220px)",
            overflowY: 'auto'
          }}>
            <Flex width="full" wrap="wrap">
              <Box width={"full"}>
                <Table width={"full"} variant="striped" colorScheme="green" size={"sm"}>
                  <Thead>
                    <Tr>
                      <Th>{t('DashboardStatusAcao')}</Th>
                      <Th>{t('DashboardStatusData')}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {values.itens.map((i) => (<Tr>
                      <Td>
                        {infos.find((j) => j.event == i.action) && <>
                          {`${infos.find((j) => j.event == i.action)?.info} ${i.value ?? ''} ${i.message ?? ''}`}
                        </>}

                        {(i.action == 'TURN_ON_SOLENOID' || i.action == 'TURN_OFF_SOLENOID') && <>
                          {pontos.find((j: any) => j.isOn == i.action && j.pointModBus == i.value) && <>{t('DashboardStatusLigouSolenoide')} {pontos.find((j: any) => j.isOn == i.action && j.pointModBus == i.value)?.info}</>}
                          {pontos.find((j: any) => j.ifOff == i.action && j.pointModBus == i.value) && <>{t('DashboardStatusDesligouSolenoide')} {pontos.find((j: any) => j.ifOff == i.action && j.pointModBus == i.value)?.info}</>}
                        </>}
                        {i.action != 'TURN_ON_SOLENOID' && i.action != 'TURN_OFF_SOLENOID' &&
                          <>
                            {pontos.find((j: any) => j.isOn == i.action) && <>{t('DashboardStatusLigou')} {pontos.find((j: any) => j.isOn == i.action)?.info}</>}
                            {pontos.find((j: any) => j.ifOff == i.action) && <>{t('DashboardStatusDesligou')} {pontos.find((j: any) => j.ifOff == i.action)?.info}</>}
                          </>
                        }
                      </Td>
                      <Td>
                        {formatData(i.date, 'dd/MM/yyyy HH:mm:ss')}
                      </Td>
                    </Tr>))}
                  </Tbody>
                </Table>
              </Box>

              <Button onClick={onClose} mt={4} colorScheme="blackAlpha">{t('DashboardFechar')}</Button>
            </Flex>
          </Form>)}
      </Formik>}
    </>
  )
}