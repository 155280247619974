import React from "react";
import { ClienteUnidade } from "../types/cliente_unidade"
import { Button, ModalHeader, ModalContent, ModalOverlay, Modal, useDisclosure, ModalCloseButton, ModalBody, ModalFooter, Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ListHeaderSecondary } from "./ListHeaderSecondary";
import { Form, useField, useFormikContext } from "formik";
import { Formik } from "formik";
import { DefaultButton } from "./DefaultButton";
import MapBait from "../../body-center/components/Mapbait";
import InputImage from "./InputImage";
import Accordion from "./Accordion";
import { RootState } from "../../app/mainReducer";
import { useSelector } from "react-redux";
import config from "../../../config";

interface ClientUnitBaitMapProps {
  clientUnit: ClienteUnidade;
}

export const ClientUnitBaitMap = ({ clientUnit }: ClientUnitBaitMapProps) => {
  const { t } = useTranslation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [, { error, touched, value: allUnits }, { setValue }] = useField('unidades');

  const formik = useFormikContext();

  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);

  const updateClientUnit = (values: ClienteUnidade) => {
    const units = JSON.parse(JSON.stringify(allUnits));
    const unitIndex = units.findIndex((unit: ClienteUnidade) => unit.id === values.id);
    units[unitIndex] = values;
    setValue(units);
    formik.submitForm();
  }

  return (
    <React.Fragment>
      {clientUnit?.id && <Button onClick={onOpen} colorScheme="blue" size="sm">
        <div className="w-full flex flex-col items-center">
          {t('BodyCenterMapaDeIscas')}
          <small>BODY CENTER</small>
        </div>
      </Button>}
      <Modal scrollBehavior="inside" closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"full"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ListHeaderSecondary label={t('BodyCenterMapaDeIscas')} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {clientUnit && <Formik
              enableReinitialize
              initialValues={clientUnit}
              onSubmit={(val, { setErrors }) => {
                if (!val.body_center_map_image) {
                  setErrors({ body_center_map_image: t('SistemaCampoObrigatorio') });
                  return;
                }
                updateClientUnit({
                  ...val,
                  changed: true,
                });
              }}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <Accordion items={[
                    {
                      title: t('DashboardConfigurações'),
                      content: <div className="flex flex-wrap gap-2">
                        <InputImage
                          label={t('ClientUnitPictureOfMapUnit')}
                          name="body_center_map_image"
                        />
                        <Button type="submit" colorScheme="green" mt={1} size="sm">
                          {t('SistemaSalvar')}
                        </Button>
                      </div>
                    },
                    {
                      defaultOpened: true,
                      title: t('BodyCenterMapaDeIscas'),
                      content: <div className="justify-center flex flex-wrap gap-2">
                        {!clientUnit.body_center_map_image && t('ClientUnitFillPictureOfMapUnit')}
                        {clientUnit.body_center_map_image && <React.Fragment>
                          {isMobile ?
                            <Box
                              overflowX="auto"
                              overflowY="hidden"
                              className="mx-auto max-w-[85vw] md:max-w-auto"
                              sx={{
                                '&::-webkit-scrollbar': {
                                  width: '12px',
                                  height: '12px',
                                  backgroundColor: '#F5F5F5',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                  backgroundColor: config.defaultColor,
                                  borderRadius: '2px',
                                  '&:hover': {
                                    backgroundColor: config.defaultColor,
                                  },
                                },
                                '&::-webkit-scrollbar-track': {
                                  backgroundColor: '#F5F5F5',
                                  borderRadius: '2px',
                                },
                              }}
                            >
                              <Box minWidth="1110px" minHeight="500px" position="relative">
                                <MapBait
                                  baseImage={clientUnit.body_center_map_image}
                                  clientUnit={clientUnit}
                                  setPosition={(position: any) => setFieldValue('body_center_map_position', position)}
                                  setPoints={(points: any) => setFieldValue('body_center_map_points', points)}
                                  isStatic={isMobile}
                                />
                                <Box
                                  position="absolute"
                                  top={0}
                                  left={0}
                                  right={0}
                                  bottom={0}
                                  onTouchStart={(e) => e.stopPropagation()}
                                  onClick={(e) => e.stopPropagation()}
                                  pointerEvents="auto"
                                  sx={{
                                    touchAction: "pan-x",
                                    userSelect: "none",
                                    overscrollBehavior: "contain"
                                  }}
                                />
                              </Box>
                            </Box> :
                            <MapBait
                              baseImage={clientUnit.body_center_map_image}
                              clientUnit={clientUnit}
                              setPosition={(position: any) => setFieldValue('body_center_map_position', position)}
                              setPoints={(points: any) => setFieldValue('body_center_map_points', points)}
                              isStatic={isMobile}
                            />
                          }
                        </React.Fragment>}
                      </div>
                    }
                  ]} />
                </Form>)}
            </Formik>}
          </ModalBody>
        </ModalContent>
      </Modal>
    </React.Fragment>
  )
}
