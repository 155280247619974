import React from "react";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";
import { useField } from "formik";
import { InputHTMLAttributes } from "react";
import { MultiSelect } from "react-multi-select-component";

import Select from 'react-select'; // Adicione este import
import { useTranslation } from "react-i18next";

export type InputLocalSearchProps = InputHTMLAttributes<HTMLSelectElement> & {
  name: string;
  label: string;
  options: {
    label: string;
    value: string | number;
  }[];
  bgNone?: boolean;
};

const InputLocalSearch: React.FC<InputLocalSearchProps> = ({
  label,
  options,
  bgNone = false,
  size: _,
  width,
  ...props
}) => {
  const [field, { error }, { setValue }] = useField(props as any);
  const { t } = useTranslation();
  return (
    <FormControl isInvalid={!!error} width={width} p={1}>
      <FormLabel htmlFor={field.name}>{label}</FormLabel>
      <Select
        options={options}
        value={options.find(option => option.value === field.value)}
        onChange={(option) => setValue(option?.value)}
        placeholder={t('SelectPlaceholder')}
        isClearable
        noOptionsMessage={() => t('NoOptionsMessage')}
      />
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};

export default InputLocalSearch;