import { useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useTranslation } from "react-i18next";
import { Box, Flex, Text } from "@chakra-ui/react";
import ReactSpeedometer from "react-d3-speedometer";
import { formatData } from "../../utils/data";

interface Item {
  valor: number;
  data: string;
  ponto: string;
  posicaoPonto: number;
}

export const DashboardCard = ({ value, minimal = false, mostraMaior = false, positionText = "35%", telaGrande = false }: { value: Item; minimal?: boolean; mostraMaior?: boolean; positionText?: string; telaGrande?: boolean; }) => {
  const { t } = useTranslation();

  const dashboard = useSelector((state: RootState) => state.leituras.dashboard_leituras);

  const eFosfina = dashboard?.last_leitura?.tipo_leitura == 'PH3 (Fosfina)';

  const corFosfina = [
    '#FF6347',
    '#FFD700',
    '#00FF7F',
    '#4169E1',
    '#845EC2',
    'red'
  ]

  const corOzonio = [
    '#FF6347',
    '#FFD700',
    '#00FF7F',
    '#4169E1',
    '#8a2be2',
  ]

  const escalaFosfina = [
    '0 - 399',
    '400 - 1199',
    '1200 - 1999',
    '2000 - 3000',
    '3001 - 4000',
    '4001 - 5000',
  ]

  const escalaOzonio = [
    '0 - 40',
    '41 - 50',
    '51 - 60',
    '61 - 80',
    '> 81',
  ]

  const cores = eFosfina ? corFosfina : corOzonio;
  const escala = eFosfina ? escalaFosfina : escalaOzonio;

  const temOPontoConfigurado = (ponto: number) => {
    return dashboard?.sensores?.some((i) => i.posicao == ponto);
  }

  const temOPontoConfiguracao = (configuracao: string) => {
    return dashboard?.sensores?.find((i) => (i.configuracao ?? '').trim() == configuracao);
  }

  const getLeituras = (): Item[] => {
    if (!dashboard?.last_data || !dashboard?.sensores) return [
      {
        valor: 0,
        data: "",
        ponto: `${t('DashboardPonto')} 1`,
        posicaoPonto: 0,
      },
      {
        valor: 0,
        data: "",
        ponto: `${t('DashboardPonto')} 2`,
        posicaoPonto: 0,
      },
      {
        valor: 0,
        data: "",
        ponto: `${t('DashboardPonto')} 3`,
        posicaoPonto: 0,
      },
      {
        valor: 0,
        data: "",
        ponto: `${t('DashboardPonto')} 4`,
        posicaoPonto: 0,
      },
      {
        valor: 0,
        data: "",
        ponto: `${t('DashboardPonto')} 5`,
        posicaoPonto: 0,
      },
      {
        valor: 0,
        data: "",
        ponto: `${t('DashboardPonto')} 6`,
        posicaoPonto: 0,
      },
    ];

    let grafico: any = [];

    const last_data: any = dashboard.last_data;

    for (let i = 1; i <= 16; i++) {
      if (temOPontoConfigurado(i)) {
        grafico.push({
          valor: last_data[`c${i}`],
          data: last_data[`t${i}`],
          ponto: `${t('DashboardPonto')} ${i} ${temOPontoConfiguracao('Retorno')?.posicao == i ? `(${t('DashboardRetorno')})` :
            temOPontoConfiguracao('Injeção superior')?.posicao == i ? `(${t('DashboardInpecaoSuperior')})` :
              temOPontoConfiguracao('Injeção inferior')?.posicao == i ? `(${t('DashboardInpecaoInferior')})` : ''
            }`,
          posicaoPonto: i,
        },)
      }
    }

    return grafico;
  };

  const getMaxValue = () => {
    let maior = 0;

    getLeituras().forEach((i) => {
      if (maior < i.valor) {
        maior = i.valor;
      }
    })


    if (!eFosfina) {
      if (maior > 100) {
        return 150;
      }
      return 100;
    }

    if (maior > 5000) {
      return 7000;
    }

    return 5000;
  }

  const segmentsFosfina = [0, 400, 1200, 2000, 3000, 4000, getMaxValue()]
  const segmentsOzonio = [0, 40, 50, 60, 80, getMaxValue()]

  const customSegments = eFosfina ? segmentsFosfina : segmentsOzonio

  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);

  return (
    <Box position={"relative"} mt={isMobile ? 4 : getLeituras().length <= 6 ? 4 : 2}>
      <Flex left={positionText} transform={"translateX(-50%)"} bg={minimal ? "white" : "none"} borderRadius={"25px"} opacity={"0.9"} position={"absolute"} width={"full"} top={isMobile ? "-8px" : (telaGrande && getLeituras().length <= 6) ? '-12px' : "-5px"} justifyContent={"center"} direction={"column"} textAlign={"center"}>
        <small style={{ fontSize: (telaGrande && getLeituras().length <= 6) ? '0.7rem' : '0.5rem', color: '#404244', fontWeight: '700' }}>{value.ponto} <small style={{ fontSize: (telaGrande && getLeituras().length <= 6) ? '10px' : '8px', fontWeight: '400' }}>{formatData(parseInt(value.data) * 1000, 'HH:mm') && <>({formatData(parseInt(value.data) * 1000, 'HH:mm')})</>}</small></small>
      </Flex>

      <ReactSpeedometer
        value={value.valor}
        minValue={0}
        maxValue={getMaxValue()}
        needleColor="#888"
        startColor={cores[0]}
        endColor={cores[cores.length - 1]}
        segments={4}
        needleHeightRatio={(getLeituras().length <= 6 || mostraMaior) && !isMobile ? 0.7 : 0.5}
        needleTransitionDuration={1000}
        customSegmentStops={customSegments}
        segmentColors={cores}
        height={telaGrande && getLeituras().length <= 6 ? 130 : (isMobile ? (minimal ? 80 : 110) : (minimal ? 70 : (getLeituras().length <= 6 || mostraMaior) ? 90 : 80))}
        width={telaGrande && getLeituras().length <= 6 ? 190 : (isMobile ? (minimal ? 130 : 160) : (minimal ? 80 : (getLeituras().length <= 6 || mostraMaior) ? 150 : 130))}
        ringWidth={telaGrande && getLeituras().length ? 40 : (isMobile ? 35 : (minimal ? 10 : (getLeituras().length <= 6 || mostraMaior) ? 30 : 20))}
        labelFontSize={isMobile || (telaGrande && getLeituras().length <= 6)  ? "10" : "6"}
        valueTextFontSize="0"
      />
      <Flex left={positionText} transform={"translateX(-50%)"} width={"full"} justifyContent={"center"} position={"absolute"} top={telaGrande && getLeituras().length ? "100px" : ((getLeituras().length <= 6 || mostraMaior) ? "80px" : '70px')}>
        <Text bg={minimal ? "white" : "none"} borderRadius={"10px"} px={2} opacity={"0.9"} fontSize={(telaGrande && getLeituras().length <= 6) ? '1rem' : "0.8rem"} color="#404244" fontWeight="600">
          {value.valor} <small>ppm</small>
        </Text>
      </Flex>
    </Box>
  );
}
