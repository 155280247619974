import { Button, Flex, FormControl, FormErrorMessage } from "@chakra-ui/react";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import { FiCheck, FiMapPin, FiX } from "react-icons/fi";

interface IProps {
  mb?: string;
  mt?: string;
}

interface Event<T = EventTarget> {
  target: T;
}

const InputGeolocalizacao = ({ mb, mt, ...props }: IProps) => {
  const [, { touched, error, value: valueLatitude }, { setValue: setValueLatitude }] = useField('latitude');
  const [, { value: valueLongitude }, { setValue: setValueLongitude }] = useField('longitude');

  const onOpen = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      setValueLatitude(position.coords.latitude);
      setValueLongitude(position.coords.longitude);
    });
  }

  function iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  const verNoMaps = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      const url = `https://www.google.com.br/maps/dir/${position.coords.latitude},${position.coords.longitude}/${valueLatitude},${valueLongitude}`;
      window.open(url, iOS() ? "_top" : "_blank");
    });

  }

  const { t } = useTranslation();

  return (
    <Flex>
      <FormControl p={1} mb={mb ? mb : 0} mt={mt ? mt : 0} isInvalid={touched && error ? true : false}>
        <Button size="sm" mr={1} borderWidth={error && '2px'} borderColor="red" colorScheme="teal" onClick={onOpen}><FiMapPin /> &nbsp; {t('ClienteEditInputRegGeolocalizacao')}</Button>
        {valueLatitude && valueLongitude && <Button mr={1} size="sm" colorScheme="teal" onClick={verNoMaps}><FiCheck /> &nbsp; {t('ClienteEditInputVerNoMaps')} </Button>}
        {valueLatitude && valueLongitude && <Button size="sm" colorScheme="red" onClick={() => { setValueLatitude(''); setValueLongitude('') }}><FiX /> </Button>}
        {error && <FormErrorMessage>{error}</FormErrorMessage>}
      </FormControl>
    </Flex>
  )
}

export default InputGeolocalizacao;