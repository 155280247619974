import { useIsAuth } from "../../hooks/useIsAuth"
import Layout from "../components/Layout";
import { ListHeader } from "../components/ListHeader";
import { DefaultButton } from "../components/DefaultButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import ResponsiveTable, { TableHeaders } from "../components/ResponsiveTable";
import { useState, useEffect } from 'react';
import { sistemaActions } from "../recucer";
import { ListInput } from "../components/ListInput";
import { Pagination } from "../components/Pagination";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";
import { ConfirmButton } from "../components/ConfirmButton";
import { FiTrash } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { TipoFosfina } from "../types/tipo_fosfina";
import { Caminhao } from "../types/caminhao";

export const CaminhaoList = () => {
  useIsAuth();

  const values = useSelector((state: RootState) => state.sistema.caminhoes);
  const isLoading = useSelector((state: RootState) => !!state.sistema.isLoading);
  const success = useSelector((state: RootState) => !!state.sistema.success);
  const dispatch = useDispatch()

  const [page, setPage] = useState(1);
  const [termo, setTermo] = useState("");
  const eDesenvolvedor = useSelector((state: RootState) => (state.sistema.user as any)?.e_desenvolvedor == 1);
  useEffect(() => {
    dispatch(sistemaActions.caminhoesRequest({
      page,
      termo,
    }))
  }, [dispatch, page, termo])

  useEffect(() => {
    if (!success) {
      return;
    }
    dispatch(sistemaActions.caminhoesRequest({
      page,
      termo,
    }))
  }, [dispatch, success, page, termo])

  const { t } = useTranslation();

  const headers: TableHeaders<Caminhao>[] = [
    {
      label: "Id",
      wrapped: true,
      render: (item) => item.id,
    },
    {
      label: t('CaminhaoEditCodigo'),
      wrapped: true,
      render: (item) => item.codigo,
    },
    {
      label: t('UsuarioListAtivo'),
      wrapped: true,
      render: (item: any) => item.ativo == 1 ? t('SistemaSim') : t('SistemaNão'),
    },
    {
      label: t('SistemaAções'),
      wrapped: true,
      render: (item: any, index: any) => <>{eDesenvolvedor && <ConfirmButton confirm={() => onDeleteItem(item.id)}><FiTrash /></ConfirmButton>}</>,
      notClicable: true,
    },
  ];

  const onDeleteItem = (id: string) => {
    dispatch(sistemaActions.deleteCaminhaoRequest(id));
  }

  const navigate = useNavigate();

  return (
    <Layout>
      <ListHeader label={t('CaminhaoListTitulo')}>
        <DefaultButton ml={4} to="/caminhao/novo"> {t('SistemaNovo')} </DefaultButton>
      </ListHeader>

      <Loader isLoading={isLoading} />

      <ListInput label={t('ClientesListTermo')} onSubmit={setTermo} />

      {values && <ResponsiveTable onClick={(item: any) => navigate(`/caminhao/${item.item.id}`)} headers={headers} data={values.data} />}

      {values && <Pagination info={values} paginate={setPage} />}
    </Layout>
  )
}