import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../app/mainReducer"
import { Button, ButtonGroup, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import { FiEdit, FiImage } from "react-icons/fi";
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import { novaLeituraAmostra } from "../data/leitura_amostra";
import { Form, Formik, useField } from "formik";
import InputNumber from "../../sistema/components/InputNumber";
import InputSelect from "../../sistema/components/InputSelect";
import InputField from "../../sistema/components/InputField";
import validateForm from "../../utils/validateForm";
import { LeituraAmostra } from "../types/leitura_amostra";
import InputImage from "../../sistema/components/InputImage";
import { useEffect, useState } from "react";
import { leituraActions } from "../reducer";
import InputVerImagem from "../../sistema/components/InputVerImagem";
import getEnv from "../../utils/getEnv";
import { useTranslation } from "react-i18next";

export const APIURL: string = getEnv("REACT_APP_API_HOST");

export const DashboardAmostras = () => {
  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);
  const user: any = useSelector((state: RootState) => state.sistema.user);
  const dashboard = useSelector((state: RootState) => state.leituras.dashboard_leituras);
  const { t } = useTranslation()

  const userIsAdmin = () => {
    return user?.administrador == 1 || user?.e_operador == 1;
  }

  const amostras = dashboard?.last_leitura?.amostras ?? [];

  const ThStyle = {
    fontSize: isMobile ? '16px' : '10px',
    fontWheight: 600,
    color: '#404244',
  }

  const TdStyle: any = {
    fontSize: isMobile ? '15px' : '10px',
    textAlign: 'center',
  }

  const { isOpen, onOpen, onClose } = useDisclosure()

  const getHoras = () => {
    let inicial = 0;
    const retorno = [];

    for (let i = 0; i < 24; i++) {
      retorno.push({
        name: `${inicial} ${inicial == 0 ? t('DashboardAmostraHoraMin') : t('DashboardHoras')}`,
        value: inicial
      })

      inicial += 6;
    }

    return retorno;
  }

  const InputHoras = ({ values }: { values: LeituraAmostra }) => {
    const [, { value }, { setValue }] = useField('tipo_leitura');


    return (
      <InputSelect onChangeVal={(val) => {
        (parseInt(val ?? '0')) > 0 ? setValue('Aplicada') : setValue('Inicial')
      }} name="qt_hora" label={t('DashboardAmostraHoras')} width={"50%"}>
        {getHoras().map((i, index) => (
          <option value={i.value} key={`${index}${i.value}`}>{i.name}</option>
        ))}
      </InputSelect>
    )
  }

  const success = useSelector((state: RootState) => state.leituras.success)
  const dispatch = useDispatch();

  useEffect(() => {
    if (!success) return;

    if (success == 'Amostra salva com sucesso.') {
      onClose();
      return;
    }
  }, [success, onClose, dispatch])

  const [currentItem, setCurrentItem] = useState<LeituraAmostra | null>(null);

  const onOpenModal = (item: LeituraAmostra | null) => {
    if (!item) {
      setCurrentItem(novaLeituraAmostra())
      onOpen()
      return;
    }

    setCurrentItem(item);
    onOpen()
  }

  return (
    <Flex pt={!isMobile ? 3 : 0} px={1} width={"full"} direction={"column"} maxH={'1000px'} minH={"100px"}>
      <Flex pb={2} height={"10%"} width={"full"} alignItems={"center"} justifyContent={"center"}>
        {<Text px={1} pt={2} position={"relative"} fontSize="1.1rem" color="#404244" fontWeight="700">
          {t('DashboardAmostras')}
        </Text>}
      </Flex>

      <Flex height={"90%"} width={"full"} wrap={"wrap"} alignItems={""}>
        <Text mb={1} position={"relative"} fontSize={"1rem"} color="#404244" fontWeight="600">
          Produto: {t('Dashboard' + (dashboard?.last_leitura?.produto ?? '').trim())}
        </Text>

        <Flex maxH={!isMobile ? '230px' : "1000px"} overflowX={"hidden"} overflowY={"auto"} width={"full"}>
          <table style={{ width: '100%' }}>
            <thead style={{ width: '100%', position: 'sticky', top: 0, backgroundColor: 'white' }}>
              <tr style={{ width: '100%' }}>
                <th style={{
                  ...ThStyle,
                  width: '10%'
                }}>N°</th>
                <th style={{
                  ...ThStyle,
                  width: '30%'
                }}>{t('DashboardAmostra')}</th>
                <th style={{
                  ...ThStyle,
                  width: '30%'
                }}>PPB</th>
                <th style={{
                  ...ThStyle,
                  width: '15%'
                }}>{t('DashboardAmostraHora')}</th>
                <th style={{ width: "15%" }}></th>
              </tr>
            </thead>
            <tbody>
              {amostras.map((i) =>
              (<tr key={i.id}>
                <td style={TdStyle}>
                  {i.sequencial}
                </td>
                <td style={TdStyle}>
                  {t(`DashboardAmostra${(i.tipo_leitura ?? '').trim()}`)}
                </td>
                <td style={TdStyle}>
                  {parseFloat(i.concentracao as any).toFixed(0)}ppb
                </td>
                <td style={TdStyle}>
                  {i.qt_hora}h
                </td>
                <td style={TdStyle}>
                  <ButtonGroup>
                    {i.imagem && <InputVerImagem url={`https://api.ecozonedobrasil.com/storage/images/${i.imagem}`} label={<FiImage size={isMobile ? '16px' : '12px'} />} />}
                    {userIsAdmin() && <Button onClick={(() => onOpenModal({
                      ...i,
                      imagem: i.imagem ? `https://api.ecozonedobrasil.com/storage/images/${i.imagem}` : ""
                    }))} size={"xs"} color={"blackAlpha"}>
                      <FiEdit />
                    </Button>}
                  </ButtonGroup>
                </td>
              </tr>))}
            </tbody>
          </table>
        </Flex>

        {userIsAdmin() && dashboard?.last_leitura?.id && <DefaultButton className="no-print" onClick={() => onOpenModal(null)} mx={'auto'} mb={2} mt={2} size={"sm"}>{t('SistemaNova') + ' ' + t('DashboardAmostraMin')}</DefaultButton>}
      </Flex>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"2xl"}>
        <ModalOverlay />
        <ModalContent top={-10}>
          <ModalHeader>
            <ListHeaderSecondary label={currentItem?.id ? t('SistemaEditar') + ' ' + t('DashboardAmostraMin') : t('SistemaNova') + ' ' + t('DashboardAmostraMin')} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {currentItem && <Formik
              initialValues={currentItem}
              enableReinitialize
              onSubmit={(values, { setErrors }) => {
                if ((values.concentracao ?? 0) == 0) {
                  setErrors({
                    concentracao: t('DashboardAmostraIndiceDomValidacao')
                  })
                  return;
                }

                const validation = validateForm({
                  'tipo_leitura': 'required',
                  'data_hora': 'required',
                }, values, t);

                if (Object.keys(validation).length > 0) {
                  setErrors(validation)
                  return;
                }
                console.log("amostra form", values);
                dispatch(leituraActions.leiturasAmostrasSaveRequest({
                  ...values,
                  leitura_id: dashboard?.last_leitura.id as number,
                  changed: true,
                }));
              }}
            >
              {({ values }) => (
                <Form>
                  <Flex width="full" wrap={"wrap"}>
                    <InputSelect disabled name="tipo_leitura" label="Tipo de leitura" width={"50%"}>
                      <option value="Inicial">{t('DashboardAmostraInicial')}</option>
                      <option value="Aplicada">{t('DashboardAmostraAplicada')}</option>
                    </InputSelect>
                    <InputField type="datetime-local" name="data_hora" label={t('DashboardAmostraData/Hora')} width={"50%"} />
                    <InputNumber decimalPlaces={0} name="concentracao" label={t('DashboardAmostraIndiceDom')} width={"50%"} />
                    <InputHoras values={values} />

                    <InputImage name="imagem" label={t('SistemaImagem')} />
                  </Flex>
                  <DefaultButton mt={4} type="submit">{t('DashboardSalvar')}</DefaultButton>
                  <Button mt={4} colorScheme={"blackAlpha"} ml={2} onClick={onClose}>{t('DashboardFechar')}</Button>
                </Form>)}
            </Formik>}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  )
}