import { format, parse } from "date-fns";
import ptBrLocale from "date-fns/locale/pt-BR";

export const formatData = (
  data: string | number,
  template = "dd/MM/yyyy HH:mm"
) => {
  if (!data) {
    return "";
  }
  if (template === "dd/MM/yyyy") {
    const parsedDate = typeof data === "string" ? parse(data, "yyyy-MM-dd", new Date()) : new Date(data);
    return format(parsedDate, template, { locale: ptBrLocale });
  }
  return format(new Date(data), template, { locale: ptBrLocale });
};

export const getCurrentData = (template = "yyyy-MM-dd HH:mm") => {
  return format(new Date(), template, { locale: ptBrLocale });
};

export const getDataWithLocale = (
  data: string,
  template = "yyyy-MM-dd HH:mm:ss"
) => {
  console.log(data)
  return parse(data, template, new Date(), { locale: ptBrLocale });
};
