import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react"
import { DefaultButton } from "./DefaultButton"
import { ListHeaderSecondary } from "./ListHeaderSecondary"
import { useState, useEffect } from 'react'
import { User } from "../types/user"
import { novoUser } from "../data/user"
import { UserForm } from "./UserForm"
import { useSelector } from "react-redux"
import { RootState } from "../../app/mainReducer"
import { useTranslation } from "react-i18next"

export const ClienteUnidadesResponsaveisNovo = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [currentItem, setCurrentItem] = useState<User | null>(null);
  const success = useSelector((state: RootState) => !!state.sistema.success);

  useEffect(() => {
    if (success) {
      onClose();
      setCurrentItem(null);
    }
  }, [success, onClose]);
  const cadastrar = () => {
    setCurrentItem(novoUser());
    onOpen()
  }

  const { t } = useTranslation();

  return (
    <>
      <DefaultButton ml={2} onClick={() => cadastrar()}>{t('ClienteResponsaveisNovo')}</DefaultButton>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"2xl"}>
        <ModalOverlay />
        <ModalContent top={-10}>
          <ModalHeader>
            <ListHeaderSecondary label={t('ClienteResponsaveisNovoTitulo')} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <UserForm currentItem={currentItem} onClose={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}