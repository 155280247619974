import { useField, Formik, Form } from "formik";
import ResponsiveTable, { TableHeaders } from "./ResponsiveTable";
import { ListHeaderSecondary } from "./ListHeaderSecondary";
import { DefaultButton } from "./DefaultButton";
import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { useState, ReactNode } from 'react'
import validateForm from "../../utils/validateForm";
import { FiTrash } from "react-icons/fi";
import { mensagemErro } from "../../utils/toasts";
import { useTranslation } from "react-i18next";

interface IProps {
  headers: TableHeaders<any>[];
  name: string;
  novoItemObject: any;
  tituloForm: string;
  editTituloForm: string;
  form: (item: any, setFieldValue?: any) => ReactNode;
  topForm?: () => ReactNode;
  abriuModal?: () => void;
  antesAbrirModal?: () => void;
  validation?: { [item: string]: string };
  formatValuesBeforeSave?: (item: any) => any;
  afterSave?: () => any;
  afterDelete?: () => any;
  hasMenuActions?: boolean;
  canSave?: (item: any) => boolean;
  canEdit?: boolean;
  minH?: string;
  tfoot?: ReactNode;
  extraButtonsOnTop?: () => ReactNode;
}

export const Filhos = ({ headers, name, novoItemObject, tituloForm, editTituloForm, validation = {}, form, extraButtonsOnTop, afterDelete, tfoot, canSave, topForm, abriuModal, antesAbrirModal, formatValuesBeforeSave, afterSave, hasMenuActions = true, minH = "50vh", canEdit = true }: IProps) => {
  const [, { value }, { setValue }] = useField(name);

  const { t } = useTranslation();

  const menuActions = hasMenuActions ? [{
    label: t('SistemaAções'),
    wrapped: true,
    render: (item: any, index: any) => <><Button size={"sm"} colorScheme="red" onClick={() => onDeleteItem(index)}><FiTrash /></Button></>,
    notClicable: true,
  }] : [];

  const getHeaders = () => {
    return [
      ...headers,
      ...menuActions,
    ]
  }

  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [currentItem, setCurrentItem] = useState<any>(null);
  const { isOpen, onOpen, onClose } = useDisclosure()

  const onOpenModal = (index: string | number) => {
    if (index === 'novo') {
      if (typeof antesAbrirModal === 'function') {
        antesAbrirModal();
      }
      setCurrentIndex(-1)
      setCurrentItem(novoItemObject)
      onOpen()
      if (typeof abriuModal === 'function') {
        setTimeout(() => {
          abriuModal();
        }, 0)
      }
      return;
    }

    if (!canEdit) {
      return;
    }

    if (typeof antesAbrirModal === 'function') {
      antesAbrirModal();
    }
    setCurrentIndex(index as number);
    setCurrentItem(value[index]);
    onOpen()
    if (typeof abriuModal === 'function') {
      setTimeout(() => {
        abriuModal();
      }, 0)
    }
  }

  const onSaveModal = (form: any) => {
    const values = JSON.parse(JSON.stringify(value ?? [])).filter((i: any) => !i?.deleted);
    const valuesDeleted = JSON.parse(JSON.stringify(value ?? [])).filter((i: any) => i?.deleted);

    form = {
      ...form,
      changed: true,
    }

    if (typeof canSave === 'function') {
      if (!canSave(form)) {
        return;
      }
    }

    if (currentIndex === -1) {
      const valuesToPush = [
        ...values,
        ...valuesDeleted,
      ]
      valuesToPush.push(form);
      setValue(valuesToPush)
      onClose();
      setCurrentItem(null);
      typeof afterSave === 'function' && afterSave();
      return;
    }

    values[currentIndex] = {
      ...values[currentIndex],
      ...form,
    };
    setValue([
      ...values,
      ...valuesDeleted,
    ])
    onClose();
    setCurrentItem(null);
    setCurrentIndex(-1);
    typeof afterSave === 'function' && afterSave();
  }

  const onDeleteItem = (index: number) => {
    const values = JSON.parse(JSON.stringify(value)).filter((i: any) => !i?.deleted);
    const valuesDeleted = JSON.parse(JSON.stringify(value)).filter((i: any) => i?.deleted);

    if (index === -1) return;

    if (!values[index]?.id) {
      values.splice(index, 1);
      setValue([
        ...values,
        ...valuesDeleted,
      ])
      typeof afterDelete === 'function' && afterDelete();
      return;
    }

    values[index] = {
      ...values[index],
      deleted: true,
      changed: true,
    };

    setValue([
      ...values,
      ...valuesDeleted,
    ])
    typeof afterDelete === 'function' && afterDelete();
  }

  return (
    <div className="my-2 w-full">
      <ListHeaderSecondary label={tituloForm}>
        <DefaultButton size="sm" ml={4} onClick={() => onOpenModal('novo')}>
          {t('SistemaNovo')}
        </DefaultButton>
        {typeof extraButtonsOnTop === 'function' && extraButtonsOnTop()}
      </ListHeaderSecondary>
      {value && <ResponsiveTable tfoot={tfoot} onClick={(item: any) => onOpenModal(item.index)} headers={getHeaders()} data={value.filter((i: any) => !i?.deleted)} />}

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"2xl"}>
        <ModalOverlay />
        <ModalContent top={-10}>
          <ModalHeader>
            <ListHeaderSecondary label={currentItem?.id ? `${t('SistemaEditar')} ${editTituloForm}` : `${t('SistemaCriar')} ${editTituloForm}`} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody minH={minH}>
            {typeof topForm === 'function' && topForm()}

            {currentItem && <Formik
              enableReinitialize
              initialValues={currentItem}
              onSubmit={async (val, { setErrors }) => {
                const isOk = validateForm(validation, val, t)
                if (Object.keys(isOk).length > 0) {
                  setErrors(isOk)
                  mensagemErro(t('CorrigaOsErrosESalveNovamente'))
                  return;
                }

                if (typeof formatValuesBeforeSave === 'function') {
                  onSaveModal({
                    ...(await formatValuesBeforeSave(val)),
                    changed: true,
                  });
                  return;
                }

                onSaveModal({
                  ...val,
                  changed: true,
                });
              }}
            >
              {({ values, setFieldValue }) => (
                <Form style={{
                  maxHeight: "calc(100vh - 220px)",
                  overflowY: 'auto'
                }}>
                  <Flex width="full" wrap="wrap">

                    {form(values, setFieldValue)}

                    <hr style={{ width: '100%', marginTop: 12 }} />

                    <DefaultButton type="submit" mt={4}>Salvar {editTituloForm}</DefaultButton>
                    <Button onClick={onClose} ml={2} mt={4} mb={4} colorScheme="blackAlpha">Voltar</Button>
                  </Flex>
                </Form>)}
            </Formik>}
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  )
}