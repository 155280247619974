import { Box, Flex, Icon, ModalBody, ModalCloseButton, ModalOverlay, Modal, Text, useDisclosure, Button, ModalHeader, ModalContent } from "@chakra-ui/react";
import React from "react";
import { ClienteUnidade } from "../../sistema/types/cliente_unidade";
import { useTranslation } from "react-i18next";
import MapBait from "./Mapbait";
import { FiMap, FiMaximize2 } from "react-icons/fi";
import { BodyCenterVisit } from "../types/body_center_visit";
import { getBaitsTypes } from "../utils/bodyCenterBaits";
import { BodyCenterVisitOccurrence } from "../types/body_center_visit_occurrence";
import config from "../../../config";

type FirstTabProps = {
  unit: ClienteUnidade;
  selectedVisits: BodyCenterVisit[];
}

export const FirstTab = ({ unit, selectedVisits }: FirstTabProps) => {
  const { t } = useTranslation();

  const isConsumed = (occurrence: BodyCenterVisitOccurrence) => {
    const ocurrences = (getBaitsTypes().find(baitType => baitType.value == occurrence.bait_type)?.occurrences ?? []);
    if (!ocurrences.length) {
      return false;
    }
    return ocurrences.find(o => o.value == occurrence.bait_type_occurrence)?.isConsumed == 1;
  }

  const visitPointConsumed = (point: number, visit?: BodyCenterVisit) => {
    if (!visit) {
      return false;
    }
    return (visit.occurrences ?? []).filter((occurrence) => isConsumed(occurrence) && occurrence.point == point).length;
  }

  const getPointStatus = (point: number) => {
    const sortedVisits = JSON.parse(JSON.stringify(selectedVisits)).sort(function (a: any, b: any) {
      return new Date(b.date).getTime() - new Date(a.date).getTime();
    });

    const first = sortedVisits[0];
    const second = sortedVisits[1];
    const third = sortedVisits[2];
    const fourth = sortedVisits[3];

    if (
      visitPointConsumed(point, first) &&
      visitPointConsumed(point, second) &&
      visitPointConsumed(point, third) &&
      visitPointConsumed(point, fourth)
    ) {
      return 'alert3';
    }

    if (
      visitPointConsumed(point, first) &&
      visitPointConsumed(point, second) &&
      visitPointConsumed(point, third)
    ) {
      return 'alert2';
    }

    if (
      visitPointConsumed(point, first) &&
      visitPointConsumed(point, second)
    ) {
      return 'alert1';
    }

    return '';
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const MapContent = () => (
    <MapBait
      key={selectedVisits.map((visit) => visit.id).join('-')}
      baseImage={unit.body_center_map_image}
      clientUnit={{
        ...unit,
        body_center_map_points: unit.body_center_map_points.map((point) => ({
          ...point,
          status: getPointStatus(point.text)
        }))
      }}
      setPosition={(position: any) => { }}
      setPoints={(points: any) => { }}
      isStatic={true}
    />
  );


  return (
    <React.Fragment>
      {unit.body_center_map_image && <Box
        bg="white"
        rounded="md"
        shadow="sm"
        mb={2}
        borderWidth={1}
        borderColor="gray.200"
        className="flex flex-col md:flex-row items-center justify-center gap-6 max-w-[1100px] mx-auto p-4 md:p-1"
      >
        <div className="flex items-center gap-3">
          <div className="w-5 h-5 rounded-full border-2 border-red-500 bg-red-200"></div>
          <span className="text-gray-600">{t('BodyCenter2ConsumptionConsecutive')}</span>
        </div>

        <div className="flex items-center gap-3">
          <div className="w-8 h-8 rounded-full border-2 border-red-500 bg-red-200"></div>
          <span className="text-gray-600">{t('BodyCenter3ConsumptionConsecutive')}</span>
        </div>

        <div className="flex items-center gap-3">
          <div className="w-10 h-10 rounded-full border-2 border-red-500 bg-red-200"></div>
          <span className="text-gray-600">{t('BodyCenter4ConsumptionConsecutive')}</span>
        </div>
      </Box>
      }

      {unit.body_center_map_image && <Box
        overflowX="auto"
        overflowY="hidden"
        className="mx-auto max-w-[85vw] md:max-w-auto"
        sx={{
          '&::-webkit-scrollbar': {
            width: '12px',
            height: '12px',
            backgroundColor: '#F5F5F5',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: config.defaultColor,
            borderRadius: '2px',
            '&:hover': {
              backgroundColor: config.defaultColor,
            },
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#F5F5F5',
            borderRadius: '2px',
          },
        }}
      >
        <Box minWidth="1110px" minHeight="500px" position="relative">
          <MapContent />
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            onTouchStart={(e) => e.stopPropagation()}
            onClick={(e) => e.stopPropagation()}
            pointerEvents="auto"
            sx={{
              touchAction: "pan-x",
              userSelect: "none",
              overscrollBehavior: "contain"
            }}
          />
        </Box>
      </Box>}

      {!unit.body_center_map_image && (<Box
        bg="white"
        rounded="xl"
        p={6}
        borderWidth={1}
        borderColor="gray.200"
        shadow="sm"
        className="mx-0 md:mx-6 mt-0 md:mt-2"
      >
        <Flex
          direction="column"
          align="center"
          justify="center"
          height="100%"
          p={8}
          textAlign="center"
        >
          <Box
            bg="gray.50"
            p={8}
            rounded="full"
            mb={6}
          >
            <Icon as={FiMap} fontSize="4xl" color="gray.400" />
          </Box>
          <Text
            fontSize="xl"
            fontWeight="bold"
            color="gray.700"
            mb={3}
          >
            {t('BodyCenterSelectUnitNoImage')}
          </Text>
        </Flex>
      </Box>)}
    </React.Fragment>
  )
};
