import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../app/mainReducer"
import { Button, ButtonGroup, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import InputSelect from "../../sistema/components/InputSelect";
import { FiEdit, FiTrash } from "react-icons/fi";
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import InputField from "../../sistema/components/InputField";
import { novaLeituraAplicacao } from "../data/leitura_aplicacao";
import { LeituraAplicacao } from "../types/leitura_aplicacao";
import { useState } from "react";
import { formatData, getCurrentData } from "../../utils/data";
import { leituraActions } from "../reducer";
import { useTranslation } from 'react-i18next';

export const DashboardAplicacao = () => {
  const dashboard = useSelector((state: RootState) => state.leituras?.dashboard_leituras);
  const { isOpen, onOpen, onClose } = useDisclosure()

  const user: any = useSelector((state: RootState) => state.sistema.user);
  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);

  const userIsAdmin = () => {
    return user?.administrador == 1 || user?.e_operador == 1;
  }

  const aplicacoes = dashboard?.last_leitura?.aplicacoes ?? [];

  const ThStyle = {
    fontSize: '16px',
    fontWheight: 600,
    color: '#404244',
  }

  const TdStyle: any = {
    fontSize: '15px',
    textAlign: 'center',
  }

  const [currentItem, setCurrentItem] = useState<LeituraAplicacao | null>(null);

  const onOpenModal = (item: LeituraAplicacao | null) => {
    if (!item) {
      const novoItem = novaLeituraAplicacao(aplicacoes.length > 0 ? 'Reaplicação' : 'Aplicação');
      dispatch(leituraActions.leiturasAplicacoesSaveRequest({
        ...{
          ...novoItem,
          leitura_id: dashboard?.last_leitura?.id as number,
        },
        changed: true,
      } as any))
      return;
    }

    setCurrentItem(item);
    onOpen()
  }

  const aplicacaoAberta = () => {
    return aplicacoes.some((i) => i.data_inicio && !i.data_fim);
  }

  const temAplicacaoFechada = () => {
    return aplicacoes.some((i) => i.data_inicio && i.data_fim);
  }


  const dispatch = useDispatch();

  const finalizarAplicacaoAberta = () => {
    const aplicacao = aplicacoes.find((i) => i.data_inicio && !i.data_fim);

    if (!aplicacao) return;

    dispatch(leituraActions.leiturasAplicacoesSaveRequest({
      ...aplicacao,
      data_fim: getCurrentData(),
      changed: true,
    }))
  }

  const onDelete = (item: LeituraAplicacao) => {
    dispatch(leituraActions.leiturasAplicacoesSaveRequest({
      ...item,
      changed: true,
      deleted: true,
    }))
  }

  const { t } = useTranslation();

  return (
    <Flex mb={6} px={1} width={"full"} direction={"column"}>
      {isMobile && <Flex pb={2} height={"10%"} width={"full"} alignItems={"center"} justifyContent={"center"}>
        <Text px={1} pt={2} position={"relative"} fontSize="1.1rem" color="#404244" fontWeight="700">
          {t('DashboardInjecoesPH3')}
        </Text>
      </Flex>}

      <Flex height={"90%"} width={"full"} wrap={"wrap"} alignItems={""}>
        <Flex width={"full"}>
          <table style={{ width: '100%', maxWidth: isMobile ? "100%" : "70%", margin: 'auto' }}>
            <thead style={{ width: '100%', position: 'sticky', top: 0, backgroundColor: 'white' }}>
              <tr style={{ width: '100%' }}>
                <th style={{
                  ...ThStyle,
                  width: '35%'
                }}>{t('DashboardInjecoesDATA')}</th>
                <th style={{
                  ...ThStyle,
                  width: '35%'
                }}>{t('DashboardInjecoesTIPO')}</th>
                <th style={{ width: "10%" }}></th>
              </tr>
            </thead>
            <tbody>
              {aplicacoes.map((i) =>
              (<tr key={i.id} style={{ paddingTop: '5px' }}>
                <td style={{ ...TdStyle, textAlign: 'right' }}>
                  Início: {formatData(i.data_inicio, 'dd/MM HH:mm')} <br />
                  {i.data_fim && <>Fim: {formatData(i.data_fim, 'dd/MM HH:mm')} </>}
                </td>
                <td style={TdStyle}>
                  {t(`Dashboard${i.tipo}`)}
                </td>
                <td style={TdStyle}>
                  <ButtonGroup>
                    {userIsAdmin() && <Button onClick={() => onOpenModal(i)} size={"xs"} color={"blackAlpha"}>
                      <FiEdit />
                    </Button>}
                    {userIsAdmin() && <Button onClick={() => onDelete(i)} size={"xs"} color={"red"}>
                      <FiTrash />
                    </Button>}
                  </ButtonGroup>
                </td>
              </tr>))}
            </tbody>
          </table>
        </Flex>

        {userIsAdmin() && dashboard?.last_leitura?.id && <DefaultButton className="no-print" onClick={() => { aplicacaoAberta() ? finalizarAplicacaoAberta() : onOpenModal(null) }} mx={'auto'} mb={2} mt={2} size={isMobile ? "sm" : "xs"}>{aplicacaoAberta() ? t('DashboardFinalizar') : t('DashboardIniciar')} {temAplicacaoFechada() ? t(`DashboardReaplicação`) : t(`DashboardAplicação`)}</DefaultButton>}
      </Flex>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"2xl"}>
        <ModalOverlay />
        <ModalContent top={-10}>
          <ModalHeader>
            <ListHeaderSecondary label={currentItem?.id ? t('SistemaEditar') + ` ${currentItem?.tipo && currentItem.tipo.trim() == 'Aplicação' ? t('DashboardAplicação') : t('DashboardReaplicação')}` : t('SistemaNova') + ` ${currentItem?.tipo && currentItem.tipo.trim() == 'Aplicação' ? t('DashboardAplicação') : t('DashboardReaplicação')}`} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {currentItem && <Formik
              initialValues={currentItem}
              enableReinitialize
              onSubmit={(values, { setErrors }) => {
                console.log("aplicação form", values);
                dispatch(leituraActions.leiturasAplicacoesSaveRequest({
                  ...values,
                  changed: true,
                }))
              }}
            >
              {({ values }) => (
                <Form>
                  <Flex width="full" wrap={"wrap"}>
                    {values?.id && <InputSelect
                      label={t('DashboardInjecoesTipoMin')}
                      name="tipo"
                      width={"100%"}
                    >
                      <option value="Aplicação">{t('DashboardAplicação')}</option>
                      <option value="Reaplicação">{t('DashboardReaplicação')}</option>
                    </InputSelect>}

                    {values?.id && <InputField
                      label={t('DashboardDataInicial')}
                      name="data_inicio"
                      type="datetime-local"
                      width={isMobile ? "100%" : "50%"}
                    />}
                    {values?.id && <InputField
                      label={t('DashboardDataFinal')}
                      name="data_fim"
                      type="datetime-local"
                      width={isMobile ? "100%" : "50%"}
                    />}
                  </Flex>
                  <Flex width={"full"} justifyContent={"space-between"}>
                    <Button mt={4} colorScheme={"blackAlpha"} ml={2} onClick={onClose}>{t('DashboardFechar')}</Button>
                    <DefaultButton mt={4} type="submit">
                      {values?.id && t('DashboardSalvar')}
                      {!values?.id && `${t('DashboardIniciar')} ${t(`Dashboard${values.tipo}`)}`}
                    </DefaultButton>
                  </Flex>
                </Form>)}
            </Formik>}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  )
}