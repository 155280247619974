import { Flex, Icon, Text } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FiX } from "react-icons/fi";

export const NotFound = () => {
  const { t } = useTranslation();

  return (
    <Box
      bg="white"
      rounded="xl"
      p={6}
      borderWidth={1}
      borderColor="gray.200"
      shadow="sm"
    >
      <Flex
        direction="column"
        align="center"
        justify="center"
        height="100%"
        p={8}
        textAlign="center"
      >
        <Box
          bg="gray.50"
          p={8}
          rounded="full"
          mb={6}
        >
          <Icon as={FiX} fontSize="4xl" color="gray.400" />
        </Box>
        <Text
          fontSize="xl"
          fontWeight="bold"
          color="gray.700"
          mb={3}
        >
          {t('BodyCenterNotFound')}
        </Text>
      </Flex>
    </Box>
  )
};
