import React from "react";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import { useField } from "formik";
import { InputHTMLAttributes } from "react";

export type InputSelectProps = InputHTMLAttributes<HTMLSelectElement> & {
  name: string;
  inputSize?: "xs" | "sm" | "md" | "lg";
  label?: string;
  bgNone?: boolean;
  onChangeVal?: (val: string) => void;
  labelSize?: string;
};

const   InputSelect: React.FC<InputSelectProps> = ({
  label,
  bgNone = false,
  size: _,
  inputSize = "md",
  width,
  labelSize,
  onChangeVal,
  ...props
}) => {
  const [field, { error }, { setValue }] = useField(props as any);
  return (
    <FormControl isInvalid={!!error} width={width} p={1}>
      {label && <FormLabel fontSize={labelSize ? labelSize : "16px"} mb={0} mt={2} htmlFor={field.name}>{label}</FormLabel>}
      <Select
        onChange={(e) => { setValue(e.target.value, false); typeof onChangeVal === 'function' && onChangeVal(e.target.value) }}
        value={field.value}
        overflow={"hidden"}
        textOverflow={"ellipsis"}
        {...props}
        id={field.name}
        placeholder={props.placeholder ? props.placeholder : label}
        bg={!bgNone ? 'white' : 'none'}
        color={!bgNone ? 'black' : 'white'}
        borderRadius={2}
        borderWidth={2}
        size={inputSize}
      >
        {props.children}
      </Select>
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};

export default InputSelect;
