import { Box, Flex, Heading, Img, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Select, Text, useDisclosure } from "@chakra-ui/react"
import { useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useTranslation } from "react-i18next";
import { DashboardCard } from "./DashboardCard";
import { DashboardLegendaCores } from "./DashboardLegendaCores";
import { DashboardNomeLeitura } from "./DashboardNomeLeitura";
import { DashboardVerMais } from "./DashboardVerMais";
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import { useEffect, useState } from 'react';
import { formatData } from "../../utils/data";
import { ReactECharts } from "./ReactECharts";
import { tipoConfiguracao } from "../arrays/tipo_configuracao";
import { getBgColor } from "./Silo";
import { getDados } from "./DashboardGraficoLinha";
import { Leitura } from "../types/leitura";
import { LeituraDados } from "../types/leitura_dados";
import { differenceInMinutes } from "date-fns";
import { formatValor } from "../../utils/formatValor";
import imagesUrl from "../../utils/imagesUrl";

interface Item {
  valor: number;
  data: string;
  ponto: string;
  posicaoPonto: number;
}

const siloPontoCentral = imagesUrl + "armazenamentos/silo_ponto_central.png";

export const DashboardPontosLeitura = () => {
  const dashboard = useSelector((state: RootState) => state.leituras.dashboard_leituras);
  const injecoes = useSelector((state: RootState) => state.leituras.injecoes);

  interface Item {
    valor: number;
    data: string;
    ponto: string;
    posicaoPonto: number;
  }
  const { t } = useTranslation();
  const temOPontoConfigurado = (ponto: number) => {
    return dashboard?.sensores?.some((i) => i.posicao == ponto);
  }

  const temOPontoConfiguracao = (configuracao: string) => {
    return dashboard?.sensores?.find((i) => (i.configuracao ?? '').trim() == configuracao);
  }

  const getLeituras = (last_data: any): Item[] => {
    if (!dashboard?.last_data || !dashboard?.sensores || !last_data) return [
      {
        valor: 0,
        data: "",
        ponto: `${t('DashboardPonto')} 1`,
        posicaoPonto: 0,
      },
      {
        valor: 0,
        data: "",
        ponto: `${t('DashboardPonto')} 2`,
        posicaoPonto: 0,
      },
      {
        valor: 0,
        data: "",
        ponto: `${t('DashboardPonto')} 3`,
        posicaoPonto: 0,
      },
      {
        valor: 0,
        data: "",
        ponto: `${t('DashboardPonto')} 4`,
        posicaoPonto: 0,
      },
      {
        valor: 0,
        data: "",
        ponto: `${t('DashboardPonto')} 5`,
        posicaoPonto: 0,
      },
      {
        valor: 0,
        data: "",
        ponto: `${t('DashboardPonto')} 6`,
        posicaoPonto: 0,
      },
    ];

    let grafico: any = [];

    // const last_data: any = dashboard.last_data;

    for (let i = 1; i <= 16; i++) {
      if (temOPontoConfigurado(i)) {
        grafico.push({
          valor: last_data[`c${i}`],
          data: last_data[`t${i}`],
          ponto: `${t('DashboardPonto')} ${i} ${temOPontoConfiguracao('Retorno')?.posicao == i ? `(${t('DashboardRetorno')})` :
            temOPontoConfiguracao('Injeção superior')?.posicao == i ? `(${t('DashboardInpecaoSuperior')})` :
              temOPontoConfiguracao('Injeção inferior')?.posicao == i ? `(${t('DashboardInpecaoInferior')})` : ''
            }`,
          posicaoPonto: i,
        },)
      }
    }

    return grafico;
  };

  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);
  const eFosfina = dashboard?.last_leitura?.tipo_leitura == 'PH3 (Fosfina)';
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [pontoSelecionado, setPontoSelecionado] = useState(1);

  const getProfundidadePonto = () => {
    const ponto = dashboard?.sensores?.find((i) => i.posicao == pontoSelecionado);
    return parseFloat(ponto?.profundidade as any ?? '0');
  }

  const getPontosDisponiveis = () => {
    const pontos = [];

    for (let i = 1; i <= 16; i++) {
      if (temOPontoConfigurado(i)) {
        pontos.push(i);
      }
    }

    return pontos;
  }

  const getValorAtualPontoSelecionado = () => {
    return getLeituras(dashboard?.last_data as LeituraDados).find((i) => i.posicaoPonto == pontoSelecionado)?.valor ?? 0
  }

  const getDataPeloPontoSelecionado = () => {
    const retorno = [];
    let ultimoItem = null;
    let ultimoPonto = null;
    for (const injecao of (injecoes ?? [])) {
      if (ultimoItem) {
        const dataFinal = new Date(injecao.data_hora);
        const dataInicial = new Date(ultimoItem.data_hora);

        const pontoEncontrado = dashboard?.data?.find((i) => new Date(i.data) < dataFinal && new Date(i.data) > dataInicial);

        if (pontoEncontrado) {
          ultimoPonto = pontoEncontrado;
          retorno.push((pontoEncontrado as any)[`c${pontoSelecionado}`])
        } else if (ultimoPonto) {
          retorno.push((ultimoPonto as any)[`c${pontoSelecionado}`])
        } else {
          retorno.push(0)
        }

      } else {
        retorno.push(0)
      }

      ultimoItem = injecao;
    }

    return retorno;
  }

  const getDataPelaMediaDosPontosSelecionado = () => {
    const retorno = [];
    let ultimoItem = null;
    let ultimoPonto = null;
    for (const injecao of (injecoes ?? [])) {
      if (ultimoItem) {
        const dataFinal = new Date(injecao.data_hora);
        const dataInicial = new Date(ultimoItem.data_hora);

        const pontoEncontrado = dashboard?.data?.find((i) => new Date(i.data) < dataFinal && new Date(i.data) > dataInicial);

        if (pontoEncontrado) {
          ultimoPonto = pontoEncontrado;
          let valorTotal = 0;
          let qtPontosAtivo = 0;

          for (let index = 1; index <= 16; index++) {
            if (temOPontoConfigurado(index)) {
              qtPontosAtivo += 1;
              valorTotal += (ultimoPonto as any)[`c${index}`]
            }

          }

          retorno.push((valorTotal / qtPontosAtivo).toFixed(0))
        } else if (ultimoPonto) {
          let valorTotal = 0;
          let qtPontosAtivo = 0;

          for (let index = 1; index <= 16; index++) {
            if (temOPontoConfigurado(index)) {
              qtPontosAtivo += 1;
              valorTotal += (ultimoPonto as any)[`c${index}`]
            }

          }

          retorno.push((valorTotal / qtPontosAtivo).toFixed(0))
        } else {
          retorno.push(0)
        }

      } else {
        retorno.push(0)
      }

      ultimoItem = injecao;
    }

    return retorno;
  }

  const optionGrafico1 = {
    title: {
      text: ''
    },
    tooltip: {
      valueFormatter: (value: string) => `${parseFloat(value)}ppm`,
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }
      }
    },
    legend: {
      data: [t('DashboardPontosVerMaisComparativoInjecaoTitulo'), t('DashboardPontosVerMaisComparativoInjecaoSubtitulo')]
    },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: injecoes?.map((i, index) => {
          if (index == 0) {
            return 0;
          }

          return `${differenceInMinutes(new Date(i.data_hora), new Date(injecoes[0].data_hora))} ${t('DashboardMinuto')}`
        }) ?? []
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: [
      {
        name: t('DashboardPontosVerMaisComparativoInjecaoTitulo'),
        type: 'line',
        stack: 'Total',
        itemStyle: {
          color: '#879ad7'
        },
        areaStyle: {},
        emphasis: {
          focus: 'series'
        },
        data: getDataPeloPontoSelecionado()
      },
      {
        name: t('DashboardPontosVerMaisComparativoInjecaoSubtitulo'),
        type: 'line',
        stack: 'Total',
        itemStyle: {
          color: '#b2dc9f'
        },
        areaStyle: {},
        emphasis: {
          focus: 'series'
        },
        data: injecoes?.map((i) => i.concentracao_injecao) ?? []
      },
    ]
  };

  const optionGrafico2 = {
    title: {
      text: ''
    },
    tooltip: {
      valueFormatter: (value: string) => `${parseFloat(value)}ppm`,
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }
      }
    },
    legend: {
      data: [t('DashboardPontosVerMaisComparativoInjecaoTitulo'), t('DashboardPontosVerMaisComparativoRetornoSubtitulo')]
    },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: injecoes?.map((i, index) => {
          if (index == 0) {
            return 0;
          }

          return `${differenceInMinutes(new Date(i.data_hora), new Date(injecoes[0].data_hora))} ${t('DashboardMinuto')}`
        }) ?? []
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: [
      {
        name: t('DashboardPontosVerMaisComparativoRetornoSubtitulo'),
        type: 'line',
        stack: 'Total',
        itemStyle: {
          color: '#b2dc9f'
        },
        areaStyle: {},
        emphasis: {
          focus: 'series'
        },
        data: injecoes?.map((i) => i.concentracao_retorno) ?? []
      },
      {
        name: t('DashboardPontosVerMaisComparativoInjecaoTitulo'),
        type: 'line',
        stack: 'Total',
        itemStyle: {
          color: '#879ad7'
        },
        areaStyle: {},
        emphasis: {
          focus: 'series'
        },
        data: getDataPeloPontoSelecionado()
      },
    ]
  };

  const optionGrafico3 = {
    title: {
      text: ''
    },
    tooltip: {
      valueFormatter: (value: string) => `${parseFloat(value)}ppm`,
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }
      }
    },
    legend: {
      data: [t('DashboardPontosVerMaisComparativoInjecaoTituloMedia'), t('DashboardPontosVerMaisComparativoInjecaoSubtitulo'), t('DashboardPontosVerMaisComparativoRetornoSubtitulo')]
    },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: injecoes?.map((i, index) => {
          if (index == 0) {
            return 0;
          }

          return `${differenceInMinutes(new Date(i.data_hora), new Date(injecoes[0].data_hora))} ${t('DashboardMinuto')}`
        }) ?? []
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: [
      {
        name: t('DashboardPontosVerMaisComparativoRetornoSubtitulo'),
        type: 'line',
        stack: 'Total',
        areaStyle: {},
        emphasis: {
          focus: 'series'
        },
        data: injecoes?.map((i) => i.concentracao_retorno) ?? []
      },
      {
        name: t('DashboardPontosVerMaisComparativoInjecaoTituloMedia'),
        type: 'line',
        stack: 'Total',
        areaStyle: {},
        emphasis: {
          focus: 'series'
        },
        data: getDataPelaMediaDosPontosSelecionado()
      },
      {
        name: t('DashboardPontosVerMaisComparativoInjecaoSubtitulo'),
        type: 'line',
        stack: 'Total',
        areaStyle: {},
        emphasis: {
          focus: 'series'
        },
        data: injecoes?.map((i) => i.concentracao_injecao) ?? []
      },

    ]
  };


  const getComparacaoPontoERetorno = () => {
    const retorno = {
      ppm: '',
      porcentagem: 0,
    }

    if (getDataPeloPontoSelecionado().length == 0) return retorno;
    if (!injecoes?.length) return retorno;

    const pontoSelecionado = getDataPeloPontoSelecionado()[getDataPeloPontoSelecionado().length - 1];
    const injecao = injecoes[injecoes.length - 1].concentracao_retorno;

    if (pontoSelecionado > injecao) {
      retorno.ppm = `${(pontoSelecionado - injecao).toFixed(0)}ppm ${t('DashboardPontosVerMaisAMais')}`;
      if (injecao <= 0) {
        retorno.porcentagem = 0;
      } else {
        retorno.porcentagem = (injecao * 100) / pontoSelecionado;
      }
      return retorno;
    }

    retorno.ppm = `${(injecao - pontoSelecionado).toFixed(0)}ppm ${t('DashboardPontosVerMaisAMenos')}`;
    retorno.porcentagem = 0;
    return retorno;
  }

  const getComparacaoPontoEInjecao = () => {
    const retorno = {
      ppm: '',
      porcentagem: 0,
    }

    if (getDataPeloPontoSelecionado().length == 0) return retorno;
    if (!injecoes?.length) return retorno;

    const pontoSelecionado = getDataPeloPontoSelecionado()[getDataPeloPontoSelecionado().length - 1];
    const injecao = injecoes[injecoes.length - 1].concentracao_injecao;

    if (pontoSelecionado < injecao) {
      retorno.ppm = `${(injecao - pontoSelecionado).toFixed(0)}ppm ${t('DashboardPontosVerMaisAMenos')}`;
      if (injecao <= 0) {
        retorno.porcentagem = 0;
      } else {
        retorno.porcentagem = (pontoSelecionado * 100) / injecao;
      }
      return retorno;
    }

    retorno.ppm = `${(pontoSelecionado - injecao).toFixed(0)}ppm ${t('DashboardPontosVerMaisAMais')}`;
    retorno.porcentagem = 100;
    return retorno;
  }

  const larguraGrande = window.innerWidth >= 1920;

  const [leituraIdSelecionada, setLeituraSelecionada] = useState(0);

  const leituraDados = getDados(dashboard?.data ?? [], dashboard?.last_leitura as Leitura);

  useEffect(() => {
    if (leituraDados.length) {
      setLeituraSelecionada(leituraDados[leituraDados.length - 1]?.id ?? 0)
    }
  }, [dashboard?.last_leitura?.id])

  return (
    <Flex width={"100%"} pb={isMobile ? 10 : 0} minHeight={"435px"} direction={"column"} justifyContent={"space-around"} alignItems={"center"}>
      <Flex height={"10%"}>
        <Flex width={"full"} justifyContent={"center"}>
          <Flex wrap={"wrap"}>
            <Text ml={isMobile ? 2 : 0} position={"relative"} fontSize="1rem" color="#404244" fontWeight="700" pt={2}>{t('DashboardUltimaLeitura')} <DashboardNomeLeitura />
              &nbsp;  <select style={{ border: '1px solid gray', borderRadius: '5px' }} value={leituraIdSelecionada} onChange={(evt) => setLeituraSelecionada(parseInt(evt.target.value))}>
                {leituraDados.map((i, index) => (
                  <option key={index} value={i.id}>
                    {i.hora} h
                  </option>
                ))}
              </select>
              {eFosfina && <>&nbsp;</>} {eFosfina && <DashboardVerMais marginBottom="5px" onOpen={onOpen} />}
            </Text>
          </Flex>

        </Flex>
      </Flex>
      <Modal autoFocus={false} returnFocusOnClose={false} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"full"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ListHeaderSecondary label={<> {t('DashboardAvaliacaoDaInjecaoRetornoAreaFumigada')}</>}>
              <DefaultButton onClick={onClose} ml={2}>{t('DashboardVoltar')}</DefaultButton>
            </ListHeaderSecondary>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={2}>
            <Flex pb={!isMobile ? "300px" : 0} width={isMobile ? '100%' : '190vh'} justifyContent={"center"} mx={"auto"} wrap={"wrap"}>
              <Flex zIndex={1} width={isMobile ? '100%' : "60%"} wrap={"wrap"}>
                <Flex width={isMobile ? '100%' : '30%'} justifyContent={"center"} alignItems={"center"} direction={"column"}>
                  <Text color={"#404244"} fontWeight={700} fontSize={"18px"}>{t('DashboardPontosVerMaisEscolhaOPonto')} </Text>

                  <DashboardCard telaGrande={true} positionText="50%" mostraMaior={true} value={{
                    valor: getValorAtualPontoSelecionado(),
                    data: '',
                    ponto: '',
                    posicaoPonto: 1
                  }}
                  />

                  <Flex width={"full"} px={10} mt={4}>
                    <Select onChange={(evt) => setPontoSelecionado(parseInt(evt.target.value))}>
                      {getPontosDisponiveis().map((i) => (
                        <option value={i} key={`${i}`}>{t('DashboardPdfPonto')} {i} </option>
                      ))}
                    </Select>
                  </Flex>

                  {/* <Flex position={"relative"} width={"full"}>
                    {!isMobile && <Flex width="100%" wrap={"wrap"} justifyContent={"center"} alignItems={"center"}>
                      <Img width={"auto"} top={"80%"} transform={"translateY(-&0%)"} zIndex={0} position={'absolute'} height={"250px"} src={tipoConfiguracao(dashboard?.last_leitura?.tipo_configuracao as number)?.individual ? tipoConfiguracao(dashboard?.last_leitura?.tipo_configuracao as number)?.individual[`c${pontoSelecionado}`] as any : ''} />
                    </Flex>}
                  </Flex> */}
                </Flex>
                <Flex width={isMobile ? '100%' : '70%'} wrap={"wrap"}>
                  <Flex mt="2" width={"full"} direction={"column"} wrap={"wrap"} textAlign={'center'}>
                    <Heading mb={4} mx={"auto"} size={"md"} color={"#404244"}> {t('DashboardPontosVerMaisComparativoInjecao')} </Heading>
                    <ReactECharts option={optionGrafico1 as any} style={{ height: isMobile ? '200px' : '170px' }} />
                  </Flex>
                  <Flex mt="2" width={"full"} direction={"column"} wrap={"wrap"} textAlign={'center'}>
                    <Heading mb={4} mx={"auto"} size={"md"} color={"#404244"}> {t('DashboardPontosVerMaisComparativoRetorno')} </Heading>
                    <ReactECharts option={optionGrafico2 as any} style={{ height: isMobile ? '200px' : '170px' }} />
                  </Flex>
                </Flex>
              </Flex>

              {!isMobile && <Flex mt={6} width={isMobile ? '100%' : '40%'} wrap={"wrap"} position={"relative"}>
                <Flex mx={"auto"} mt={!isMobile ? 4 : 0} justifyContent={"center"} wrap={"wrap"}>
                  <Flex mt="2" width={"full"} direction={"column"} wrap={"wrap"} textAlign={'center'} justifyContent={"center"}>
                    <Heading mb={4} mx={"auto"} size={"md"} color={"#404244"}> {t('DashboardPontosVerMaisComparativoInjecaoTodosPontos')} </Heading>
                    <ReactECharts option={optionGrafico3 as any} style={{ height: '300px' }} />
                  </Flex>
                </Flex>
              </Flex>}
              <Flex maxW={"1100px"} mt={6} width={isMobile ? '100%' : '100%'} wrap={"wrap"} position={"relative"}>
                <Flex width={isMobile ? '100%' : "50%"} wrap={"wrap"} justifyContent={"center"} alignItems={"center"}>
                {(dashboard?.last_leitura?.e_leitura_de_6_silos as any) == 0 && <Img top={"50%"} transform={"translateY(-50%)"} zIndex={0} position={isMobile ? 'relative' : 'absolute'} height={isMobile ? 'auto' : "600px"} src={tipoConfiguracao(dashboard?.last_leitura?.tipo_configuracao as number)?.individual ? tipoConfiguracao(dashboard?.last_leitura?.tipo_configuracao as number)?.individual[`c${pontoSelecionado}`] as any : ''} />}
                  {(dashboard?.last_leitura?.e_leitura_de_6_silos as any) == 1 && <Img  top={"50%"} transform={"translateY(-50%)"} zIndex={0} position={isMobile ? 'relative' : 'absolute'} height={isMobile ? 'auto' : "300px"} src={siloPontoCentral} />}
                   
                </Flex>
                <Flex zIndex={1} width={isMobile ? '100%' : "50%"} wrap={"wrap"} direction={"column"}>
                  <Flex width={"full"} wrap={"wrap"}>
                    <Flex textAlign={"center"} width={"full"} color={"white"} wrap={"wrap"} p={1} borderRadius={"5px"} bg={getBgColor(pontoSelecionado)}>
                      <Text mx={"auto"} fontSize={"18px"} color={"white"}>{t('DashboardPdfPonto')} {pontoSelecionado} {!isMobile && <> - {t('DashboardPontosVerMaisProfundidadeSonda')}: {formatValor(getProfundidadePonto().toFixed(2))}m</>} </Text>
                    </Flex>
                    {isMobile && <Text width={"full"} textAlign={"center"} mt={2} fontSize={"18px"} color={"#404244"}>
                      {t('DashboardPontosVerMaisProfundidadeSonda')}: {formatValor(getProfundidadePonto().toFixed(2))}m
                    </Text>}
                  </Flex>
                  <Flex width={"full"} wrap={"wrap"}>
                    <Flex justifyContent={"center"} width={isMobile ? '100%' : "60%"} wrap={"wrap"}>
                      <ReactECharts option={{
                        series: [
                          {
                            type: 'gauge',
                            startAngle: 90,
                            endAngle: -270,
                            pointer: {
                              show: false
                            },
                            progress: {
                              show: true,
                              overlap: false,
                              roundCap: true,
                              clip: false,
                              itemStyle: {
                                borderWidth: 1,
                                borderColor: '#464646'
                              }
                            },
                            axisLine: {
                              lineStyle: {
                                width: isMobile ? '12' : '22'
                              }
                            },
                            splitLine: {
                              show: false,
                              distance: 0,
                              length: 15
                            },
                            axisTick: {
                              show: false
                            },
                            axisLabel: {
                              show: false,
                              distance: 100
                            },
                            data: [
                              {
                                value: getComparacaoPontoEInjecao().porcentagem.toFixed(0),
                                name: '',
                                title: {
                                  offsetCenter: ['0%', '-18%']
                                },
                                detail: {
                                  valueAnimation: true,
                                  offsetCenter: ['0%', '0%']
                                }
                              },
                            ],
                            title: {
                              fontSize: 14
                            },
                            detail: {
                              width: 30,
                              height: 8,
                              fontSize: 22,
                              color: 'inherit',
                              borderColor: 'inherit',
                              borderRadius: 20,
                              borderWidth: 0,
                              formatter: '{value}%',
                            }
                          }
                        ]
                      } as any
                      } style={{ height: isMobile ? '200px' : '200px' }} />
                    </Flex>
                    <Flex justifyContent={"center"} textAlign={"center"} width={isMobile ? '100%' : "40%"} direction={"column"}>
                      <Text mt={2} fontSize={"16px"} color={"#404244"}>
                        {t('DashboardPontosVerMaisDiferencaInjecao')}
                      </Text>
                      <Text mt={2} fontWeight={"700"} fontSize={"20px"} color={"#404244"}>
                        {getComparacaoPontoEInjecao().ppm}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex width={"full"} wrap={"wrap"}>
                    <Flex width={isMobile ? '100%' : "60%"} justifyContent={'center'}>
                      <ReactECharts option={{
                        series: [
                          {
                            type: 'gauge',
                            startAngle: 90,
                            endAngle: -270,
                            pointer: {
                              show: false
                            },
                            progress: {
                              show: true,
                              overlap: false,
                              roundCap: true,
                              clip: false,
                              itemStyle: {
                                borderWidth: 1,
                                borderColor: '#464646'
                              }
                            },
                            axisLine: {
                              lineStyle: {
                                width: isMobile ? '12' : '22'
                              }
                            },
                            splitLine: {
                              show: false,
                              distance: 0,
                              length: 15
                            },
                            axisTick: {
                              show: false
                            },
                            axisLabel: {
                              show: false,
                              distance: 100
                            },
                            data: [
                              {
                                value: getComparacaoPontoERetorno().porcentagem.toFixed(0),
                                name: '',
                                title: {
                                  offsetCenter: ['0%', '-18%']
                                },
                                detail: {
                                  valueAnimation: true,
                                  offsetCenter: ['0%', '0%']
                                }
                              },
                            ],
                            title: {
                              fontSize: 14
                            },
                            detail: {
                              width: 30,
                              height: 8,
                              fontSize: 22,
                              color: 'inherit',
                              borderColor: 'inherit',
                              borderRadius: 20,
                              borderWidth: 0,
                              formatter: '{value}%',
                            }
                          }
                        ]
                      } as any
                      } style={{ height: isMobile ? '200px' : '200px' }} />
                    </Flex>
                    <Flex justifyContent={"center"} textAlign={"center"} width={isMobile ? '100%' : "40%"} direction={"column"}>
                      <Text mt={2} fontSize={"16px"} color={"#404244"}>
                        {t('DashboardPontosVerMaisDiferencaRetorno')}
                      </Text>
                      <Text mt={2} fontWeight={"700"} fontSize={"20px"} color={"#404244"}>
                        {getComparacaoPontoERetorno().ppm}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            {isMobile && <Flex mb={isMobile ? '200px' : '0'} mx={"auto"} mt={4} maxW={isMobile ? '100%' : '70%'} justifyContent={"center"} wrap={"wrap"}>
              <Flex mt="2" width={"full"} direction={"column"} wrap={"wrap"} textAlign={'center'} justifyContent={"center"}>
                <Heading mb={4} mx={"auto"} size={"md"} color={"#404244"}> {t('DashboardPontosVerMaisComparativoInjecaoTodosPontos')} </Heading>
                <ReactECharts option={optionGrafico3 as any} style={{ height: '250px' }} />
              </Flex>
            </Flex>}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Flex height={"80%"} width={"100%"} wrap="wrap" justifyContent="space-around">
        {getLeituras((dashboard?.data ?? []).find((j) => j.id == leituraIdSelecionada) as LeituraDados).map((i) => (
          <Flex key={i.ponto} >
            <DashboardCard telaGrande={larguraGrande} positionText="50%" value={i} />
          </Flex>
        ))}
      </Flex>
      <Flex height={"10%"}>
        <Flex height={"35px"} width={"100%"} wrap="wrap">
          <DashboardLegendaCores />
        </Flex>
      </Flex>
    </Flex>
  )
}