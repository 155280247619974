import { Form } from "formik"

import { Formik } from "formik";
import Accordion from "./Accordion"
import { useTranslation } from "react-i18next";
import { Flex } from "@chakra-ui/react";
import { DefaultButton } from "./DefaultButton";
import { FiSearch } from "react-icons/fi";

export const ListFilters = ({ onSubmit, form }: { form: (values: any) => React.ReactNode, onSubmit: (values: Record<string, any>) => void }) => {
  const { t } = useTranslation();

  return (
    <Accordion className="my-4" items={[
      {
        title: t('SistemFilters'),
        content: <div className="w-full">
          <Formik
            enableReinitialize
            initialValues={{}}
            onSubmit={(values) => {
              onSubmit(values);
            }}
          >
            {({ values }) => (
              <Form>
                <Flex width="full" wrap="wrap">
                  {form(values)}
                  <hr style={{ width: '100%', marginTop: 5, marginBottom: 10 }} />
                  <DefaultButton className="w-full md:w-auto flex gap-2 items-center" type="submit">
                    {t('SistemaFiltrar')} <FiSearch />
                  </DefaultButton>
                </Flex>
              </Form>
            )}
          </Formik>
        </div>
      }
    ]} />
  )
}