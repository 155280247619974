import React from "react";
import {
  Button,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useField } from "formik";
import SignatureCanvas from 'react-signature-canvas';
import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

interface IProps {
  name: string;
  label: string;
  width?: string;
}


const InputAssinatura: React.FC<IProps> = ({
  label,
  width,
  ...props
}) => {
  const [field, { value, error }, { setValue }] = useField(props.name);

  const [assData, setAssData] = useState<any>(null);

  useEffect(() => {

    const interval = setInterval(() => {
      if (!assData) return;
      if (assData.isEmpty()) {
        if (value) setValue('');
      } else {
        const newData = assData.getTrimmedCanvas().toDataURL('image/png');
        if (value != newData) {
          setValue(newData)
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [assData, setValue, value]);

  const { t } = useTranslation();

  return (
    <FormControl isInvalid={!!error} mt="0.5em">
      <Button mb={1} size={"sm"} onClick={() => { assData && assData.clear() }} colorScheme="blackAlpha">
        {t('DashboardLimpar')}
      </Button>
      <SignatureCanvas
        canvasProps={{ width: width, height: 300, className: 'sigCanvas', color: 'white', style: { margin: 'auto', backgroundColor: 'white', borderRadius: '10px', border: `2px solid ${error ? 'red' : '#d3d3d3'}` } }} ref={data => setAssData(data)} />
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};

export default InputAssinatura;
