import { Flex, Img, Text } from '@chakra-ui/react';
import { useState } from "react";
import config from "../../../config";
import { useTranslation } from "react-i18next";
import imagesUrl from '../../utils/imagesUrl';

const mycotoxinAspergilluFrente = imagesUrl + "mycotoxinAspergilluFrente.png";
const mycotoxinAspergilluCostas = imagesUrl + "mycotoxinAspergilluCostas.png";
const mycotoxinClavicepsFrente = imagesUrl + "mycotoxinClavicepsFrente.png";
const mycotoxinClavicepsCostas = imagesUrl + "mycotoxinClavicepsCostas.png";
const mycotoxinFusariumFrente = imagesUrl + "mycotoxinFusariumFrente.png";
const mycotoxinFusariumCostas = imagesUrl + "mycotoxinFusariumCostas.png";
const mycotoxinPenicilliumFrente = imagesUrl + "mycotoxinPenicilliumFrente.png";
const mycotoxinPenicilliumCostas = imagesUrl + "mycotoxinPenicilliumCostas.png";

export const DashboardToxinas = () => {
  const { t } = useTranslation()

  const insetos = [
    {
      nome: 'Aspergpillus',
      imagemFrente: mycotoxinAspergilluFrente,
      imagemCostas: mycotoxinAspergilluCostas,
      descricao: t('DashboardToxinas1'),
    },
    {
      nome: 'Claviceps',
      imagemFrente: mycotoxinClavicepsFrente,
      imagemCostas: mycotoxinClavicepsCostas,
      descricao: t('DashboardToxinas2'),
    },
    {
      nome: 'Fusarium',
      imagemFrente: mycotoxinFusariumFrente,
      imagemCostas: mycotoxinFusariumCostas,
      descricao: t('DashboardToxinas3'),
    },
    {
      nome: 'Penicillium',
      imagemFrente: mycotoxinPenicilliumFrente,
      imagemCostas: mycotoxinPenicilliumCostas,
      descricao: t('DashboardToxinas4'),
    },
  ]

  const [selecionado, setSelecionado] = useState('Aspergpillus')

  return (
    <Flex mb={4} mt={2} px={4} width={"full"} wrap={"wrap"} justifyContent={"center"}>
      <Text mb={2} fontWeight={700} fontSize={"16px"}>{t('DashboardTiposFungos')}</Text>

      <Flex width={"full"} wrap={"wrap"} justifyContent={"center"}>
        {insetos.map((i, index) => (
          <Flex direction={"column"} mb={2} width={"50%"} position={"relative"} key={`${index}`}>
            <Flex borderRadius={"50%"} width={"100%"} p={0.5} border={`${selecionado == i.nome ? `4px solid ${config.defaultColor}` : ''}`}>
              <img onClick={() => setSelecionado(i.nome)} style={{ cursor: 'pointer' }} height={"auto"} src={i.imagemFrente} />
            </Flex>

            <Text mx={"auto"} fontSize={"12px"}>{i.nome}</Text>
          </Flex>
        ))}
      </Flex>

      <Flex direction={"column"} textAlign={"center"} width={"full"}>
        <Img mx={"auto"} width={"70%"} src={insetos.find((i) => i.nome == selecionado)?.imagemCostas} />
        <Text>{insetos.find((i) => i.nome == selecionado)?.descricao}</Text>
      </Flex>
    </Flex>
  )
}