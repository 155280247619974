import { AvaliacaoInicialQuestao } from "../../sistema/types/avaliacao_inicial_questao";
import { getCurrentData } from "../../utils/data";
import { PreparacaoInicial } from "../types/preparacao_inicial";

export const novaPreparacaoInicial = ({
  questoesFormatadas = {
    sinaleiro: [],
    teto: [],
    corpo: [],
    base: [],
    predio: [],
  },
}: {
  questoesFormatadas: {
    sinaleiro: AvaliacaoInicialQuestao[];
    teto: AvaliacaoInicialQuestao[];
    corpo: AvaliacaoInicialQuestao[];
    base: AvaliacaoInicialQuestao[];
    predio: AvaliacaoInicialQuestao[];
  };
}): PreparacaoInicial => {
  return {
    data_hora: getCurrentData(),
    leitura_id: "",
    cliente_id: "",
    cliente_unidade_id: "",
    cliente_unidade_silo_id: "",
    observacoes: "",
    respostas: [],
    relatorio: "",
    questoesFormatadas: questoesFormatadas,
  };
};
