import { useDispatch } from "react-redux";
import { sistemaActions } from "../sistema/recucer";

export function useChangeNetworkStatus() {
  const dispatch = useDispatch();

  if (navigator.onLine) {
    console.log('online')
    dispatch(sistemaActions.setNetworkOnline(true))
  } else {
    console.log('offline')
    dispatch(sistemaActions.setNetworkOnline(false))
  }

  window.addEventListener("offline", function () {
    console.log('offline')
    dispatch(sistemaActions.setNetworkOnline(false))
  });

  window.addEventListener("online", function () {
    console.log('online')
    dispatch(sistemaActions.setNetworkOnline(true))
  });
}


