import { useIsAuth } from "../../hooks/useIsAuth"
import Layout from "../components/Layout";
import { ListHeader } from "../components/ListHeader";
import { DefaultButton } from "../components/DefaultButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import ResponsiveTable, { TableHeaders } from "../components/ResponsiveTable";
import { useState, useEffect } from 'react';
import { sistemaActions } from "../recucer";
import { ListInput } from "../components/ListInput";
import { Pagination } from "../components/Pagination";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";
import { ConfirmButton } from "../components/ConfirmButton";
import { FiTrash } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { Inseto } from "../types/inseto";

export const InsetosList = () => {
  useIsAuth();

  const insetos = useSelector((state: RootState) => state.sistema.insetos);
  const isLoading = useSelector((state: RootState) => !!state.sistema.isLoading);
  const success = useSelector((state: RootState) => !!state.sistema.success);
  const dispatch = useDispatch()

  const [page, setPage] = useState(1);
  const [termo, setTermo] = useState("");
  const eDesenvolvedor = useSelector((state: RootState) => (state.sistema.user as any)?.e_desenvolvedor == 1);
  useEffect(() => {
    dispatch(sistemaActions.insetosRequest({
      page,
      termo,
    }))
  }, [dispatch, page, termo])

  useEffect(() => {
    if (!success) {
      return;
    }
    dispatch(sistemaActions.insetosRequest({
      page,
      termo,
    }))
  }, [dispatch, success, page, termo])

  const { t } = useTranslation();

  const headers: TableHeaders<Inseto>[] = [
    {
      label: "Id",
      wrapped: true,
      render: (item) => item.id,
    },
    {
      label: t('ClientesListNome'),
      wrapped: true,
      render: (item) => item.nome,
    },
    {
      label: t('UsuarioListAtivo'),
      wrapped: true,
      render: (item: any) => item.ativo == 1 ? t('SistemaSim') : t('SistemaNão'),
    },
    {
      label: t('InsetosEditResistencia'),
      wrapped: true,
      render: (item: any) => `${t(`InsetosEditResistencia${item.resistencia.trim()}`)}`,
    },
    {
      label: t('InsetosEditTipo'),
      wrapped: true,
      render: (item: any) => item.tipo == 1 ? t('InsetosEditTipo1') : t('InsetosEditTipo2'),
    },
    {
      label: t('SistemaAções'),
      wrapped: true,
      render: (item: any, index: any) => <>{eDesenvolvedor && <ConfirmButton confirm={() => onDeleteItem(item.id)}><FiTrash /></ConfirmButton>}</>,
      notClicable: true,
    },
  ];

  const onDeleteItem = (id: string) => {
    dispatch(sistemaActions.deleteInsetosRequest(id));
  }

  const navigate = useNavigate();

  return (
    <Layout>
      <ListHeader label={t('InsetosListTitulo')}>
        <DefaultButton ml={4} to="/insetos/novo"> {t('SistemaNovo')} </DefaultButton>
      </ListHeader>

      <Loader isLoading={isLoading} />

      <ListInput label={t('ClientesListTermo')} onSubmit={setTermo} />

      {insetos && <ResponsiveTable onClick={(item: any) => navigate(`/insetos/${item.item.id}`)} headers={headers} data={insetos.data} />}

      {insetos && <Pagination info={insetos} paginate={setPage} />}
    </Layout>
  )
}