import { useIsAuth } from "../../hooks/useIsAuth"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { FiSend, FiTrash } from "react-icons/fi";
import { leituraActions } from "../reducer";
import ResponsiveTable, { TableHeaders } from "../../sistema/components/ResponsiveTable";
import Layout from "../../sistema/components/Layout";
import { ListHeader } from "../../sistema/components/ListHeader";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import Loader from "../../sistema/components/Loader";
import { ListInput } from "../../sistema/components/ListInput";
import { Pagination } from "../../sistema/components/Pagination";
import { ConfirmButton } from "../../sistema/components/ConfirmButton";
import { formatData } from "../../utils/data";
import { useTranslation } from "react-i18next";
import { AvaliacaoInicialStatus } from "../components/AvaliacaoInicialStatus";
import { AvaliacaoInicial } from "../types/avaliacao_inicial";
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import { mensagemErro } from "../../utils/toasts";
import { Button, Flex, Img, Link } from "@chakra-ui/react";
import { PreparacaoInicial } from "../types/preparacao_inicial";
import imagesUrl from "../../utils/imagesUrl";

const systemz = imagesUrl + "logos/systemz.png";

export const PreparacaoInicialList = () => {
  useIsAuth();
  const preparacoesIniciais = useSelector((state: RootState) => state.leituras.preparacoesIniciais);
  const preparacoesParaEnviar = useSelector((state: RootState) => state.leituras.preparacoesParaEnviar);
  const leituraDadosSelectsInputs = useSelector((state: RootState) => state.leituras.leituraDadosSelectsInputs);
  const isLoading = useSelector((state: RootState) => !!state.leituras.isLoading);
  const success = useSelector((state: RootState) => !!state.leituras.success);
  const dispatch = useDispatch()
  const eDesenvolvedor = useSelector((state: RootState) => (state.sistema.user as any)?.e_desenvolvedor == 1);
  const [page, setPage] = useState(1);
  const [termo, setTermo] = useState("");
  const networkOnline = useSelector((state: RootState) => state.sistema.networkOnline);

  const { t } = useTranslation()

  useEffect(() => {
    if (!networkOnline) {
      return;
    }
    dispatch(leituraActions.preparacoesIniciaisRequest({
      page,
      termo,
    }))
  }, [dispatch, page, termo])

  useEffect(() => {
    if (!success) {
      return;
    }
    if (!networkOnline) {
      return;
    }
    dispatch(leituraActions.preparacoesIniciaisRequest({
      page,
      termo,
    }))
  }, [dispatch, success, page, termo])

  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);

  const headers: TableHeaders<PreparacaoInicial>[] = [
    {
      label: "Id",
      wrapped: true,
      render: (item) => item.id,
    },
    {
      label: t('LeiturasListData'),
      wrapped: true,
      render: (item) => formatData(item.data_hora),
    },
    {
      label: t('LeiturasListCliente'),
      wrapped: false,
      render: (item) => leituraDadosSelectsInputs?.clientes?.find((i) => i.id == item.cliente_id as any)?.nome,
    },
    {
      label: t('DashboardUnidade'),
      wrapped: !isMobile,
      render: (item) => leituraDadosSelectsInputs?.clientes_unidades?.find((i) => i.id == item.cliente_unidade_id as any)?.nome_referencia,
    },
    {
      label: t('SistemaAções'),
      wrapped: true,
      render: (item, index: any) => <>{eDesenvolvedor && <ConfirmButton confirm={() => onDeleteItem(item.id as number)}><FiTrash /></ConfirmButton>}
        {item.relatorio ? <Link href={item.relatorio} target="_blank" rel="noopener noreferrer">
          <Button size={"sm"} ml={1} colorScheme={'green'}>
            {t('SistemaImprimirRelatorio')}
          </Button>
        </Link> :
          <Button size={"sm"} ml={1} colorScheme={'blackAlpha'}>
            {t('SistemaGerandoRelatorio')}
          </Button>}
      </>,
      notClicable: true,
    },
  ];

  const onDeleteItem = (id: number) => {
    dispatch(leituraActions.deletePreparacaoInicial(id));
  }

  const onDeleteItemLocal = (id: number) => {
    dispatch(leituraActions.deletePreparacaoInicialLocalRequest(id));
  }

  const navigate = useNavigate();

  const onSendAvaliacao = (tempId: string) => {
    if (!networkOnline) {
      mensagemErro(t('AvaliacaoInicialVoceEstaSemInternet'))
      return;
    }

    const avaliacao = preparacoesParaEnviar?.find((i) => i.tempId == tempId);

    if (!avaliacao) return;

    dispatch(leituraActions.savePreparacaoInicial(avaliacao));

  }

  const headersTemp: TableHeaders<AvaliacaoInicial>[] = [
    {
      label: "Id",
      wrapped: true,
      render: (item) => item.tempId,
    },
    {
      label: t('LeiturasListData'),
      wrapped: true,
      render: (item) => formatData(item.data_hora),
    },
    {
      label: t('LeiturasListCliente'),
      wrapped: false,
      render: (item) => leituraDadosSelectsInputs?.clientes?.find((i) => i.id == item.cliente_id as any)?.nome,
    },
    {
      label: t('DashboardUnidade'),
      wrapped: !isMobile,
      render: (item) => leituraDadosSelectsInputs?.clientes_unidades?.find((i) => i.id == item.cliente_unidade_id as any)?.nome_referencia,
    },
    {
      label: t('SistemaAções'),
      wrapped: true,
      render: (item: any, index: any) => <>
        {eDesenvolvedor && <ConfirmButton confirm={() => onDeleteItemLocal(item.tempId)}><FiTrash /></ConfirmButton>} &nbsp;
        <ConfirmButton colorSheme="blue" confirm={() => onSendAvaliacao(item.tempId)}><FiSend /></ConfirmButton>
      </>,
      notClicable: true,
    },
  ];

  return (
    <Layout showInternetStatus={true}>
      <ListHeader label={t('PreparacaoInicialTituloList')}>
        <DefaultButton ml={4} to="/preparacoes-iniciais/novo"> {t('SistemaNova')} </DefaultButton>
      </ListHeader>
      <Flex width={"full"} position={"relative"}>
        {!isMobile && <Img width={"100px"} height={"100px"} zIndex={999} src={systemz} position={"absolute"} top={"30px"} left={"20px"} />}
        {isMobile && <Img mx={"auto"} my={2} width={"100px"} height={"100px"} src={systemz} />}
      </Flex>
      <AvaliacaoInicialStatus buscaQuestoes={true} />

      <Loader isLoading={isLoading} />

      <ListHeaderSecondary label={t('AvaliacaoInicialASeremEnviadas')} />

      {preparacoesParaEnviar && <ResponsiveTable onClick={(item: any) => navigate(`/preparacoes-iniciais/${item.item.tempId}`)} headers={headersTemp} data={preparacoesParaEnviar} />}

      <ListHeaderSecondary label={t('AvaliacaoInicialEnviadas')} />

      <ListInput label={t('AvaliacaoInicialTermo')} onSubmit={setTermo} />

      {preparacoesIniciais && <ResponsiveTable onClick={(item: any) => navigate(`/preparacoes-iniciais/${item.item.id}`)} headers={headers} data={preparacoesIniciais.data} />}

      {preparacoesIniciais && <Pagination info={preparacoesIniciais} paginate={setPage} />}
    </Layout>
  )
}