import { Button, Flex, FormControl, FormErrorMessage, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spacer, Spinner, useDisclosure } from "@chakra-ui/react";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import { FiCamera, FiCheck, FiX } from "react-icons/fi";
import { useState } from 'react';

interface IProps {
  label: string;
  name: string;
  mb?: string;
}

interface Event<T = EventTarget> {
  target: T;
}

const InputImage = ({ label, mb, ...props }: IProps) => {
  const [, { error, touched, value }, { setValue }] = useField(props);

  const [isLoadingLocal, setIsLoadingLocal] = useState<boolean>(false);

  const randomId = Math.floor(Math.random() * 100).toString();

  const open = () => {
    document.getElementById(`image-field${randomId}`)?.click();
  }

  const ajustImage = (event: Event<HTMLInputElement>) => {
    let fileList = event.target.files;
    if (!fileList?.length) {
      return;
    }
    setIsLoadingLocal(true);
    let file = fileList[0];

    let maxWidth = 900;
    let maxHeight = 900;

    let reader = new FileReader();
    reader.onloadend = () => {
      const imagePreview = reader.result;
      let wrap = document.createElement("div");
      let resizeArea = document.createElement("canvas");
      wrap.appendChild(resizeArea);
      wrap.id = "wrap-imageupload-resize-area";
      wrap.style.position = "relative";
      wrap.style.overflow = "hidden";
      wrap.style.width = "0";
      wrap.style.height = "0";
      resizeArea.id = "imageupload-resize-area";
      resizeArea.style.position = "absolute";
      document.body.appendChild(wrap);

      let oImg = document.createElement("img");
      oImg.id = "img-resize";
      oImg.src = imagePreview as string;

      oImg.onload = () => {
        let resizeMaxWidth = maxWidth * 1.6;
        let resizeMaxHeight = maxHeight * 1.6;

        let height = oImg.height;
        let width = oImg.width;

        if (width > resizeMaxWidth) {
          height = Math.round((height * resizeMaxWidth) / width);
          width = resizeMaxWidth;
        }

        if (height > resizeMaxHeight) {
          width = Math.round((width * resizeMaxHeight) / height);
          height = resizeMaxHeight;
        }

        resizeArea.width = width;
        resizeArea.height = height;

        const ctx = resizeArea.getContext("2d");
        ctx?.drawImage(oImg, 0, 0, width, height);

        setValue(resizeArea.toDataURL("image/png", 0.8));
        setIsLoadingLocal(false);
      };
    };
    reader.readAsDataURL(file);
  };

  const { isOpen, onOpen, onClose } = useDisclosure()

  // capture="environment" accept="image/*" 

  const { t } = useTranslation();

  return (
    <Flex>
      <FormControl p={1} mb={mb ? mb : 0} isInvalid={touched && error ? true : false}>
        <Button size="sm" mr={1} borderWidth={error && '2px'} borderColor="red" colorScheme="teal" onClick={open}>{isLoadingLocal ? <Spinner /> : <FiCamera />} &nbsp; {label}</Button>
        {value && <Button mr={1} size="sm" colorScheme="teal" onClick={onOpen}><FiCheck /> &nbsp; {t('InputImageVer')}</Button>}
        {value && <Button size="sm" colorScheme="red" onClick={() => setValue('')}><FiX /> </Button>}
        <input type="file" onChange={ajustImage} style={{ display: 'none' }} id={`image-field${randomId}`} />
        {error && <FormErrorMessage>{error}</FormErrorMessage>}
      </FormControl>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('InputImageVisualizacao')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Image src={value} alt='imagem' />
            <Spacer marginY={5} />
            <Button colorScheme="teal" onClick={onClose}>{t('DashboardFechar')}</Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  )
}

export default InputImage;