import { useSelector } from "react-redux"
import { RootState } from "../../app/mainReducer"
import { useTranslation } from 'react-i18next'

export const DashboardNomeProduto = () => {
  const dashboard = useSelector((state: RootState) => state.leituras.dashboard_leituras)
  const { t } = useTranslation()

  return (
    <>
      {t('Dashboard' + (dashboard?.last_leitura?.produto ?? '').trim())}
    </>
  )
}