import { useIsAuth } from "../../hooks/useIsAuth"
import Layout from "../components/Layout";
import { ListHeader } from "../components/ListHeader";
import { DefaultButton } from "../components/DefaultButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { Cliente } from "../types/cliente";
import ResponsiveTable, { TableHeaders } from "../components/ResponsiveTable";
import { useState, useEffect } from 'react';
import { sistemaActions } from "../recucer";
import { ListInput } from "../components/ListInput";
import { Pagination } from "../components/Pagination";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";
import { ConfirmButton } from "../components/ConfirmButton";
import { FiTrash } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { Badge } from "@chakra-ui/react";
import { formatData } from "../../utils/data";

export const ClienteList = () => {
  useIsAuth();

  const clientes = useSelector((state: RootState) => state.sistema.clientes);
  const isLoading = useSelector((state: RootState) => !!state.sistema.isLoading);
  const success = useSelector((state: RootState) => !!state.sistema.success);
  const dispatch = useDispatch()

  const [page, setPage] = useState(1);
  const [termo, setTermo] = useState("");
  const eDesenvolvedor = useSelector((state: RootState) => (state.sistema.user as any)?.e_desenvolvedor == 1);
  useEffect(() => {
    dispatch(sistemaActions.clientesRequest({
      page,
      termo,
    }))
  }, [dispatch, page, termo])

  useEffect(() => {
    if (!success) {
      return;
    }
    dispatch(sistemaActions.clientesRequest({
      page,
      termo,
    }))
  }, [dispatch, success, page, termo])

  const { t } = useTranslation();

  const headers: TableHeaders<Cliente>[] = [
    {
      label: "Id",
      wrapped: true,
      render: (item) => item.id,
    },
    {
      label: t('ClientesListNome'),
      wrapped: false,
      render: (item) => <>
        {item.nome} <Badge mb={0.5} colorScheme={(item.ativo as any) == 1 ? 'blue' : 'red'}>
          {(item.ativo as any) == 1 ? t('DashboardAtivo') : t('DashboardInativo')}
        </Badge>
         <br />
        <Badge colorScheme="cyan">
          Criado em {formatData(item.created_at as string, 'dd/MM/yyyy HH:mm')}
        </Badge>
        <Badge colorScheme="purple">
          Atualizado em {formatData(item.updated_at as string, 'dd/MM/yyyy HH:mm')}
        </Badge>
      </>,
    },
    {
      label: '',
      wrapped: false,
      render: (item) => <div className="w-full flex gap-1">
        <Badge colorScheme="purple">
          {t('ClienteEditInputTitulo')} ({(item.unidades ?? []).length})
        </Badge>
        <Badge colorScheme="linkedin">
          {t('ClienteUnidadeArmazenamentos')} ({item.unidades.reduce((anterior, atual) => {
            return anterior + atual.silos.length;
          }, 0)})
        </Badge>
      </div>,
    },
    {
      label: t('SistemaAções'),
      wrapped: true,
      render: (item: any, index: any) => <>{eDesenvolvedor && <ConfirmButton confirm={() => onDeleteItem(item.id)}><FiTrash /></ConfirmButton>}</>,
      notClicable: true,
    },
  ];

  const onDeleteItem = (id: string) => {
    dispatch(sistemaActions.deleteClientesRequest(id));
  }

  const navigate = useNavigate();

  return (
    <Layout>
      <ListHeader label={t('ClientesListTitulo')}>
        <DefaultButton ml={4} to="/clientes/novo"> {t('SistemaNovo')} </DefaultButton>
      </ListHeader>

      <Loader isLoading={isLoading} />

      <ListInput label={t('ClientesListTermo')} onSubmit={setTermo} />

      {clientes && <ResponsiveTable onClick={(item: any) => navigate(`/clientes/${item.item.id}`)} headers={headers} data={clientes.data} />}

      {clientes && <Pagination info={clientes} paginate={setPage} />}
    </Layout>
  )
}