import { getCurrentData } from "../../utils/data";
import { AvaliacaoInicial } from "../types/avaliacao_inicial";
import { getUser, getUsuarios } from "./leitura";

export const novaAvaliacaoInicial = (): AvaliacaoInicial => {
  return {
    data_hora: getCurrentData(),
    leitura_id: "",
    cliente_id: "",
    cliente_unidade_id: "",
    cliente_unidade_silo_id: "",
    ordem_servico: "",
    produto: "",
    volume: 0,
    hermeticidade_critico_calcadas: 2.5,
    hermeticidade_critico_ventiladores: 1.75,
    hermeticidade_critico_anel_inferior: 2.5,
    hermeticidade_critico_anel_superior: 1.75,
    hermeticidade_critico_montante: 1.25,
    hermeticidade_critico_chapa: 1.25,
    hermeticidade_critico_parafuso: 0.75,
    hermeticidade_critico_tunel: 1.75,
    hermeticidade_critico_bicas: 1.5,
    hermeticidade_critico_perda_maxima: 15,
    hermeticidade_regular_calcadas: 1.75,
    hermeticidade_regular_ventiladores: 1.25,
    hermeticidade_regular_anel_inferior: 1.75,
    hermeticidade_regular_anel_superior: 1.25,
    hermeticidade_regular_montante: 0.75,
    hermeticidade_regular_chapa: 0.75,
    hermeticidade_regular_parafuso: 0.5,
    hermeticidade_regular_tunel: 1.25,
    hermeticidade_regular_bicas: 0.75,
    hermeticidade_regular_perda_maxima: 10,
    hermeticidade_bom_calcadas: 1,
    hermeticidade_bom_ventiladores: 0.75,
    hermeticidade_bom_anel_inferior: 1,
    hermeticidade_bom_anel_superior: 0.75,
    hermeticidade_bom_montante: 0.25,
    hermeticidade_bom_chapa: 0.25,
    hermeticidade_bom_parafuso: 0.25,
    hermeticidade_bom_tunel: 0.75,
    hermeticidade_bom_bicas: 0,
    hermeticidade_bom_perda_maxima: 5,
    hermeticidade_otimo_calcadas: 0.25,
    hermeticidade_otimo_ventiladores: 0.25,
    hermeticidade_otimo_anel_inferior: 0.25,
    hermeticidade_otimo_anel_superior: 0,
    hermeticidade_otimo_montante: 0,
    hermeticidade_otimo_chapa: 0,
    hermeticidade_otimo_parafuso: 0,
    hermeticidade_otimo_tunel: 0,
    hermeticidade_otimo_bicas: 0,
    hermeticidade_otimo_perda_maxima: 0.75,
    porcentagem_indice_hermticidade: 0.75,
    avaliacao_hermeticidade_calcadas: 0.25,
    avaliacao_hermeticidade_ventiladores: 0.25,
    avaliacao_hermeticidade_anel_inferior: 0.25,
    avaliacao_hermeticidade_anel_superior: '0.00',
    avaliacao_hermeticidade_montante: '0.00',
    avaliacao_hermeticidade_chapa: '0.00',
    avaliacao_hermeticidade_parafuso: '0.00',
    avaliacao_hermeticidade_tunel: '0.00',
    avaliacao_hermeticidade_bicas: '0.00',
    silo_temperatura_1: 0,
    silo_temperatura_2: 0,
    silo_temperatura_3: 0,
    silo_temperatura_4: 0,
    silo_temperatura_5: 0,
    silo_temperatura_6: 0,
    silo_temperatura_7: 0,
    silo_temperatura_8: 0,
    silo_temperatura_9: 0,
    silo_umidade_relativa: 0,
    pontuacao_insetos: 0,
    pontuacao_temperatura: 0,
    pontuacao_impurezas: 0,
    observacoes: "",
    massa_graos_compactacao: 0,
    massa_graos_valculas: 0,
    massa_graos_nivelamento: 0,
    massa_graos_compactacao_inapto: 0,
    massa_graos_valculas_inapto: 0,
    massa_graos_nivelamento_inapto: 0,
    matriz_indice_risco: 0,
    relatorio: "",
    imagens: [],
    insetos_final: [],
    insetos: [],
    responsaveis: [
      {
        avaliacao_inicial_id: "",
        user_id: getUser()?.id as any,
        usuario: getUsuarios()?.administradores.find(
          (i: any) => i.id == getUser()?.id
        ),
      },
    ],
    assinaturas: [],
  };
};
