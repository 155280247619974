import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/mainReducer';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import InputLocalSearch from '../../sistema/components/InputLocalSearch';
import { Form, Formik } from 'formik';
import { bodyCenterActions } from '../reducer';
import { Cliente } from '../../sistema/types/cliente';
import InputMultiSelect from '../../sistema/components/InpurMultiSelect';
import InputLocalMultiSearch from '../../sistema/components/InputLocalMultiSearch';

export const SelectClient = () => {
  const isLoading = useSelector((state: RootState) => state.bodyCenter?.isLoading);
  const selectedClient = useSelector((state: RootState) => state.bodyCenter?.dashboard?.selectedClient);
  const selectedUnits = useSelector((state: RootState) => state.bodyCenter?.dashboard?.selectedUnits);
  const clients = useSelector((state: RootState) => state.bodyCenter?.dashboard?.clients);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const dispatch = useDispatch();

  if (isLoading) {
    return <></>
  }

  if ((selectedUnits ?? []).length > 0) {
    return <></>
  }

  return (
    <Modal isOpen={true} onClose={() => { }} isCentered size="xl">
      <ModalOverlay
        bg="blackAlpha.300"
        backdropFilter="blur(10px)"
      />
      <ModalContent
        p={6}
        borderRadius="xl"
        boxShadow="xl"
      >
        <ModalHeader fontSize="2xl" textAlign="center">
          {!selectedClient ? t('BodyCenterSelectClientTitle') : t('BodyCenterSelectClientUnitsSearchPlaceholder')}
        </ModalHeader>

        <ModalBody className='w-full'>
          {!selectedClient ? (
            <Formik
              initialValues={{
                client: '',
              }}
              enableReinitialize
              onSubmit={(values, { setErrors }) => {
                if (!values.client) {
                  setErrors({ client: t('BodyCenterSelectClientRequired') });
                  return;
                }

                dispatch(bodyCenterActions.bodyCenterDashboardSelectClient((clients ?? []).find(c => c.id == values.client as any) as Cliente));
              }}
            >
              <Form>
                <VStack spacing={6} className='w-full'>
                  <Text color="gray.600" textAlign="center">
                    {t('BodyCenterSelectClientDescription')}
                  </Text>

                  <div className='w-full'>

                    <InputLocalSearch
                      className='w-full'
                      name="client"
                      label={''}
                      options={(clients ?? []).map((client) => ({
                        label: `${client.nome} - ${t('ClienteEditInputTitulo')} (${(client.unidades ?? []).length}) - ${t('BodyCenterSelectClientTotalVisits')} (${(client.unidades ?? []).reduce((acc, curr) => acc + (curr.bodyCenterVisits?.length ?? 0), 0)})`,
                        value: `${client.id}`,
                      }))}
                    />

                  </div>
                </VStack>

                <div className='w-full flex justify-between mt-8'>
                  <Button type='button' onClick={() => navigate('/')} variant="ghost">
                    {t('BodyCenterSelectClientBackToDashboard')}
                  </Button>
                  <Button type='submit' colorScheme="teal">
                    {t('BodyCenterSelectClientConfirm')}
                  </Button>
                </div>
              </Form>
            </Formik>) : (
            <Formik
              initialValues={{
                units: (selectedClient?.unidades ?? []).map(u => `${u.id}`),
              }}
              enableReinitialize
              onSubmit={(values, { setErrors }) => {
                if (!values.units.length) {
                  setErrors({ units: t('BodyCenterSelectClientUnitsRequired') });
                  return;
                }

                dispatch(bodyCenterActions.bodyCenterDashboardSelectClientUnits(values.units as any as number[]));
              }}
            >
              <Form>
                <VStack spacing={6} className='w-full'>
                  <Text color="gray.600" textAlign="center">
                    {t('BodyCenterSelectClientUnitsSearch')}
                  </Text>

                  <div className='w-full'>
                    <InputLocalMultiSearch
                      className='w-full'
                      name="units"
                      label={''}
                      options={(selectedClient?.unidades ?? []).map((unit) => ({
                        label: `${unit.nome_referencia} - ${(unit.cidade ?? '')} - ${t('BodyCenterSelectClientTotalVisits')} (${(unit.bodyCenterVisits ?? [])?.length})`,
                        value: `${unit.id}`,
                      }))}
                    />

                  </div>
                </VStack>

                <div className='w-full flex justify-between mt-8'>
                  <Button type='button' onClick={() => {
                    dispatch(bodyCenterActions.bodyCenterDashboardSelectClient(undefined));
                  }} variant="ghost">
                    {t('SistemaVoltar')}
                  </Button>
                  <Button type='submit' colorScheme="teal">
                    {t('BodyCenterSelectClientConfirm')}
                  </Button>
                </div>
              </Form>
            </Formik>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>

  );
};