import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { mensagemErro, mensagemSucesso } from "../utils/toasts";
import { Leitura } from "./types/leitura";
import { ApiPaginationRes } from "../sistema/types/apiPaginationRes";
import { ListPayload } from "../sistema/types/listPayload";
import { LeituraDadosSelectsInputs } from "./types/leitura_dados_selects_inputs";
import { DashboardLeitura } from "./types/dashboard_leitura";
import { DashboardFiltro } from "./types/dashboard_filtro";
import { novoDashboardFiltro } from "./data/dashboard_filtro";
import { EquipamentoCiclo } from "../sistema/types/equipamento_ciclo";
import { LeituraAmostra } from "./types/leitura_amostra";
import { LeituraAplicacao } from "./types/leitura_aplicacao";
import { LeituraAvaliacaoInicial } from "../sistema/types/leitura_avaliacao_inicial";
import { LeituraInjecao } from "./types/leitura_injecao";
import { getCurrentData } from "../utils/data";
import { AvaliacaoInicial } from "./types/avaliacao_inicial";
import { generateUuid } from "../utils/utils";
import { AvaliacaoInicialQuestao } from "../sistema/types/avaliacao_inicial_questao";
import { PreparacaoInicial } from "./types/preparacao_inicial";

export interface IAvaliacaoInicialStatus {
  last_update: string;
}

export interface IDadosPreparacaoInicial {
  sinaleiro: AvaliacaoInicialQuestao[];
  teto: AvaliacaoInicialQuestao[];
  corpo: AvaliacaoInicialQuestao[];
  base: AvaliacaoInicialQuestao[];
  predio: AvaliacaoInicialQuestao[];
}

type TInitialState = {
  error?: string;
  success?: string;
  isLoading?: boolean;
  isLoadingInjecao?: boolean;
  leituras?: ApiPaginationRes<Leitura[]>;
  leitura?: Leitura | null;
  leituraDadosSelectsInputs?: LeituraDadosSelectsInputs | null;
  dashboard_leituras?: DashboardLeitura | null;
  filtros_atuais?: DashboardFiltro;
  equipamento_ciclo_atual?: EquipamentoCiclo;
  injecoes?: LeituraInjecao[];
  avaliacaoInicialStatus?: IAvaliacaoInicialStatus;
  isLoadigAvaliacaoStatus?: boolean;
  avaliacoesIniciais?: ApiPaginationRes<AvaliacaoInicial[]>;
  avaliacaoInicial?: AvaliacaoInicial;
  avaliacoesParaEnviar?: AvaliacaoInicial[];
  preparacoesParaEnviar?: PreparacaoInicial[];
  imagemIdsParaApagar?: string[];
  dadosPreparacaoInicial?: IDadosPreparacaoInicial;
  isLoadingPreparacaoInicial?: boolean;
  preparacoesIniciais?: ApiPaginationRes<PreparacaoInicial[]>;
  preparacaoInicial?: PreparacaoInicial;
};

const initialState: TInitialState = {};

const reducer = createSlice({
  name: "leituras",
  initialState,
  reducers: {
    error(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
      state.success = "";
      mensagemErro(payload);
    },
    success(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = "";
      state.success = payload;
      mensagemSucesso(payload);
    },
    leiturasRequest(state, _: PayloadAction<ListPayload>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    leiturasRequestSuccess(
      state,
      { payload }: PayloadAction<ApiPaginationRes<Leitura[]>>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.leituras = payload;
    },
    leituraRequest(state, _: PayloadAction<string>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
      state.leitura = null;
    },
    leituraRequestSuccess(state, { payload }: PayloadAction<Leitura>) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.leitura = payload;
    },
    saveLeituraRequest(state, _: PayloadAction<Leitura>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    saveLeituraRequestSuccess(state, { payload }: PayloadAction<Leitura>) {
      state.isLoading = false;
      state.error = "";
      state.success = "Leitura salva com sucesso.";
      state.leitura = payload;
      mensagemSucesso("Leitura salva com sucesso.");
    },
    deleteLeituraRequest(state, { payload }: PayloadAction<string | number>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    deleteLeituraRequestSuccess(state) {
      state.isLoading = false;
      state.error = "";
      state.success = "Leitura apagada com sucesso.";
      mensagemSucesso("Leitura apagada com sucesso.");
    },
    preparacaoInicialDados(state) {
      state.isLoading = true;
      state.isLoadingPreparacaoInicial = true;
      state.error = "";
      state.success = "";
    },
    preparacaoInicialDadosSuccess(
      state,
      { payload }: PayloadAction<IDadosPreparacaoInicial>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.dadosPreparacaoInicial = payload;
      state.isLoadingPreparacaoInicial = false;
    },
    leituraDadosSelectsInputs(state) {
      state.isLoading = true;
      state.isLoadigAvaliacaoStatus = true;
      state.error = "";
      state.success = "";
    },
    leituraDadosSelectsInputsSuccess(
      state,
      { payload }: PayloadAction<LeituraDadosSelectsInputs>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.leituraDadosSelectsInputs = payload;
      state.isLoadigAvaliacaoStatus = false;
      if (!state.avaliacaoInicialStatus) {
        state.avaliacaoInicialStatus = {
          last_update: getCurrentData(),
        };
      } else {
        state.avaliacaoInicialStatus.last_update = getCurrentData();
      }
    },
    dashboardLeiturasRequest(
      state,
      { payload }: PayloadAction<DashboardFiltro | null>
    ) {
      state.isLoading = true;
      state.isLoadingInjecao = false;
      state.error = "";
      state.success = "";
      if (payload) {
        state.filtros_atuais = { ...state.filtros_atuais, ...payload };
      } else if (!state.filtros_atuais) {
        state.filtros_atuais = novoDashboardFiltro();
      }
    },
    dashboardLeiturasRequestSuccess(
      state,
      { payload }: PayloadAction<DashboardLeitura>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.dashboard_leituras = payload;
    },
    equipamentoCicleRequest(state, _: PayloadAction<string>) {},
    equipamentoCicleRequestSuccess(
      state,
      { payload }: PayloadAction<EquipamentoCiclo>
    ) {
      state.equipamento_ciclo_atual = payload;
    },
    leiturasAmostrasSaveRequest(state, _: PayloadAction<LeituraAmostra>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    leiturasAmostrasSaveRequestSuccess(
      state,
      { payload }: PayloadAction<LeituraAmostra>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "Amostra salva com sucesso.";
      if (!state.dashboard_leituras?.last_leitura?.amostras) return;
      const existente =
        state.dashboard_leituras.last_leitura.amostras.findIndex(
          (i) => i.id == payload.id
        );

      if (existente >= 0) {
        state.dashboard_leituras.last_leitura.amostras[existente] = {
          ...state.dashboard_leituras.last_leitura.amostras[existente],
          ...payload,
        };
      } else {
        state.dashboard_leituras.last_leitura.amostras.push(payload);
      }
      mensagemSucesso("Amostra salva com sucesso.");
    },
    startHermeticidadeRequest(state, { payload }: PayloadAction<string>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    startHermeticidadeRequestSuccess(
      state,
      { payload }: PayloadAction<string>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      if (!state.dashboard_leituras?.last_leitura) return;
      state.dashboard_leituras.last_leitura.data_inicio_hermeticidade = payload;
    },
    leiturasAplicacoesSaveRequest(state, _: PayloadAction<LeituraAplicacao>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    leiturasAplicacoesSaveRequestSuccess(
      state,
      { payload }: PayloadAction<LeituraAplicacao>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "Registro salvo com sucesso.";
      if (!state.dashboard_leituras?.last_leitura?.aplicacoes) return;
      const existente =
        state.dashboard_leituras.last_leitura.aplicacoes.findIndex(
          (i) => i.id == payload.id
        );

      if (existente >= 0) {
        state.dashboard_leituras.last_leitura.aplicacoes[existente] = {
          ...state.dashboard_leituras.last_leitura.aplicacoes[existente],
          ...payload,
        };
      } else {
        state.dashboard_leituras.last_leitura.aplicacoes.push(payload);
      }
      mensagemSucesso(`${payload?.tipo} salva com sucesso.`);
    },
    saveAvaliacaoInicial(state, _: PayloadAction<LeituraAvaliacaoInicial[]>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    saveAvaliacaoInicialSuccess(
      state,
      { payload }: PayloadAction<LeituraAvaliacaoInicial[]>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";

      if (!state.dashboard_leituras?.avaliacao_inicial_questoes_respondidas)
        return;
      payload.forEach((item) => {
        if (!state.dashboard_leituras?.avaliacao_inicial_questoes_respondidas)
          return;
        const indexExistente =
          state.dashboard_leituras.avaliacao_inicial_questoes_respondidas.findIndex(
            (i) => i.id == item.id
          );
        if (indexExistente < 0) {
          state.dashboard_leituras.avaliacao_inicial_questoes_respondidas.push(
            item
          );
        } else {
          state.dashboard_leituras.avaliacao_inicial_questoes_respondidas[
            indexExistente
          ] = item;
        }
      });
    },
    leiturasInjecaoRequestSuccess(
      state,
      { payload }: PayloadAction<LeituraInjecao[]>
    ) {
      state.error = "";
      state.success = "";
      state.injecoes = payload;
    },
    saveLeituraInjecaoRequest(state, _: PayloadAction<LeituraInjecao>) {
      state.isLoadingInjecao = true;
      state.error = "";
      state.success = "";
    },
    saveLeituraInjecaoRequestSuccess(
      state,
      { payload }: PayloadAction<LeituraInjecao>
    ) {
      state.isLoadingInjecao = false;
      state.error = "";
      state.success = "";

      if (!state.injecoes) return;

      const injecaoIndex = state.injecoes.findIndex((i) => i.id == payload.id);

      if (injecaoIndex < 0) {
        state.injecoes.push(payload);
        return;
      }
      state.injecoes[injecaoIndex] = payload;
    },
    avaliacoesIniciaisRequest(state, _: PayloadAction<ListPayload>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    avaliacoesIniciaisRequestSuccess(
      state,
      { payload }: PayloadAction<ApiPaginationRes<AvaliacaoInicial[]>>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.avaliacoesIniciais = payload;
    },
    avaliacaoInicialRequest(state, _: PayloadAction<string>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    avaliacaoInicialRequestSuccess(
      state,
      { payload }: PayloadAction<AvaliacaoInicial>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.avaliacaoInicial = payload;
    },
    saveAvaliacaoInicialLocalRequest(
      state,
      { payload }: PayloadAction<AvaliacaoInicial>
    ) {
      if (!state.avaliacoesParaEnviar) state.avaliacoesParaEnviar = [];

      if (payload.tempId) {
        const index = state.avaliacoesParaEnviar.findIndex(
          (i) => i.tempId == payload.tempId
        );

        if (index < 0) {
          console.warn(
            `Avaliação com tempId não encontrado: ${payload.tempId}, index: ${index}`
          );
          return;
        }

        state.avaliacoesParaEnviar[index] = payload;
        state.avaliacaoInicial = payload;
        if (payload.mostra_mensagem_sucesso) {
          mensagemSucesso("Salvo localmente com sucesso.");
        }
        return;
      }

      const novoItem = {
        ...payload,
        tempId: generateUuid(),
      };

      state.avaliacoesParaEnviar.push(novoItem);
      state.avaliacaoInicial = novoItem;
      if (payload.mostra_mensagem_sucesso) {
        mensagemSucesso("Salvo localmente com sucesso.");
      }
    },
    saveAvaliacaoInicialRequest(
      state,
      { payload }: PayloadAction<AvaliacaoInicial>
    ) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    saveAvaliacaoInicialRequestSuccess(
      state,
      {
        payload,
      }: PayloadAction<{
        avaliacaoNova: AvaliacaoInicial;
        avaliacaoAntiga: AvaliacaoInicial;
      }>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "Salvo com sucesso.";
      state.avaliacaoInicial = payload.avaliacaoNova;
      if (payload.avaliacaoAntiga?.tempId) {
        const indexItem = (state.avaliacoesParaEnviar ?? []).findIndex(
          (i) => i.tempId == payload.avaliacaoAntiga.tempId
        );

        if (indexItem >= 0) {
          state.avaliacoesParaEnviar?.splice(indexItem, 1);
          if (state.avaliacoesIniciais) {
            state.avaliacoesIniciais.data.push(payload.avaliacaoNova);
          }
        }
      }
      const imagensId: string[] = [];

      payload.avaliacaoAntiga.imagens?.forEach((i) => {
        if (i.imagemId) {
          imagensId.push(i.imagemId as string);
        }
      });
      payload.avaliacaoAntiga.assinaturas?.forEach((i) => {
        if (i.assinaturaId) {
          imagensId.push(i.assinaturaId);
        }
      });
      payload.avaliacaoAntiga.insetos?.forEach((i) => {
        if (i.imagemId) {
          imagensId.push(i.imagemId);
        }
      });

      if (!state.imagemIdsParaApagar) state.imagemIdsParaApagar = [];

      console.log("saveAvaliacaoInicialRequestSuccess", imagensId);

      imagensId.forEach((i) => state.imagemIdsParaApagar?.push(i));

      mensagemSucesso("Salvo com sucesso.");
    },
    removeImagemLocalmente(state, { payload }: PayloadAction<string>) {
      if (!state.imagemIdsParaApagar) return;

      const indexeEncontrado = state.imagemIdsParaApagar.findIndex(
        (u) => u == payload
      );

      if (indexeEncontrado < 0) return;

      state.imagemIdsParaApagar.splice(indexeEncontrado, 1);

      console.log("removeImagemLocalmente", indexeEncontrado);
    },
    deleteAvaliacaoInicialRequest(
      state,
      { payload }: PayloadAction<string | number>
    ) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    deleteAvaliacaoInicialLocalRequest(
      state,
      { payload }: PayloadAction<string | number>
    ) {
      state.error = "";
      state.success = "";
      state.isLoading = false;
      const index = (state.avaliacoesParaEnviar ?? []).findIndex(
        (i) => i.tempId == payload && !i.id
      );

      if (index < 0) return;

      state.avaliacoesParaEnviar?.splice(index, 1);
    },
    deleteAvaliacaoInicialRequestSuccess(state) {
      state.isLoading = false;
      state.error = "";
      state.success = "Deletado.";
      mensagemSucesso("Deletado com sucesso.");
    },

    preparacaoInicialRequest(state, _: PayloadAction<string>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    preparacaoInicialRequestSuccess(
      state,
      { payload }: PayloadAction<PreparacaoInicial>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.preparacaoInicial = payload;
    },
    savePreparacaoInicialLocalRequest(
      state,
      { payload }: PayloadAction<PreparacaoInicial>
    ) {
      if (!state.preparacoesParaEnviar) state.preparacoesParaEnviar = [];

      if (payload.tempId) {
        const index = state.preparacoesParaEnviar.findIndex(
          (i) => i.tempId == payload.tempId
        );

        if (index < 0) {
          console.warn(
            `Avaliação com tempId não encontrado: ${payload.tempId}, index: ${index}`
          );
          return;
        }

        state.preparacoesParaEnviar[index] = payload;
        state.preparacaoInicial = payload;
        mensagemSucesso("Salvo localmente com sucesso.");
        return;
      }

      const novoItem = {
        ...payload,
        tempId: generateUuid(),
      };

      state.preparacoesParaEnviar.push(novoItem);
      state.preparacaoInicial = novoItem;
      mensagemSucesso("Salvo localmente com sucesso.");
    },
    preparacoesIniciaisRequest(state, _: PayloadAction<ListPayload>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    preparacoesIniciaisRequestSuccess(
      state,
      { payload }: PayloadAction<ApiPaginationRes<PreparacaoInicial[]>>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.preparacoesIniciais = payload;
    },
    savePreparacaoInicial(state, _: PayloadAction<PreparacaoInicial>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    savePreparacaoInicialSuccess(
      state,
      {
        payload,
      }: PayloadAction<{
        avaliacaoNova: PreparacaoInicial;
        avaliacaoAntiga: PreparacaoInicial;
      }>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "Salvo com sucesso.";
      state.preparacaoInicial = payload.avaliacaoNova;
      if (payload.avaliacaoAntiga?.tempId) {
        const indexItem = (state.preparacoesParaEnviar ?? []).findIndex(
          (i) => i.tempId == payload.avaliacaoAntiga.tempId
        );

        if (indexItem >= 0) {
          state.preparacoesParaEnviar?.splice(indexItem, 1);
          if (state.preparacoesIniciais) {
            state.preparacoesIniciais.data.push(payload.avaliacaoNova);
          }
        }
      }
      mensagemSucesso("Salvo com sucesso.");
    },
    deletePreparacaoInicialLocalRequest(
      state,
      { payload }: PayloadAction<string | number>
    ) {
      state.error = "";
      state.success = "";
      const index = (state.preparacoesParaEnviar ?? []).findIndex(
        (i) => i.tempId == payload && !i.id
      );

      if (index < 0) return;

      state.preparacoesParaEnviar?.splice(index, 1);
    },
    deletePreparacaoInicial(state, { payload }: PayloadAction<number>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    deletePreparacaoInicialSuccess(state, { payload }: PayloadAction<string>) {
      state.error = "";
      state.success = "Removido com sucesso";
      state.isLoading = false;
      const index = (state.preparacoesParaEnviar ?? []).findIndex(
        (i) => i.tempId == payload && !i.id
      );

      if (index < 0) return;

      state.preparacoesParaEnviar?.splice(index, 1);
    },
  },
});

export const leituraActions = reducer.actions;

export default reducer;
