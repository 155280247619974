import React, { useEffect, useState } from "react";
import { Stage, Layer, Image, Transformer, Circle, Text, Group } from "react-konva";
import useImage from "use-image";
import { Button, Input, useDisclosure } from "@chakra-ui/react";
import { FiPlus } from "react-icons/fi";
import Konva from "konva";
import config from "../../../config";
import { useTranslation } from "react-i18next";
import { mensagemErro } from "../../utils/toasts";

const URLImage = ({
    image,
    shapeProps,
    unSelectShape,
    isSelected,
    onSelect,
    onChange,
    stageScale,
    onDelete,
    isStatic,
}) => {
    const shapeRef = React.useRef();
    const trRef = React.useRef();
    const deleteButton = React.useRef();
    const [img] = useImage(image.src);
    const [radius, setRadius] = React.useState(20);

    React.useEffect(() => {
        if (!image.src && (image?.status ?? '').includes('alert')) {
            const multiplier = image.status === 'alert1' ? 23 : image.status === 'alert2' ? 28 : 33;
            const anim = new Konva.Animation((frame) => {
                const scale = Math.sin(frame.time * 0.002) * 0.3 + 1;
                setRadius(multiplier * scale);
            }, shapeRef.current?.getLayer());

            anim.start();
            return () => anim.stop();
        }
    }, [image.src, image.status]);

    const getCircleStyle = () => {
        // Você pode adicionar mais status conforme necessário
        switch (image.status) {
            case 'alert1':
            case 'alert2':
            case 'alert3':
                return {
                    fill: "red",
                    opacity: 0.7,
                    fillRadialGradientColorStops: [
                        0, 'rgba(255,0,0,0.6)',
                        0.5, 'rgba(255,0,0,0.4)',
                        1, 'rgba(255,0,0,0.2)'
                    ]
                };
            default:
                return {
                    fill: config.defaultColor,
                    opacity: 0.9,
                    shadowBlur: 10,
                    shadowColor: 'white'
                };
        }
    };

    React.useEffect(() => {
        if (isSelected) {
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    const onMouseEnter = (event) => {
        event.target.getStage().container().style.cursor = isSelected ? "move" : "pointer";
    };

    const onMouseLeave = (event) => {
        event.target.getStage().container().style.cursor = "default";
    };

    const handleDelete = () => {
        unSelectShape(null);
        onDelete(shapeRef.current);
    };

    return (
        <React.Fragment>
            {image?.src ? (
                <Image
                    image={img}
                    x={image.x}
                    y={image.y}
                    rotation={image.rotation || 0}
                    scaleX={image.scaleX || 1}
                    scaleY={image.scaleY || 1}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    offsetX={img ? img.width / 2 : 0}
                    offsetY={img ? img.height / 2 : 0}
                    onClick={onSelect}
                    onTap={onSelect}
                    ref={shapeRef}
                    {...shapeProps}
                    draggable={!isStatic}
                    onDragEnd={(e) => {
                        if (isStatic) {
                            return;
                        }
                        onChange({
                            ...shapeProps,
                            x: e.target.x(),
                            y: e.target.y()
                        });
                    }}
                    onTransformEnd={(e) => {
                        if (isStatic) {
                            return;
                        }
                        const node = shapeRef.current;
                        const scaleX = node.scaleX();
                        const scaleY = node.scaleY();
                        const rotation = node.rotation(); // Captura a rotação

                        node.scaleX(1);
                        node.scaleY(1);
                        onChange({
                            ...shapeProps,
                            rotation: rotation,
                            x: node.x(),
                            y: node.y(),
                            width: Math.max(5, node.width() * scaleX),
                            height: Math.max(node.height() * scaleY)
                        });
                    }}
                />
            ) : (
                <Group
                    x={image.x}
                    y={image.y}
                    rotation={image.rotation || 0}
                    scaleX={image.scaleX || 1}
                    scaleY={image.scaleY || 1}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onClick={onSelect}
                    onTap={onSelect}
                    ref={shapeRef}
                    draggable={!isStatic}
                    onDragEnd={(e) => {
                        if (isStatic) {
                            return;
                        }
                        onChange({
                            ...shapeProps,
                            x: e.target.x(),
                            y: e.target.y()
                        });
                    }}
                >
                    <Circle
                        radius={(image.status ?? '').includes('alert') ? radius : 20}
                        fillRadialGradientStartPoint={{ x: 0, y: 0 }}
                        fillRadialGradientStartRadius={0}
                        fillRadialGradientEndPoint={{ x: 0, y: 0 }}
                        fillRadialGradientEndRadius={(image.status ?? '').includes('alert') ? radius : 20}
                        {...getCircleStyle()}
                    />
                    <Text
                        text={image.text?.toString()}
                        fontSize={image.text >= 100 ? 16 : 24}
                        fill="white"
                        align="center"
                        verticalAlign="middle"
                        stroke={'white'}
                        strokeWidth={1}
                        width={radius * 2}
                        height={radius * 2}
                        offsetX={radius}
                        offsetY={radius}
                    />
                </Group>
            )}
            {isSelected && (
                <Transformer
                    ref={trRef}
                    boundBoxFunc={(oldBox, newBox) => {
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                    rotationSnaps={[0, 90, 180, 270]} 
                    onTransform={(e) => {
                        const node = shapeRef.current;
                        const rotation = node.rotation();
                        const scale = {
                            x: node.scaleX(),
                            y: node.scaleY()
                        };
                        console.log('Durante transformação:', { rotation, scale });
                    }}
                    onTransformEnd={(e) => {
                        const node = shapeRef.current;
                        const rotation = node.rotation();
                        const scale = {
                            x: node.scaleX(),
                            y: node.scaleY()
                        };

                        onChange({
                            ...shapeProps,
                            rotation: rotation,
                            scaleX: scale.x,
                            scaleY: scale.y,
                            x: node.x(),
                            y: node.y()
                        });
                    }}
                >
                    <Circle
                        radius={8}
                        fill="red"
                        ref={deleteButton}
                        onClick={handleDelete}
                        x={shapeRef.current.width() * stageScale}
                    />
                </Transformer>
            )}
        </React.Fragment>
    );
};

const MapBait = ({ baseImage, clientUnit, setPosition, setPoints, isStatic = false }) => {
    const dragUrl = React.useRef();
    const dragText = React.useRef();
    const stageRef = React.useRef();
    const [images, setImages] = React.useState(clientUnit.body_center_map_points ?? []);
    useEffect(() => {
        setPoints(images.map((i) => ({ ...i, status: '' })));
    }, [images]);
    const [selectedId, selectShape] = React.useState(null);
    const [stageSpec, setStageSpec] = useState(clientUnit.body_center_map_position ?? { scale: 1, x: 0, y: 0 });
    useEffect(() => {
        setPosition(stageSpec);
    }, [stageSpec]);
    const handleWheel = (e) => {
        e.evt.preventDefault();

        const scaleBy = 1.1;
        const stage = e.target.getStage();
        const oldScale = stage.scaleX();
        const mousePointTo = {
            x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
            y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale
        };

        const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

        setStageSpec({
            scale: newScale,
            x: (stage.getPointerPosition().x / newScale - mousePointTo.x) * newScale,
            y: (stage.getPointerPosition().y / newScale - mousePointTo.y) * newScale
        });
    };

    const handleRemove = (index) => {
        const newList = images.filter((item) => item.index !== index);

        setImages(newList);
    };

    const checkDeselect = (e) => {
        // deselect when clicked on empty area
        const clickedOnEmpty = e.target === e.target.getStage();
        if (clickedOnEmpty) {
            selectShape(null);
        }
    };

    const unSelectShape = (prop) => {
        selectShape(prop);
    };

    const onDeleteImage = (node) => {
        const newImages = [...images];
        newImages.splice(node.index, 1);
        setImages(newImages);
    };

    const getNextPoint = () => {
        const imagesSorted = (images ?? []).filter((item) => !item.src).sort((a, b) => a.text - b.text);

        return ((imagesSorted[imagesSorted.length - 1]?.text ?? 0) + 1)
    }

    const { t } = useTranslation();

    const { isOpen: isOpenModalCustomPoint, onOpen: onOpenModalCustomPoint, onClose: onCloseModalCustomPoint } = useDisclosure();

    const [customPoint, setCustomPoint] = useState();

    return (
        <div>
            <div>
                <div>
                    <div>
                    {!isStatic && <div>
                            <div className="w-full flex justify-between">
                                <div className="flex gap-2">
                                    <Button colorScheme="blue" borderRadius={1} size="sm" onClick={() => {
                                        const currPosition = stageRef.current.getRelativePointerPosition();
                                        setImages(
                                            images.concat([
                                                {
                                                    ...currPosition,
                                                    y: currPosition.y + 35,
                                                    src: null,
                                                    text: getNextPoint(),
                                                    fontSize: 20,
                                                }
                                            ])
                                        );
                                    }} className="p-2 bg-blue-700 text-white rounded flex gap-1 items-center">
                                        <FiPlus /> {t('MapBaitPointInSequence')}: {getNextPoint()}
                                    </Button>
                                    {!isOpenModalCustomPoint && <Button colorScheme="blackAlpha" borderRadius={1} size="sm" onClick={onOpenModalCustomPoint} className="p-2 bg-blue-700 text-white rounded flex gap-1 items-center">
                                        <FiPlus /> {t('MapBaitCustomPoint')}
                                    </Button>}
                                    {isOpenModalCustomPoint &&<div className="flex items-center gap-2">
                                        <Input autoFocus maxWidth={'100px'} size={'sm'} type="number" value={customPoint} onChange={(e) => setCustomPoint(parseInt(e.target.value))} />
                                        <Button colorScheme="blue" borderRadius={1} size="sm" onClick={() => {
                                            const newPoint = parseInt(customPoint);

                                            if (isNaN(newPoint) || newPoint < 1) {
                                                mensagemErro(t('MapBaitCustomPointError'));
                                                return;
                                            }
                                            const currPosition = stageRef.current.getRelativePointerPosition();
                                            setImages(
                                                images.concat([
                                                    {
                                                        ...currPosition,
                                                        y: currPosition.y + 25,
                                                        src: null,
                                                        text: customPoint,
                                                        fontSize: 20,
                                                    }
                                                ])
                                            );
                                            setCustomPoint();
                                            onCloseModalCustomPoint();
                                        }} className="p-2 bg-blue-700 text-white rounded flex gap-1 items-center">
                                            {t('MapBaitAdd')}
                                        </Button>
                                        <Button onClick={onCloseModalCustomPoint} colorScheme="red" borderRadius={1} size="sm">
                                            {t('MapBaitClose')}
                                        </Button>
                                    </div>}              
                                </div>

                                <div>
                                    <Button colorScheme="green" borderRadius={1} size="sm" type="submit" className="p-2 bg-blue-700 text-white rounded">
                                        {t('MapBaitSaveMap')}
                                    </Button>
                                </div>
                            </div>
                            <div className="block relative mt-2">
                                <img
                                    height="50rem"
                                    width="50rem"
                                    objectfit="contain"
                                    key="img3"
                                    alt={'Map'}
                                    src={baseImage}
                                    draggable="true"
                                    onDragStart={(e) => {
                                        dragUrl.current = e.target.src;
                                    }}
                                />
                            </div>
                        </div>}
                    </div>
                    <div
                        className={`${isStatic ? 'overflow-x-hidden' : 'overflow-x-auto'} flex justify-center items-center w-full`}
                        onDrop={(e) => {
                            if (isStatic) {
                                return;
                            }
                            e.preventDefault();
                            stageRef.current.setPointersPositions(e);
                            setImages([
                                {
                                    ...stageRef.current.getRelativePointerPosition(),
                                    src: dragUrl.current,
                                    text: dragText.current,
                                },
                                ...images
                            ]);
                        }}
                        onDragOver={(e) => !isStatic && e.preventDefault()}
                    >
                        <Stage
                            width={1100}
                            height={500}
                            onMouseDown={!isStatic ? checkDeselect : undefined}
                            onTouchStart={!isStatic ? checkDeselect : undefined}
                            style={{
                                border: "1px solid #E2E8F0",
                                cursor: "default",
                                borderRadius: 5,
                                boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)"
                            }}
                            ref={stageRef}
                            draggable={!isStatic}
                            scaleX={stageSpec.scale}
                            scaleY={stageSpec.scale}
                            x={stageSpec.x}
                            y={stageSpec.y}
                            onWheel={!isStatic ? handleWheel : undefined}
                            onDragEnd={(e) => {
                                if (e.target === e.target.getStage() && !isStatic) {
                                    setStageSpec({
                                        ...stageSpec,
                                        x: e.target.x(),
                                        y: e.target.y()
                                    });
                                }
                            }}
                        >
                            <Layer>
                                {images.map((image, index) => {
                                    return (
                                        <URLImage
                                            image={image}
                                            key={index}
                                            shapeProps={image}
                                            stageScale={stageSpec.scale}
                                            isSelected={!isStatic && image === selectedId}
                                            unSelectShape={unSelectShape}
                                            onClick={!isStatic ? handleRemove : undefined}
                                            onSelect={() => {
                                                if (isStatic) {
                                                    return;
                                                }
                                                selectShape(image);
                                            }}
                                            onChange={(newAttrs) => {
                                                if (isStatic) {
                                                    return;
                                                }
                                                const rects = images.slice();
                                                rects[index] = newAttrs;
                                                setImages(rects);
                                            }}
                                            onDelete={!isStatic ? onDeleteImage : undefined}
                                            isStatic={isStatic}
                                        />
                                    );
                                })}
                            </Layer>
                        </Stage>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MapBait;