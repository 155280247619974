import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useIsAuth } from "../../hooks/useIsAuth"
import { useParams } from "react-router-dom";
import { useState, useEffect } from 'react'
import { Formik, Form, useFormikContext, useField } from 'formik'
import { Box, Button, Flex, Img, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Radio, Stack, Text, useDisclosure } from "@chakra-ui/react";
import { leituraActions } from "../reducer";
import { ListHeader } from "../../sistema/components/ListHeader";
import Layout from "../../sistema/components/Layout";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import Loader from "../../sistema/components/Loader";
import InputField from "../../sistema/components/InputField";
import InputSelect from "../../sistema/components/InputSelect";
import { Leitura } from "../types/leitura";
import { LeiturasResponsaveis } from "../components/LeituraResponsaveis";
import validateForm from "../../utils/validateForm";
import { useTranslation } from "react-i18next";
import { formatData } from "../../utils/data";
import { AvaliacaoInicialQuestao } from "../../sistema/types/avaliacao_inicial_questao";
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import { ReactECharts } from "../components/ReactECharts";
import { Sinaleiro } from "../components/Sinaleiro";
import { LeituraAvaliacaoInicial } from "../../sistema/types/leitura_avaliacao_inicial";
import InputRadio from "../../sistema/components/InputRadio";
import { mensagemErro } from "../../utils/toasts";
import { PreparacaoInicial } from "../types/preparacao_inicial";
import imagesUrl from "../../utils/imagesUrl";

const avaliacao_silo_inteiro = imagesUrl + 'armazenamentos/avaliacao_silo_inteiro.jpg'
const avaliacao_silo_corpo = imagesUrl + 'armazenamentos/avaliacao_silo_corpo.jpg'
const avaliacao_silo_base = imagesUrl + 'armazenamentos/avaliacao_silo_base.jpg'
const avaliacao_silo_teto = imagesUrl + 'armazenamentos/avaliacao_silo_teto.jpg'
const predio_parado = imagesUrl + 'armazenamentos/predio_parado.gif'

export const tiposRespostasPadrao = [
  {
    key: 'DashboardAvaliacaoInicialConforme',
    value: 1,
  },
  {
    key: 'DashboardAvaliacaoInicialNaoConforme',
    value: 2,
  },
  {
    key: 'DashboardAvaliacaoInicialNaoSeAplica',
    value: 3,
  }
]

export const tiposRespostasSinaleiro = [
  {
    key: 'DashboardAvaliacaoInicialVermelho',
    value: 1,
  },
  {
    key: 'DashboardAvaliacaoInicialAmarelo',
    value: 2,
  },
  {
    key: 'DashboardAvaliacaoInicialVerde',
    value: 3,
  }
]

export const PreparacaoInicialEdit = () => {
  useIsAuth();

  const { t } = useTranslation()
  const { id } = useParams();

  const preparacaoInicial = useSelector((state: RootState) => state.leituras.preparacaoInicial)
  const leituraDados = useSelector((state: RootState) => state.leituras.leituraDadosSelectsInputs)
  const isLoading = useSelector((state: RootState) => !!state.leituras.isLoading)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!id) return;

    dispatch(leituraActions.preparacaoInicialRequest(id))
  }, [dispatch, id])

  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);

  const ClienteUnidadeInput = () => {
    const formik = useFormikContext();
    const values = formik.values as Leitura;

    if (!values?.cliente_id) {
      return (
        <InputSelect label={t('LeiturasEditUnidade')} name="cliente_unidade_id" width={tamanhoCampo}>
          <option value={""}>{t('LeiturasEditUnidadeNone')}</option>
        </InputSelect>
      );
    }

    return (
      <InputSelect label={t('LeiturasEditUnidade')} name="cliente_unidade_id" width={tamanhoCampo}>
        {leituraDados && leituraDados.clientes_unidades.filter((i) => i.cliente_id == values.cliente_id).map((i) => (
          <option value={i.id} key={i.id}>{i.nome_referencia} - {i.cpf_cnpj} - {i.cidade} ({(i.ativo as any) == '1' ? t('DashboardAtivo') : t('DashboardInativo')})</option>
        ))}
      </InputSelect>
    );
  }

  const ClienteUnidadeSiloInput = () => {
    const formik = useFormikContext();
    const values = formik.values as Leitura;

    if (!values?.cliente_unidade_id) {
      return (
        <InputSelect label={t('LeiturasEditSilo')} name="cliente_unidade_silo_id" width={tamanhoCampo}>
          <option value={""}>{t('LeiturasEditSiloNone')}</option>
        </InputSelect>
      );
    }

    const onChangeSilo = (val: string) => {
      const siloEncontrado = (leituraDados?.clientes_unidades_silos?.find((i) => i.id == val as any) ?? null);
      if (!siloEncontrado) {
        formik.setFieldValue('silo', null);
        return;
      }
      formik.setFieldValue('silo', {
        ...siloEncontrado,
        tipo_transporta: siloEncontrado.tipo_transporta ? siloEncontrado.tipo_transporta : '',
      });
    }

    return (
      <InputSelect onChangeVal={onChangeSilo} label={t('LeiturasEditSilo')} name="cliente_unidade_silo_id" width={tamanhoCampo}>
        {leituraDados && leituraDados.clientes_unidades_silos.filter((i => i.cliente_unidade_id == values.cliente_unidade_id)).map((i) => (
          <option value={i.id} key={i.id}>{i.nome_referencia} - {t(`ClienteSilos${i.tipo_silo}`)}</option>
        ))}
      </InputSelect>
    );
  }

  const tamanhoCampo = isMobile ? '100%' : '33.33%';

  const InputLeituraSelect = () => {
    const formik = useFormikContext();

    const onChangeLocal = (val: string) => {
      const leitura = leituraDados?.leituras.find((i) => i.id as any == val);

      if (!val) return;

      formik.setFieldValue(`cliente_id`, leitura?.cliente_id);
      formik.setFieldValue(`cliente_unidade_id`, leitura?.cliente_unidade_id);
      formik.setFieldValue(`cliente_unidade_silo_id`, leitura?.cliente_unidade_silo_id);

      console.log('InputLeituraSelect', leitura)
    }

    return (
      <InputSelect onChangeVal={onChangeLocal} label={t('DashboardStatusLeitura')} name="leitura_id" width={tamanhoCampo}>
        {leituraDados && leituraDados.leituras.map((i) => (
          <option value={i.id} key={i.id}>{i.cliente?.nome} - {formatData(i.data)} </option>
        ))}
      </InputSelect>
    )
  }

  const [isLoadingLocal, setisLoadingLocal] = useState(false);

  const SinaleiroForm = ({ name, value }: { name: string; value: number }) => {
    const [, , { setValue }] = useField(name);

    const onClickSinaleiro = (value: number) => {
      setValue(value)
    }

    const cores = [
      '',
      'red',
      'yellow',
      'green'
    ]

    return (
      <Sinaleiro largura="25px" onClick={onClickSinaleiro} cor={cores[value]} />
    )
  }

  const BlocoQuestoes = ({ imagemSilo, label, origem, values }: { imagemSilo: string; mostraPreparacaoTotal?: boolean; label?: string; origem: string; values: PreparacaoInicial; }) => {
    const [, { value: questoesFormatadas }, { setValue: setQuestoesFormatadas }] = useField('questoesFormatadas');

    const questoes: AvaliacaoInicialQuestao[] = questoesFormatadas[origem];

    const getTotalFeito = (origem: string) => {
      const dados: any = questoesFormatadas[origem];

      if (!dados) return;

      const total = dados.length;
      let respondidas = 0;

      dados.forEach((i: any) => {
        if (i.resposta && i.resposta.status == 3) {
          respondidas += 1;
        } else if (i.resposta && i.resposta.data_fim && i.resposta.data_inicio && i.resposta.status > 0) {
          respondidas += 1;
        }
      })

      return parseFloat(((respondidas * 100) / total).toFixed(2));
    }

    const ButtonResponsaveis = ({ index, count }: { index: number; count: number }) => {
      const { isOpen: isOpenPessoas, onOpen: onOpenPessoas, onClose: onClosePessoas } = useDisclosure()

      return (
        <>
          <DefaultButton onClick={onOpenPessoas} size={"xs"}>{t('DashboardAvaliacaoInicialResponsaveis')} ({count})</DefaultButton>

          <Modal autoFocus={false} returnFocusOnClose={false} isOpen={isOpenPessoas} onClose={onClosePessoas}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {isOpenPessoas && <LeiturasResponsaveis name={`questoesFormatadas.${origem}[${index}].resposta.pessoas`} />}
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blackAlpha" onClick={onClosePessoas}>{t('SistemaVoltar')}</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

        </>
      )
    }

    const gaugeData = [
      {
        value: getTotalFeito('teto'),
        name: t('AvaliacaoInicialQuestaoEditTeto'),
        title: {
          offsetCenter: ['0%', '-30%']
        },
        detail: {
          valueAnimation: true,
          offsetCenter: ['0%', '-15%']
        }
      },
      {
        value: getTotalFeito('corpo'),
        name: t('AvaliacaoInicialQuestaoEditCorpo'),
        title: {
          offsetCenter: ['0%', '0%']
        },
        detail: {
          valueAnimation: true,
          offsetCenter: ['0%', '13%']
        }
      },
      {
        value: getTotalFeito('base'),
        name: t('AvaliacaoInicialQuestaoEditBase'),
        title: {
          offsetCenter: ['0%', '27%']
        },
        detail: {
          valueAnimation: true,
          offsetCenter: ['0%', '40%']
        }
      }
    ];

    const getResultadoQuestaoIndividual = (questao_nome: string) => {
      const dados: any = questoesFormatadas['predio'];

      if (!dados) return;

      const questao = dados.find((i: any) => i.nome_questao.trim() == questao_nome);

      const resultado = parseInt(questao?.resposta?.status ?? '0');

      if (resultado > 100) return 100;

      return resultado;
    }

    const graficoGeralPredioData = {
      series: [
        {
          type: 'gauge',
          startAngle: 90,
          endAngle: -270,
          pointer: {
            show: false
          },
          progress: {
            show: true,
            overlap: false,
            roundCap: true,
            clip: false,
            itemStyle: {
              borderWidth: 1,
              borderColor: '#464646'
            }
          },
          axisLine: {
            lineStyle: {
              width: 40
            }
          },
          splitLine: {
            show: false,
            distance: 0,
            length: 10
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false,
            distance: 50
          },
          data: [
            {
              value: getResultadoQuestaoIndividual('DashboardAvaliacaoInicialVedacao'),
              name: t('DashboardAvaliacaoInicialVedacao'),
              
              title: {
                offsetCenter: ['0%', '-45%']
              },
              detail: {
                valueAnimation: true,
                offsetCenter: ['0%', '-31%']
              }
            },
            {
              value: getResultadoQuestaoIndividual('DashboardAvaliacaoInicialMontagemInjecaoSilos'),
              name: t('DashboardAvaliacaoInicialMontagemInjecaoSilos'),
              title: {
                offsetCenter: ['0%', '-15%']
              },
              detail: {
                valueAnimation: true,
                offsetCenter: ['0%', '-3%']
              }
            },
            {
              value: getResultadoQuestaoIndividual('DashboardAvaliacaoInicialProfilaxia'),
              name: t('DashboardAvaliacaoInicialProfilaxia'),
              title: {
                offsetCenter: ['0%', '12%']
              },
              detail: {
                valueAnimation: true,
                offsetCenter: ['0%', '25%']
              }
            },
            {
              value: getResultadoQuestaoIndividual('DashboardAvaliacaoInicialTermoNebulização'),
              name: t('DashboardAvaliacaoInicialTermoNebulização'),
              title: {
                offsetCenter: ['0%', '41%']
              },
              detail: {
                valueAnimation: true,
                offsetCenter: ['0%', '53%']
              }
            }
          ],
          title: {
            fontSize: 12
          },
          detail: {
            width: 30,
            height: 8,
            fontSize: 10,
            color: 'inherit',
            borderColor: 'inherit',
            borderRadius: 20,
            borderWidth: 1,
            formatter: '{value}%',
          }
        }
      ]
    }

    const graficoGeralData = {
      series: [
        {
          type: 'gauge',
          startAngle: 90,
          endAngle: -270,
          pointer: {
            show: false
          },
          progress: {
            show: true,
            overlap: false,
            roundCap: true,
            clip: false,
            itemStyle: {
              borderWidth: 1,
              borderColor: '#464646'
            }
          },
          axisLine: {
            lineStyle: {
              width: 50
            }
          },
          splitLine: {
            show: false,
            distance: 0,
            length: 10
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false,
            distance: 50
          },
          data: gaugeData,
          title: {
            fontSize: 14
          },
          detail: {
            width: 30,
            height: 8,
            fontSize: 10,
            color: 'inherit',
            borderColor: 'inherit',
            borderRadius: 20,
            borderWidth: 1,
            formatter: '{value}%',
          }
        }
      ]
    };

    const questaoFoiRespondida = (resposta: LeituraAvaliacaoInicial) => {
      if (resposta.status == 3) return true;
      return resposta.status > 0 && resposta.data_fim && resposta.data_inicio;
    }

    return (
      <>
        <Flex width={"full"} mt={6}></Flex>
        {label && <ListHeaderSecondary label={label} />}
        {((values && tipoAvaliacao(values) != 'Prédio') || (values && tipoAvaliacao(values) == 'Prédio' && questoes[0].e_sinaleiro == 1)) &&
          <Flex width={"full"} wrap={"wrap-reverse"} bg={"white"} borderRadius={"25px"}>
            <Flex width={isMobile ? "100%" : questoes[0].e_sinaleiro == 1 ? "40%" : "50%"} py={2} top={-10} wrap={"wrap"}>
              <img style={{ margin: 'auto' }} width={isMobile ? '50%' : "70%"} height={"auto"} src={imagemSilo} />
            </Flex>
            <Flex alignItems={"center"} width={isMobile ? "100%" : questoes[0].e_sinaleiro == 1 ? "60%" : "50%"} py={2}>
              {questoes[0].e_sinaleiro == 1 ? <>
                <ReactECharts option={(values && tipoAvaliacao(values) != 'Prédio') ? graficoGeralData as any : graficoGeralPredioData as any} style={{ height: '400px' }} />
              </> : <>
                <ReactECharts option={{
                  series: [
                    {
                      type: 'gauge',
                      startAngle: 90,
                      endAngle: -270,
                      pointer: {
                        show: false
                      },
                      progress: {
                        show: true,
                        overlap: false,
                        roundCap: true,
                        clip: false,
                        itemStyle: {
                          borderWidth: 1,
                          borderColor: '#464646'
                        }
                      },
                      axisLine: {
                        lineStyle: {
                          width: 20
                        }
                      },
                      splitLine: {
                        show: false,
                        distance: 0,
                        length: 10
                      },
                      axisTick: {
                        show: false
                      },
                      axisLabel: {
                        show: false,
                        distance: 50
                      },
                      data: [
                        {
                          value: getTotalFeito(origem as any),
                          name: origem == 'teto' ? t('AvaliacaoInicialQuestaoEditTeto') : origem == 'corpo' ? t('AvaliacaoInicialQuestaoEditCorpo') : t('AvaliacaoInicialQuestaoEditBase'),
                          title: {
                            offsetCenter: ['0%', '-18%']
                          },
                          detail: {
                            valueAnimation: true,
                            offsetCenter: ['0%', '10%']
                          }
                        },
                      ],
                      title: {
                        fontSize: 14
                      },
                      detail: {
                        width: 30,
                        height: 8,
                        fontSize: 10,
                        color: 'inherit',
                        borderColor: 'inherit',
                        borderRadius: 20,
                        borderWidth: 1,
                        formatter: '{value}%',
                      }
                    }
                  ]
                } as any
                } style={{ height: '200px' }} />
              </>}
            </Flex>
          </Flex>}

        {questoes[0].e_sinaleiro == 0 && questoes.map((i, index) => (
          <Flex key={`${index}`} width={"full"} direction={"column"}>
            <Box bg={questaoFoiRespondida(i.resposta as any) ? 'green.100' : 'white'} my={1} p={2} borderWidth="1px" borderRadius="lg" boxShadow="md">
              <Flex width={"100%"} wrap={"wrap"}>
                <Flex width={isMobile ? '100%' : '50%'} direction={"column"}>
                  <Text fontSize="xl" fontWeight={600}>{t(i.nome_questao)}</Text>
                  <InputRadio name={`questoesFormatadas.${origem}[${index}].resposta.status`}>

                    {values && tipoAvaliacao(values) == 'Prédio' ? <>
                      <Radio mx={2} value={'0'}>
                        0%
                      </Radio>
                      <Radio mx={2} value={'25'}>
                        25%
                      </Radio>
                      <Radio mx={2} value={'50'}>
                        50%
                      </Radio>
                      <Radio mx={2} value={'75'}>
                        75%
                      </Radio>
                      <Radio mx={2} value={'100'}>
                        100%
                      </Radio>
                      <Radio mx={2} value={'101'}>
                        {t('DashboardAvaliacaoInicialNaoSeAplica')}
                      </Radio>
                    </> :
                      <Stack spacing={2} flex="flex" wrap={"wrap"}>
                        {tiposRespostasPadrao.map((option, index) => (
                          <Radio key={index} value={(option.value as any).toFixed(0)}>
                            {t(option.key)}
                          </Radio>
                        ))}
                      </Stack>}
                  </InputRadio>
                  <Flex bottom={0} mt={isMobile ? 2 : 4}>
                    <ButtonResponsaveis count={(i?.resposta?.pessoas ?? []).filter((i) => !i.deleted).length} index={index} />
                  </Flex>

                </Flex>
                {values && tipoAvaliacao(values) != 'Prédio' ?
                  <Flex width={isMobile ? '100%' : '50%'} direction={"column"}>
                    <InputField type="datetime-local" label={t('DashboardAvaliacaoInicialDataInicio')} height={"20px"} name={`questoesFormatadas.${origem}[${index}].resposta.data_inicio`} />
                    <InputField type="datetime-local" label={t('DashboardAvaliacaoInicialDataFim')} name={`questoesFormatadas.${origem}[${index}].resposta.data_fim`} />
                  </Flex>
                  :
                  <Flex width={isMobile ? '100%' : '50%'} wrap={"wrap"} justifyContent={"center"}>
                    <Img m={1} src={i.imagem} maxH={"150px"} width={"auto"} />
                  </Flex>
                }
              </Flex>
            </Box>
          </Flex>
        ))}


        {questoes[0].e_sinaleiro == 1 ? <>
          <Flex mt={4} justifyContent={"space-around"} width={"full"} wrap={"wrap"} px={isMobile ? '0px' : '100px'}>
            {questoes.filter((i) => tipoAvaliacao(values) == 'Prédio' ? i.nao_mostra_no_predio == 0 : true).map((i, index) => (
              <Flex key={`${index}`} width={"33%"} direction={"column"} justifyContent={"center"} alignItems={"center"}>
                <SinaleiroForm value={i?.resposta?.status ?? 0} name={`questoesFormatadas.${origem}[${index}].resposta.status`} />
                <Text textAlign={"center"} fontSize={"12px"}>
                  {t(i.nome_questao)}
                </Text>
              </Flex>
            ))}
          </Flex>
        </> :
          <Flex width={"full"}>
          </Flex>
        }
      </>
    )
  }

  const tipoAvaliacao = (values: PreparacaoInicial): 'Cilindrico' | 'Graneleiro' | 'Prédio' | '' => {
    const silo = (leituraDados?.clientes_unidades_silos ?? []).find((i) => i.id == values.cliente_unidade_silo_id);

    if (silo) {
      return silo.tipo_silo.trim() as any;
    }

    return "";
  }

  return (
    <Layout showInternetStatus={true}>
      <ListHeader label={t('PreparacaoInicialTituloEdit')}>
        <DefaultButton ml={4} to="/preparacoes-iniciais"> {t('SistemaVoltar')} </DefaultButton>
      </ListHeader>

      <Loader isLoading={isLoading} />
      {/* {JSON.stringify(preparacaoInicial)} */}
      {!!preparacaoInicial && <Formik
        initialValues={preparacaoInicial}
        enableReinitialize
        onSubmit={async (values, { setErrors }) => {
          const validation = validateForm({
            cliente_id: 'required',
            cliente_unidade_id: 'required',
            cliente_unidade_silo_id: 'required',
          }, values, t)
          if (Object.keys(validation).length > 0) {
            setErrors(validation)
            mensagemErro("Preencha todos os campos antes de salvar.");
            return;
          }


          if (values.id) {
            console.log("leitura form", values);
            dispatch(leituraActions.savePreparacaoInicial({
              ...values,
              changed: true,
            }));
            return;
          }
          setisLoadingLocal(true)
          console.log("leitura form", values);
          dispatch(leituraActions.savePreparacaoInicialLocalRequest({
            ...values,
            changed: true,
          }));

          setTimeout(() => {
            setisLoadingLocal(false);
          }, 1000)
        }}
      >
        {({ values, submitForm }) => (
          <Form>
            <Flex width="full" wrap={"wrap"}>
              <InputField label={t('LeiturasListData')} type="datetime-local" name="data_hora" autoFocus width={tamanhoCampo} />
              <InputLeituraSelect />
              <InputSelect label={t('ClienteEditTitulo')} name="cliente_id" width={tamanhoCampo}>
                {leituraDados && leituraDados.clientes.map((i) => (
                  <option value={i.id} key={i.id}>{i.nome} ({(i.ativo as any) == '1' ? t('DashboardAtivo') : t('DashboardInativo')})</option>
                ))}
              </InputSelect>
              <ClienteUnidadeInput />
              <ClienteUnidadeSiloInput />
            </Flex>

            <BlocoQuestoes values={values} origem="sinaleiro" imagemSilo={(tipoAvaliacao(values) == 'Prédio') ? predio_parado : avaliacao_silo_inteiro} />

            {(tipoAvaliacao(values) == 'Cilindrico' || tipoAvaliacao(values) == 'Graneleiro') && <BlocoQuestoes values={values} origem="teto" imagemSilo={avaliacao_silo_teto} label={t('AvaliacaoInicialQuestaoEditTeto')} />}
            {(tipoAvaliacao(values) == 'Cilindrico' || tipoAvaliacao(values) == 'Graneleiro') && <BlocoQuestoes values={values} origem="corpo" imagemSilo={avaliacao_silo_corpo} label={t('AvaliacaoInicialQuestaoEditCorpo')} />}
            {(tipoAvaliacao(values) == 'Cilindrico' || tipoAvaliacao(values) == 'Graneleiro') && <BlocoQuestoes values={values} origem="base" imagemSilo={avaliacao_silo_base} label={t('AvaliacaoInicialQuestaoEditBase')} />}
            {(tipoAvaliacao(values) == 'Prédio') && <BlocoQuestoes values={values} origem="predio" imagemSilo={avaliacao_silo_base} label={t('DashboardAvaliacaoInicialQuestoes')} />}

            <InputField textarea={true} rows={3} label={t('AvaliacaoInicialObservacoes')} name="observacoes" />
            <Flex width={"full"} mb={"100px"} />

            <Box width={"full"} position={"fixed"} p={4} bg={"white"} marginLeft={"-17px"} bottom={0} borderTop={"2px solid #134e1d"} zIndex={10}>
              <DefaultButton onClick={submitForm} isLoading={isLoadingLocal} type="button">{t('SistemaSalvar')} {t('PreparacaoInicialTituloEdit')}</DefaultButton>
            </Box>
          </Form>
        )}
      </Formik>}
    </Layout>
  )
}
