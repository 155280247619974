import { FiActivity, FiAlertCircle, FiBarChart2, FiCalendar, FiCheckCircle, FiClock, FiEye, FiFileText, FiFlag, FiHome, FiImage, FiMapPin, FiSearch } from "react-icons/fi";
import { ClienteUnidade } from "../../sistema/types/cliente_unidade"
import { formatData } from "../../utils/data";
import { useTranslation } from "react-i18next";
import { UnitDetailsModal } from "./UnitDetailsModal";
import { useState } from "react";
import { Image, useMediaQuery } from "@chakra-ui/react";
import { RootState } from "../../app/mainReducer";
import { useSelector } from "react-redux";
import ReactSpeedometer, { CustomSegmentLabelPosition } from "react-d3-speedometer";
import { BodyCenterVisitSighting } from "../types/body_center_visit_sighting";
import { BodyCenterVisitTrace } from "../types/body_center_visit_trace";
import { BodyCenterVisitOccurrence } from "../types/body_center_visit_occurrence";
import { getBaitsTypes } from "../utils/bodyCenterBaits";
import { formatValor } from "../../utils/formatValor";

type UnitCardProps = {
  unit: ClienteUnidade;
}

type IndicatorResult = {
  value: number;
  name: string;
  color?: 'red' | 'yellow' | 'green';
}

const isConsumed = (occurrence: BodyCenterVisitOccurrence) => {
  const ocurrences = (getBaitsTypes().find(baitType => baitType.value == occurrence.bait_type)?.occurrences ?? []);
  if (!ocurrences.length) {
    return false;
  }
  return ocurrences.find(o => o.value == occurrence.bait_type_occurrence)?.isConsumed == 1;
}

export const UnitCard = ({ unit }: UnitCardProps) => {
  const { t } = useTranslation();


  const calculateConsumptionIndicator = (occurrences: BodyCenterVisitOccurrence[]): IndicatorResult => {
    const totalBaits = occurrences.filter(o => o.bait_type > 0);
    const consumedBaits = totalBaits.filter(o => isConsumed(o)).length;
    const percentage = totalBaits ? (consumedBaits / totalBaits.length) * 100 : 0;

    const value = percentage <= 0 ? 0 : (percentage > 100 ? 100 : percentage);

    return {
      value: isNaN(value) ? 0 : value,
      name: t('BodyCenterBaitConsumptionQuantityName'),
    };
  };

  const calculateTracesIndicator = (traces: BodyCenterVisitTrace[]): IndicatorResult => {
    const sectors = new Set([
      ...(traces?.map((i) => i.sector) ?? []),
    ]);

    const sectorsWithTraces = new Set([
      ...(traces.filter(t => t.quantity > 0).map(t => t.sector) ?? []),
    ]);
    const percentage = sectors.size ? (sectorsWithTraces.size / sectors.size) * 100 : 0;

    const value = percentage <= 0 ? 0 : (percentage > 100 ? 100 : percentage);

    return {
      value: isNaN(value) ? 0 : value,
      name: t('BodyCenterTracesQuantityName'),
    };
  };

  const calculateSightingsIndicator = (sightings: BodyCenterVisitSighting[]): IndicatorResult => {
    const sectors = new Set([
      ...(sightings?.map((i) => i.sector) ?? []),
    ]);

    const sectorsWithSightings = new Set([
      ...(sightings.filter(s => s.quantity_alive > 0 || s.quantity_death > 0).map(s => s.sector) ?? []),
    ]);
    const percentage = sectors.size ? (sectorsWithSightings.size / sectors.size) * 100 : 0;


    const value = percentage <= 0 ? 0 : (percentage > 100 ? 100 : percentage);

    return {
      value: isNaN(value) ? 0 : value,
      name: t('BodyCenterSightingsQuantityName'),
    };
  };

  const getColorByPercentage = (value: number): 'red' | 'yellow' | 'green' => {
    if (value >= 70) return 'red';
    if (value >= 40) return 'yellow';
    return 'green';
  };

  const calculateOverallIndicator = (): IndicatorResult => {
    const indicators = [
      calculateConsumptionIndicator(unit.bodyCenterVisits?.[0]?.occurrences ?? []),
      calculateTracesIndicator(unit.bodyCenterVisits?.[0]?.traces ?? []),
      calculateSightingsIndicator(unit.bodyCenterVisits?.[0]?.sightings ?? []),
    ];

    const average = indicators.reduce((acc, curr) => acc + curr.value, 0) / indicators.length;

    return {
      value: average < 0 ? 0 : (average > 100 ? 100 : average),
      name: t('BodyCenterOverallIndicator'),
      color: getColorByPercentage(average),
    };
  };

  const color: string = calculateOverallIndicator()?.color ?? 'green';

  const getClasses = (status: 'red' | 'yellow' | 'green', isPing: boolean = false) => {
    if (color == 'red') {
      if (status == 'red') return isPing ? 'z-10 bg-red-500 animate-ping' : 'bg-red-500';
      if (status == 'yellow') return 'bg-yellow-100';
      if (status == 'green') return 'bg-green-100';
    }
    if (color == 'yellow') {
      if (status == 'red') return 'bg-red-100';
      if (status == 'yellow') return isPing ? 'z-10 bg-yellow-500 animate-ping' : 'bg-yellow-500';
      if (status == 'green') return 'bg-green-100';
    }
    if (color == 'green') {
      if (status == 'red') return 'bg-red-100';
      if (status == 'yellow') return 'bg-yellow-100';
      if (status == 'green') return 'bg-green-500';
    }
  }

  const [isModalOpen, setIsModalOpen] = useState(false);

  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);

  const isFullHd = window.innerWidth >= 1920;

  const betweenNormalToFullHd = window.innerWidth >= 1745;

  const Speedometer = ({ value }: { value: IndicatorResult }) => {
    return <ReactSpeedometer
      customSegmentLabels={[
        {
          text: "0-39%",
          position: CustomSegmentLabelPosition.Outside,
          color: "#555",
          fontSize: "8px",
        },
        {
          text: "40-69%",
          position: CustomSegmentLabelPosition.Outside,
          color: "#555",
          fontSize: "8px"
        },
        {
          text: "70-100%",
          position: CustomSegmentLabelPosition.Outside,
          color: "#555",
          fontSize: "8px"
        }
      ]}
      currentValueText={`${value.name}: ${formatValor(value.value)}%`}
      value={value.value}
      minValue={0}
      maxValue={100}
      needleColor="#888"
      startColor="#FF6347"
      endColor="#00FF7F"
      segments={3}
      needleHeightRatio={0.7}
      needleTransitionDuration={1000}
      customSegmentStops={[0, 39, 69, 100]}
      segmentColors={['#00FF7F', '#FFD700', '#FF6347']}
      height={isFullHd ? 130 : betweenNormalToFullHd ? 115 : 95}
      width={isFullHd ? 160 : betweenNormalToFullHd ? 150 : 135}
      ringWidth={isFullHd ? 30 : betweenNormalToFullHd ? 25 : 20}
      labelFontSize="8"
      valueTextFontSize="12"

    />
  }

  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="w-full p-0">
      <div onClick={() => setIsModalOpen(true)} className={`bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 p-3 sm:p-1 cursor-pointer ${!isMobile ? 'hover:scale-[1.008]' : ''}`}>
        <div className="flex items-center justify-between mb-1 px-2">
          <h3 className="text-lg md:text-sm font-semibold text-gray-800 sm:truncate">
            {unit.nome_referencia}
          </h3>
          <div className="flex items-center gap-1">
            
            <div className={`w-4 h-4 relative ${isFullHd ? 'md:w-6 md:h-6' : 'md:w-4 md:h-4'} rounded-full transition-all duration-300 ${getClasses('red')}`}>
              {color == 'red' && <div className={`animate-ping absolute inline-flex h-full w-full rounded-full opacity-75 ${getClasses('red', true)}`} />}
            </div>
            <div className={`w-4 h-4 relative ${isFullHd ? 'md:w-6 md:h-6' : 'md:w-4 md:h-4'} rounded-full transition-all duration-300 ${getClasses('yellow')}`}>
              {color == 'yellow' && <div className={`animate-ping absolute inline-flex h-full w-full rounded-full opacity-75 ${getClasses('yellow', true)}`} />}
            </div>
            <div className={`w-4 h-4 ${isFullHd ? 'md:w-6 md:h-6' : 'md:w-4 md:h-4'} rounded-full transition-all duration-300 ${getClasses('green')}`} />
          </div>
        </div>

        {(!isMobile || (isMobile && unit.imagem)) && (
          <div className={`relative w-full ${isFullHd ? 'h-52 pt-1' : betweenNormalToFullHd ? 'h-48' : 'h-40'} mb-3 px-2`}>
            <div className="absolute top-3 left-2 z-10">
              <div className="flex opacity-90 items-center gap-1 bg-white backdrop-blur-sm text-gray-700 text-xs font-medium px-2 py-1 rounded-r shadow-r-sm transition-all duration-200">
                <FiCalendar className="w-3 h-3 text-green-600" />
                <span>{(unit.bodyCenterVisits ?? []).length}</span>
                <span className="text-gray-700">{((unit.bodyCenterVisits ?? []).length == 0 || (unit.bodyCenterVisits ?? []).length > 1)? t('BodyCenterSelectClientTotalVisits') : t('BodyCenterSingular')}</span>
              </div>
            </div>
            {unit.imagem ? (
              <div className="relative w-full h-full">
                <div className="absolute inset-0 bg-gray-100 rounded-md flex items-center justify-center transition-opacity duration-300"
                  style={{ opacity: isLoading ? 1 : 0 }}>
                  <div className="w-12 h-12 border-4 border-green-500 border-t-transparent rounded-full animate-spin" />
                </div>
                <Image
                  src={unit.imagem}
                  alt={unit.nome_referencia}
                  className="w-full h-full object-cover rounded-md transition-opacity duration-300"
                  style={{ opacity: isLoading ? 0 : 1 }}
                  onLoad={() => setIsLoading(false)}
                  fallback={
                    <div className="w-full h-full bg-gray-100 rounded-lg flex flex-col items-center justify-center">
                      <FiImage className="w-12 h-12 text-gray-300" />
                    </div>
                  }
                />
              </div>
            ) : (
              <div className="w-full h-full bg-gray-100 rounded-lg flex flex-col items-center justify-center">
                <FiImage className="w-12 h-12 text-gray-300" />
              </div>
            )}
          </div>
        )}

        {/**
        <div className="flex w-full flex-wrap px-2">
          <div className="mb-3 px-2 w-full md:w-1/2">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-1.5">
                <div className="p-1.5 bg-green-100 rounded-lg">
                  <FiCalendar className="w-5 h-5 md:w-3.5 md:h-3.5 lg:w-4 lg:h-4 text-green-600" />
                </div>
                <div>
                  <p className="text-sm md:text-xs font-medium text-gray-600">
                    {t('BodyCenterTotalVisits')}
                  </p>
                  <p className="text-2xl md:text-base lg:text-lg font-bold text-green-600">
                    {(unit.bodyCenterVisits ?? []).length}
                  </p>
                </div>
              </div>
              <div className="flex items-center justify-center w-12 h-12 md:w-8 md:h-8 bg-green-50 rounded-full">
                <FiBarChart2 className="w-6 h-6 md:w-4 md:h-4 text-green-500" />
              </div>
            </div>
          </div>

          <div className="mb-3 px-2 w-full md:w-1/2">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-1.5">
                <div className="p-1.5 bg-blue-100 rounded-lg">
                  <FiClock className="w-5 h-5 md:w-3.5 md:h-3.5 lg:w-4 lg:h-4 text-blue-600" />
                </div>
                <div>
                  <p className="text-sm md:text-xs font-medium text-gray-600">
                    {t('BodyCenterLastVisit')}
                  </p>
                  <p className="text-2xl md:text-base lg:text-lg font-bold text-blue-600">
                    {unit.bodyCenterVisits?.[0]?.date ? formatData(unit.bodyCenterVisits[0].date, 'dd/MM/yyyy') : t('BodyCenterNaoDisponivel')}
                  </p>
                </div>
              </div>
              <div className="flex items-center justify-center w-12 h-12 md:w-8 md:h-8 bg-blue-50 rounded-full">
                <FiActivity className="w-6 h-6 md:w-4 md:h-4 text-blue-500" />
              </div>
            </div>
          </div>
        </div> */}

        <div className="">
          <div className="w-full">
            <div className="w-full">
              <div className="w-full text-center">
                <p className="text-xs font-bold text-gray-600">
                  {t('BodyCenterIndicatorsLastVisit')} ({unit.bodyCenterVisits?.[0]?.date ? formatData(unit.bodyCenterVisits[0].date, 'dd/MM/yyyy') : t('BodyCenterNaoDisponivel')})
                </p>

                <div className="w-full flex flex-wrap justify-around">
                  <div className={`${isMobile ? 'w-1/2' : 'w-1/3'} flex justify-center`}>
                    <Speedometer value={calculateConsumptionIndicator(unit.bodyCenterVisits?.[0]?.occurrences ?? [])} />
                  </div>

                  <div className="${isMobile ? 'w-1/2' : 'w-1/3'} flex justify-center">
                    <Speedometer value={calculateTracesIndicator(unit.bodyCenterVisits?.[0]?.traces ?? [])} />
                  </div>

                  <div className="${isMobile ? 'w-1/2' : 'w-1/3'} flex justify-center">
                    <Speedometer value={calculateSightingsIndicator(unit.bodyCenterVisits?.[0]?.sightings ?? [])} />
                  </div>
                </div>
                {/**
                <div className="mt-2 flex flex-wrap gap-2 md:gap-1">
                  <div className="w-full flex items-center justify-center bg-purple-100 text-purple-800 text-xs sm:text-[10px] lg:text-xs font-medium px-1.5 py-1 md:py-0.5 rounded-full">
                    <FiCheckCircle className="mr-0.5 w-3 h-3" />
                    {t('BodyCenterOccurrences')} ({unit.bodyCenterVisits?.[0]?.occurrences?.length ?? 0})
                  </div>
                  <div className="w-full flex items-center justify-center bg-red-100 text-red-800 text-xs sm:text-[10px] lg:text-xs font-medium px-1.5 py-1 md:py-0.5 rounded-full">
                    <FiEye className="mr-0.5 w-3 h-3" />
                    {t('BodyCenterSightings')} ({(unit.bodyCenterVisits?.[0]?.sightings ?? []).reduce((acc, i) => acc + parseInt(i.quantity_alive as any ?? '0') + parseInt(i.quantity_death as any ?? '0'), 0)})
                  </div>
                  <div className="w-full flex items-center justify-center bg-yellow-100 text-yellow-800 text-xs sm:text-[10px] lg:text-xs font-medium px-1.5 py-1 md:py-0.5 rounded-full">
                    <FiAlertCircle className="mr-0.5 w-3 h-3" />
                    {t('BodyCenterNonconformities')} ({(unit.bodyCenterVisits?.[0]?.nonconformities ?? []).reduce((acc, i) => acc + parseInt(i.quantity as any ?? '0'), 0)})
                  </div>
                  <div className="w-full flex items-center justify-center bg-green-100 text-green-800 text-xs sm:text-[10px] lg:text-xs font-medium px-1.5 py-1 md:py-0.5 rounded-full">
                    <FiFileText className="mr-0.5 w-3 h-3" />
                    {t('BodyCenterReports')} ({(unit.bodyCenterVisits?.[0]?.nonconformities ?? []).reduce((acc, i) => acc + (i.files?.length ?? 0), 0)})
                  </div>
                  <div className="w-full flex items-center justify-center bg-gray-100 text-gray-800 text-xs sm:text-[10px] lg:text-xs font-medium px-1.5 py-1 md:py-0.5 rounded-full">
                    <FiSearch className="mr-0.5 w-3 h-3" />
                    {t('BodyCenterTraces')} ({(unit.bodyCenterVisits?.[0]?.traces ?? []).reduce((acc, i) => acc + parseInt(i.quantity as any ?? '0'), 0)})
                  </div>
                </div>*/}
              </div>


            </div>
          </div>
        </div>

        {/**
        <div className="mb-3">
          <div className="grid grid-cols-1">
            <div className="bg-white p-2 rounded-lg shadow-sm border border-gray-100">
              <div className="flex items-center gap-2 mb-2">
                <div className="p-2 sm:p-1.5 bg-green-50 rounded-lg">
                  <FiMapPin className="w-5 h-5 sm:w-3.5 sm:h-3.5 lg:w-4 lg:h-4 text-green-600" />
                </div>
                <div>
                  <p className="text-sm sm:text-xs text-gray-500">
                    {t('DashboardPdfEndereço')}
                  </p>
                  <p className="text-xs font-medium">
                    {!unit.endereco ? t('BodyCenterNaoDisponivel') : `${unit.endereco}, n° ${unit.numero}`}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <div className="p-2 sm:p-1.5 bg-purple-50 rounded-lg">
                  <FiHome className="w-5 h-5 sm:w-3.5 sm:h-3.5 lg:w-4 lg:h-4 text-purple-600" />
                </div>
                <div>
                  <p className="text-sm sm:text-xs text-gray-500">
                    {t('ClienteEditInputCidade')}
                  </p>
                  <p className="text-xs font-medium">
                    {unit.cidade ? `${unit.cidade} - ${unit.uf}` : t('BodyCenterNaoDisponivel')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        */}

        <div>
          {/**<button
            className="w-full flex items-center justify-center gap-1.5 px-3 py-2 bg-gradient-to-r from-green-600 to-green-700 hover:from-green-700 hover:to-green-800 text-white text-sm font-medium rounded-lg shadow-md hover:shadow-lg transform hover:-translate-y-0.5 transition-all duration-200"
            onClick={() => setIsModalOpen(true)}
          >
            <FiEye className="w-4 h-4" />
            <span>{t('BodyCenterVerInformacoes')}</span>
          </button> */}

          <UnitDetailsModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            unit={unit}
          />
        </div>
      </div>
    </div>
  )
}