import { useEffect, useState } from "react"
import { mensagemErro } from "../../utils/toasts";
import { formatError } from "../../utils/formatError";
import { Box, Flex, Heading } from "@chakra-ui/react";
import Loader from "../components/Loader";
import { useQuery } from "../../hooks/useQuery";
import { apiCall } from "../../utils/apiCall";
import { useDispatch, useSelector } from "react-redux";
import { sistemaActions } from "../recucer";
import { RootState } from "../../app/mainReducer";
import { useNavigate } from "react-router-dom";

export const LoginExterno = () => {
  const query = useQuery();
  const [isLoading, setIsLoading] = useState(false);
  const [erro, setErro] = useState('');
  const isAuthenticated = useSelector((state: RootState) => state.sistema.token)

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = query.get('token');
  const to = query.get('to');

  useEffect(() => {
    if (isAuthenticated && to) {
      navigate(to)
    }
  }, [isAuthenticated, navigate, to])

  useEffect(() => {
    const buscaDados = async () => {
      console.log(query.get('token'))
      console.log(query.get('to'))

      try {
        setIsLoading(true);
        const { data } = await apiCall({ url: 'user' }, token)

        dispatch(sistemaActions.setAuthData({
          user: data,
          token: token ?? '',
        }))

        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        setErro("Você não está autorizado.");
        mensagemErro(`Ocorreu um erro: ${formatError(err)}`);
      }
    }
    buscaDados();
  }, [])

  return (
    <Box width={"full"}>
      <Loader isLoading={isLoading} />
      {!erro && <Heading size={"sm"}>Carregando...</Heading>}
      {erro && <Flex
        m={4}
        bg={"red.100"}
        borderWidth={"2px"}
        borderColor={"red.300"}
        borderRadius={"10px"}
        p={5}
        maxW={"500px"} wrap={"wrap"}
      >
        Ocorreu um erro ao acessar: {erro}
      </Flex>}
    </Box>
  )
}