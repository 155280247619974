import { LeituraAvaliacaoInicial } from "../types/leitura_avaliacao_inicial";

export const novaLeituraAvaliacaoInicial = (): LeituraAvaliacaoInicial => {
  return {
    leitura_id: 0,
    preparacao_inicial_id: "",
    avaliacao_inicial_questao_id: 0,
    data_inicio: "",
    data_fim: "",
    status: 0,
    pessoas: [],
  };
};
