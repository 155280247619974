export const SiloIcon = () => {
  return (
    <svg style={{ marginRight: '12px' }} version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="20" height="20" viewBox="0 0 64.000000 64.000000"
      preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
        fill="currentColor" stroke="none">
        <path d="M220 574 c-52 -35 -96 -71 -98 -79 -2 -8 -12 -15 -23 -15 -17 0 -19
-8 -19 -100 0 -93 1 -100 20 -100 26 0 26 -22 0 -38 -18 -11 -20 -23 -20 -127
l0 -115 30 0 c20 0 30 5 30 15 0 12 15 15 75 15 60 0 75 -3 75 -15 0 -10 10
-15 30 -15 20 0 30 5 30 15 0 12 15 15 75 15 60 0 75 -3 75 -15 0 -10 10 -15
30 -15 l30 0 0 115 c0 104 -2 116 -20 127 -18 12 -20 24 -20 139 l0 125 -99
67 c-54 37 -100 67 -102 66 -2 0 -47 -29 -99 -65z m182 -11 l71 -48 -76 -3
c-43 -2 -111 -2 -154 0 l-76 3 69 47 c38 26 75 47 82 47 7 1 44 -20 84 -46z
m98 -108 l0 -35 -180 0 -180 0 0 35 0 35 180 0 180 0 0 -35z m-380 -15 c0 -11
-4 -20 -10 -20 -5 0 -10 9 -10 20 0 11 5 20 10 20 6 0 10 -9 10 -20z m0 -60
c0 -11 -4 -20 -10 -20 -5 0 -10 9 -10 20 0 11 5 20 10 20 6 0 10 -9 10 -20z
m380 -20 l0 -40 -180 0 -180 0 0 40 0 40 180 0 180 0 0 -40z m-380 -40 c0 -11
-4 -20 -10 -20 -5 0 -10 9 -10 20 0 11 5 20 10 20 6 0 10 -9 10 -20z m380 -85
l0 -65 -75 0 -75 0 0 40 c0 38 -2 40 -30 40 -28 0 -30 -2 -30 -40 l0 -40 -75
0 -75 0 0 65 0 65 180 0 180 0 0 -65z m-380 -110 c0 -63 -4 -105 -10 -105 -11
0 -14 192 -3 203 12 13 13 7 13 -98z m210 0 c0 -63 -4 -105 -10 -105 -6 0 -10
42 -10 105 0 63 4 105 10 105 6 0 10 -42 10 -105z m210 -6 c0 -59 -4 -99 -10
-99 -6 0 -10 42 -10 106 0 67 4 103 10 99 6 -3 10 -50 10 -106z m-250 1 c0
-28 -2 -30 -38 -30 -29 0 -45 7 -67 30 l-29 30 67 0 67 0 0 -30z m165 0 c-22
-23 -38 -30 -67 -30 -36 0 -38 2 -38 30 l0 30 67 0 67 0 -29 -30z m-290 -10
c18 -19 17 -20 -3 -20 -15 0 -22 6 -22 20 0 11 2 20 3 20 2 0 12 -9 22 -20z
m335 0 c0 -14 -7 -20 -22 -20 -20 0 -21 1 -3 20 10 11 20 20 22 20 1 0 3 -9 3
-20z m-210 -50 c0 -6 -32 -10 -75 -10 -43 0 -75 4 -75 10 0 6 32 10 75 10 43
0 75 -4 75 -10z m210 0 c0 -6 -32 -10 -75 -10 -43 0 -75 4 -75 10 0 6 32 10
75 10 43 0 75 -4 75 -10z"/>
      </g>
    </svg>
  )
}