import { useSelector } from "react-redux"
import { Silo } from "./Silo"
import { RootState } from "../../app/mainReducer"
import { Box, Flex, Img, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react";
import { tipoConfiguracao } from "../arrays/tipo_configuracao";
import { useTranslation } from 'react-i18next';
import { DashboardNomeProduto } from "./DashboardNomeProduto";
import { DashboardVerMais } from "./DashboardVerMais";
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import { DashboardStatus } from "./DashboardStatus";
import { formatValor } from "../../utils/formatValor";
import imagesUrl from "../../utils/imagesUrl";

export const getDensidade = (produto: string | undefined) => {
  if (!produto) return 0;

  const produtos: any = {
    Trigo: 0.765,
    Milho: 0.7,
    Soja: 0.77,
    Sorgo: 0.72,
    Cevada: 0.58,
    Triguilho: 0.6,
    Farelo: 0.25,
    Farinha: 0.77,
    'Arroz Limpo': 0.77,
    'Arroz Casca': 0.35,
    Feijao: 0.8,
    Aveia: 0.5,
    Amendoin: 0.88,
  }

  return produtos[produto] ?? 0;
}

export const DashboardSilo = () => {
  const dashboard = useSelector((state: RootState) => state.leituras.dashboard_leituras);
  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);
  const { t } = useTranslation();

  const getSelected = () => {
    if (!dashboard?.sensores) return [];

    return dashboard.sensores.map((i) => ({
      value: i.posicao,
      sensor: i.sensor,
      altura: i.profundidade,
    }))
  }

  const eFosfina = dashboard?.last_leitura?.tipo_leitura == 'PH3 (Fosfina)';

  const temOPontoConfigurado = (ponto: number) => {
    return dashboard?.sensores?.some((i) => i.posicao == ponto);
  }

  const temOPontoConfiguracao = (configuracao: string) => {
    return dashboard?.sensores?.find((i) => (i.configuracao ?? '').trim() == configuracao);
  }

  const getLeituras = (): any => {
    if (!dashboard?.last_data || !dashboard?.sensores) return [
      {
        valor: 0,
        data: "",
        ponto: `${t('DashboardPonto')} 1`,
        posicaoPonto: 0,
      },
      {
        valor: 0,
        data: "",
        ponto: `${t('DashboardPonto')} 2`,
        posicaoPonto: 0,
      },
      {
        valor: 0,
        data: "",
        ponto: `${t('DashboardPonto')} 3`,
        posicaoPonto: 0,
      },
      {
        valor: 0,
        data: "",
        ponto: `${t('DashboardPonto')} 4`,
        posicaoPonto: 0,
      },
      {
        valor: 0,
        data: "",
        ponto: `${t('DashboardPonto')} 5`,
        posicaoPonto: 0,
      },
      {
        valor: 0,
        data: "",
        ponto: `${t('DashboardPonto')} 6`,
        posicaoPonto: 0,
      },
    ];

    let grafico: any = [];

    const last_data: any = dashboard.last_data;

    for (let i = 1; i <= 16; i++) {
      if (temOPontoConfigurado(i)) {
        grafico.push({
          valor: last_data[`c${i}`],
          data: last_data[`t${i}`],
          ponto: `${t('DashboardPonto')} ${i} ${temOPontoConfiguracao('Retorno')?.posicao == i ? `(${t('DashboardRetorno')})` :
            temOPontoConfiguracao('Injeção superior')?.posicao == i ? `(${t('DashboardInpecaoSuperior')})` :
              temOPontoConfiguracao('Injeção inferior')?.posicao == i ? `(${t('DashboardInpecaoInferior')})` : ''
            }`,
          posicaoPonto: i,
        },)
      }
    }

    return grafico;
  };


  const pontoSuperior = temOPontoConfiguracao('Injeção superior') && eFosfina ? getLeituras().find((i: any) => i.posicaoPonto == temOPontoConfiguracao('Injeção superior')?.posicao as any) : null;
  const pontoInferior = temOPontoConfiguracao('Injeção inferior') && eFosfina ? getLeituras().find((i: any) => i.posicaoPonto == temOPontoConfiguracao('Injeção inferior')?.posicao as any) : null;
  const pontoRetorno = temOPontoConfiguracao('Retorno') && eFosfina ? getLeituras().find((i: any) => i.posicaoPonto == temOPontoConfiguracao('Retorno')?.posicao as any) : null;
  const siloPontoCentral = imagesUrl + "armazenamentos/silo_ponto_central.png";

  const injecoes = () => {
    const values = [];

    if (pontoSuperior) {
      values.push({
        ponto: pontoSuperior.posicaoPonto as number,
        nome: t('DashboardInpecaoSuperior'),
      })
    }

    if (pontoInferior) {
      values.push({
        ponto: pontoInferior.posicaoPonto  as number,
        nome: t('DashboardInpecaoInferior'),
      })
    }

    if (pontoRetorno) {
      values.push({
        ponto: pontoRetorno.posicaoPonto  as number,
        nome: t('DashboardRetorno'),
      })
    }

    return values;
  }

  const imagemSilo = () => {
    return tipoConfiguracao(dashboard?.last_leitura?.tipo_configuracao as number)?.imagem;

    // if (dashboard?.last_leitura?.silo && dashboard?.last_leitura?.silo?.tipo_silo.trim() == "Graneleiro") {
    //   return tipoConfiguracao(dashboard?.last_leitura?.tipo_configuracao as number)?.imagem;
    // }

    // if (dashboard?.last_leitura?.silo && dashboard?.last_leitura?.silo?.tipo_silo.trim() == "Prédio") {
    //   return tipoConfiguracao(dashboard?.last_leitura?.tipo_configuracao as number)?.imagem;
    // }

    // return "";
  }

  const imagemSilo2 = () => {
    return tipoConfiguracao(dashboard?.last_leitura?.tipo_configuracao as number)?.imagem2;
  }

  const tipo_silo = dashboard?.last_leitura?.silo?.tipo_silo;



  const getCapacidadeSilo = () => {
    const altura: any = dashboard?.last_leitura?.silo?.altura ?? 0;
    const diametro: any = dashboard?.last_leitura?.silo?.diametro ?? 0;

    if (!altura || !diametro || !getDensidade(dashboard?.last_leitura?.produto)) return 0;

    const diametro2 = altura * ((diametro / 2) * (diametro / 2) * 3.1415)

    return diametro2 * getDensidade(dashboard?.last_leitura?.produto);
  }

  const { isOpen, onOpen, onClose } = useDisclosure()
  const user = useSelector((state: RootState) => state.sistema.user);

  const userAdmin = () => {
    return (user?.administrador as any) == 1 || (user?.e_operador as any) == 1;
  }

  const ePredio = dashboard?.last_leitura?.silo?.tipo_silo == 'Prédio';

  const ModalData = ({fontSize = '11px'}) => {
    return (
      <Flex pl={1} direction={"column"} fontSize={fontSize} >
        <Text><span style={{ fontWeight: '700' }}>{t('DashboardNome')}:</span> {dashboard?.last_leitura?.silo?.numero} - {dashboard?.last_leitura?.silo?.nome_referencia}</Text>
        <Text><span style={{ fontWeight: '700' }}>{t('DashboardAltura')}:</span> {formatValor(dashboard?.last_leitura?.silo?.altura ?? '')}m</Text>
        {tipo_silo == 'Cilindrico' && <Text><span style={{ fontWeight: '700' }}>{t('DashboardDiâmetro')}:</span> {formatValor(dashboard?.last_leitura?.silo?.diametro ?? '0')}m</Text>}
        {(tipo_silo == 'Graneleiro' || tipo_silo == 'Prédio') && <Text><span style={{ fontWeight: '700' }}>{t('DashboardLargura')}:</span> {formatValor(dashboard?.last_leitura?.silo?.largura ?? '0')}m</Text>}
        {(tipo_silo == 'Graneleiro' || tipo_silo == 'Prédio') && <Text><span style={{ fontWeight: '700' }}>{t('DashboardComprimento')}:</span> {formatValor(dashboard?.last_leitura?.silo?.comprimento ?? '0')}m</Text>}
        {parseFloat(dashboard?.last_leitura?.silo?.capacidade ?? '0') > 0 && <Text><span style={{ fontWeight: '700' }}>{t('DashboardCapacidade')}:</span> {formatValor(parseFloat(dashboard?.last_leitura?.silo?.capacidade ?? '0').toFixed(2))}ton</Text>}
        {dashboard?.last_leitura?.produto && getCapacidadeSilo() > 0 && <Text><span style={{ fontWeight: '700' }}>{t('DashboardCapacidadeEm')} <DashboardNomeProduto />:</span> {formatValor(getCapacidadeSilo().toFixed(2))}ton</Text>}
        <Text><span style={{ fontWeight: '700' }}>{t('DashboardVolume')}:</span> {formatValor(dashboard?.last_leitura?.volume ?? '0')}{ePredio ? 'm³' : 'ton'}</Text>
      </Flex>
    )
  }

  return (
    <>

      <Flex p={1} width={isMobile ? "100%" : "26%"} id="dashboard-dados-silo" maxH={isMobile ? '1000px' : '352px'} minH={"352px"} alignItems={'center'}>
        <Box pt={4} bg={"white"} borderRadius={25} boxShadow={"0 0 8px rgba(0, 0, 0, 0.2)"} height={"100%"}>
          <Silo selecteds={getSelected()} imagem={imagemSilo()} imagem2={imagemSilo2()}>
            <ModalData />
          </Silo>
          <Flex width={"full"} justifyContent={"center"} py={4} px={2}>
            <DashboardVerMais onOpen={onOpen} />
          </Flex>
        </Box>

        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"2xl"}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <ListHeaderSecondary label={t('DashboardDadosDoSilo')}>
                <DefaultButton onClick={onClose} ml={2}>{t('DashboardVoltar')}</DefaultButton>
              </ListHeaderSecondary>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody my={2}>

              {dashboard?.last_leitura?.silo && !isMobile && <Flex width={"full"} wrap={"wrap"}>
                <Flex width={"50%"} direction={"column"}>
                {(dashboard?.last_leitura?.e_leitura_de_6_silos as any) == 0 && <img style={{ margin: 'auto' }} width={"80%"} height={"auto"} src={imagemSilo()} />}
                {(dashboard?.last_leitura?.e_leitura_de_6_silos as any) == 0 && imagemSilo2() && <img style={{ margin: 'auto' }} width={"60%"} height={"auto"} src={imagemSilo2()} />}
                {(dashboard?.last_leitura?.e_leitura_de_6_silos as any) == 1 && <Img src={siloPontoCentral} mx={"auto"} width={"70%"} />}
                </Flex>
                <Flex px={6} width={"50%"} direction={"column"} justifyContent={"center"}>
                  <Flex >
                    <Silo injecoes={injecoes()} somentePontos={true} selecteds={getSelected()} imagem={imagemSilo()} imagem2={imagemSilo2()}>
                    </Silo>
                  </Flex>

                  <ModalData fontSize="16px" />
                  {userAdmin() && <Box width={"100%"}>
                    <DashboardStatus />
                  </Box>}


                </Flex>
              </Flex>}

              {dashboard?.last_leitura?.silo && isMobile && <Flex width={"full"} wrap={"wrap"}>
                <Flex width={"100%"} wrap={"wrap"}>
                  {(dashboard?.last_leitura?.e_leitura_de_6_silos as any) == 0 && <img style={{ margin: 'auto' }} width={"50%"} height={"auto"} src={imagemSilo()} />}
                  {(dashboard?.last_leitura?.e_leitura_de_6_silos as any) == 0 && imagemSilo2() && <img style={{ margin: 'auto' }} width={"50%"} height={"auto"} src={imagemSilo2()} />}
                  {(dashboard?.last_leitura?.e_leitura_de_6_silos as any) == 1 && <Img mx={"auto"} src={siloPontoCentral} width={"70%"} />}
                </Flex>
                <Flex px={6} width={"100%"} direction={"column"} justifyContent={"center"}>
                  <Flex >
                    <Silo injecoes={injecoes()} somentePontos={true} selecteds={getSelected()} imagem={imagemSilo()} imagem2={imagemSilo2()}>
                    </Silo>
                  </Flex>

                  <ModalData />
                  {userAdmin() && <Box width={"100%"}>
                    <DashboardStatus />
                  </Box>}


                </Flex>
              </Flex>}




            </ModalBody>
          </ModalContent>
        </Modal>
      </Flex>
    </>
  )
}