import { Button, ButtonProps } from "@chakra-ui/react"
import config from "../../../config"
import { useNavigate } from "react-router-dom";

interface IProps extends ButtonProps {
  to?: string;
}

export const BackButton = ({ children, to, ...props }: IProps) => {
  const navigate = useNavigate();

  const onClick = () => {
    if (!to) return;

    navigate(to);
  }

  return (
    <Button onClick={onClick} colorScheme="blackAlpha" {...props}>{children}</Button>
  )
}