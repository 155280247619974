import { AvaliacaoInicialAssinatura } from "../types/avaliacao_inicial_assinatura";

export const novaAvaliacaoInicialAssinatura =
  (): AvaliacaoInicialAssinatura => {
    return {
      avaliacao_inicial_id: "",
      user_id: "",
      tipo_pessoa: "ecozone",
      nome: "",
      assinatura: "",
      etapa: "",
    };
  };
