import { useIsAuth } from "../../hooks/useIsAuth"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { FiSend, FiTrash } from "react-icons/fi";
import { leituraActions } from "../reducer";
import ResponsiveTable, { TableHeaders } from "../../sistema/components/ResponsiveTable";
import Layout from "../../sistema/components/Layout";
import { ListHeader } from "../../sistema/components/ListHeader";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import Loader from "../../sistema/components/Loader";
import { ListInput } from "../../sistema/components/ListInput";
import { Pagination } from "../../sistema/components/Pagination";
import { ConfirmButton } from "../../sistema/components/ConfirmButton";
import { formatData } from "../../utils/data";
import { useTranslation } from "react-i18next";
import { AvaliacaoInicialStatus } from "../components/AvaliacaoInicialStatus";
import { AvaliacaoInicial } from "../types/avaliacao_inicial";
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import { mensagemErro } from "../../utils/toasts";
import { useIndexedDB } from 'react-indexed-db-hook';
import { Button, Flex, Img, Link } from "@chakra-ui/react";
import imagesUrl from "../../utils/imagesUrl";

const systemz = imagesUrl + "logos/systemz.png";

export const AvaliacaoInicialList = () => {
  useIsAuth();
  const avaliacoesIniciais = useSelector((state: RootState) => state.leituras.avaliacoesIniciais);
  const avaliacoesIniciaisAEnviar = useSelector((state: RootState) => state.leituras.avaliacoesParaEnviar);
  const leituraDadosSelectsInputs = useSelector((state: RootState) => state.leituras.leituraDadosSelectsInputs);
  const isLoading = useSelector((state: RootState) => !!state.leituras.isLoading);
  const success = useSelector((state: RootState) => !!state.leituras.success);
  const dispatch = useDispatch()
  const eDesenvolvedor = useSelector((state: RootState) => (state.sistema.user as any)?.e_desenvolvedor == 1);
  const [page, setPage] = useState(1);
  const [termo, setTermo] = useState("");
  const networkOnline = useSelector((state: RootState) => state.sistema.networkOnline);

  const { t } = useTranslation()

  useEffect(() => {
    if (!networkOnline) {
      return;
    }
    dispatch(leituraActions.avaliacoesIniciaisRequest({
      page,
      termo,
    }))
  }, [dispatch, page, termo])

  useEffect(() => {
    if (!success) {
      return;
    }
    if (!networkOnline) {
      return;
    }
    dispatch(leituraActions.avaliacoesIniciaisRequest({
      page,
      termo,
    }))
  }, [dispatch, success, page, termo])

  const { add, getAll } = useIndexedDB('images');

  const [imagesDb, setImagesDb] = useState<any>([]);

  useEffect(() => {
    const getData = async () => {
      const data = await getAll();
      setImagesDb(data);
    }
    console.log(`getData AvaliacaoInicialList`)
    getData();
  }, [getAll])

  const onSendAvaliacao = (tempId: string) => {
    if (!networkOnline) {
      mensagemErro(t('AvaliacaoInicialVoceEstaSemInternet'))
      return;
    }

    const avaliacao = avaliacoesIniciaisAEnviar?.find((i) => i.tempId == tempId);

    if (!avaliacao) return;

    dispatch(leituraActions.saveAvaliacaoInicialRequest({
      ...avaliacao,
      imagens: avaliacao.imagens?.map((i) => {
        return {
          ...i,
          imagem: i.imagemId
            ? imagesDb.find((j: any) => j.id == i.imagemId)?.name ?? ''
            : i.imagem,
        };
      }),
      assinaturas: avaliacao.assinaturas?.map((k) => {
        return {
          ...k,
          assinatura: k.assinaturaId
            ? imagesDb.find((j: any) => j.id == k.assinaturaId)?.name ?? ''
            : k.assinatura,
        };
      }),
      insetos: avaliacao.insetos?.map((k) => {
        return {
          ...k,
          imagem: k.imagemId
            ? imagesDb.find((j: any) => j.id == k.imagemId)?.name ?? ''
            : k.imagem,
        };
      }),
      insetos_final: avaliacao.insetos_final?.map((k) => {
        return {
          ...k,
          imagem: k.imagemId
            ? imagesDb.find((j: any) => j.id == k.imagemId)?.name ?? ''
            : k.imagem,
        };
      }),
    }));

  }

  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);

  const headers: TableHeaders<AvaliacaoInicial>[] = [
    {
      label: "Id",
      wrapped: true,
      render: (item) => item.id,
    },
    {
      label: t('LeiturasListData'),
      wrapped: true,
      render: (item) => formatData(item.data_hora),
    },
    {
      label: t('LeiturasListCliente'),
      wrapped: false,
      render: (item) => item?.cliente?.nome,
    },
    {
      label: t('DashboardSilo'),
      wrapped: false,
      render: (item) => `${item?.silo?.numero} - ${item?.silo?.nome_referencia}`,
    },
    {
      label: t('DashboardUnidade'),
      wrapped: !isMobile,
      render: (item) => item?.unidade?.nome_referencia,
    },
    {
      label: t('SistemaAções'),
      wrapped: true,
      render: (item, index: any) => <>{eDesenvolvedor && <ConfirmButton confirm={() => onDeleteItem(item.id as number)}><FiTrash /></ConfirmButton>}
        {item.relatorio ? <Link href={item.relatorio} target="_blank" rel="noopener noreferrer">
          <Button size={"sm"} ml={1} colorScheme={'green'}>
            {t('SistemaImprimirRelatorio')}
          </Button>
        </Link> :
          <Button size={"sm"} ml={1} colorScheme={'blackAlpha'}>
            {t('SistemaGerandoRelatorio')}
          </Button>}
      </>,
      notClicable: true,
    },
  ];

  const headersTemp: TableHeaders<AvaliacaoInicial>[] = [
    {
      label: "Id",
      wrapped: true,
      render: (item) => item.tempId,
    },
    {
      label: t('LeiturasListData'),
      wrapped: true,
      render: (item) => formatData(item.data_hora),
    },
    {
      label: t('LeiturasListCliente'),
      wrapped: false,
      render: (item) => leituraDadosSelectsInputs?.clientes?.find((i) => i.id == item.cliente_id as any)?.nome,
    },
    {
      label: t('SistemaAções'),
      wrapped: true,
      render: (item: any, index: any) => <>
        {eDesenvolvedor && <ConfirmButton confirm={() => onDeleteItemLocal(item.tempId)}><FiTrash /></ConfirmButton>} &nbsp;
        <ConfirmButton colorSheme="blue" confirm={() => onSendAvaliacao(item.tempId)}><FiSend /></ConfirmButton>
      </>,
      notClicable: true,
    },
  ];

  const onDeleteItem = (id: number) => {
    dispatch(leituraActions.deleteAvaliacaoInicialRequest(id));
  }

  const onDeleteItemLocal = (id: number) => {
    dispatch(leituraActions.deleteAvaliacaoInicialLocalRequest(id));
  }

  const navigate = useNavigate();

  return (
    <Layout showInternetStatus={true}>
      <ListHeader label={t('AvaliacaoInicialTituloList')}>
        <DefaultButton ml={4} to="/avaliacoes-iniciais/novo"> {t('SistemaNova')} </DefaultButton>
      </ListHeader>
      <Flex width={"full"} position={"relative"}>
        {!isMobile && <Img width={"100px"} height={"100px"} zIndex={999} src={systemz} position={"absolute"} top={"30px"} left={"20px"} />}
        {isMobile && <Img mx={"auto"} my={2} width={"100px"} height={"100px"} src={systemz} />}
      </Flex>
      <AvaliacaoInicialStatus />

      <Loader isLoading={isLoading} />

      <ListHeaderSecondary label={t('AvaliacaoInicialASeremEnviadas')} />

      {avaliacoesIniciaisAEnviar && <ResponsiveTable onClick={(item: any) => navigate(`/avaliacoes-iniciais/${item.item.tempId}`)} headers={headersTemp} data={avaliacoesIniciaisAEnviar} />}


      <ListHeaderSecondary label={t('AvaliacaoInicialEnviadas')} />

      <ListInput label={t('AvaliacaoInicialTermo')} onSubmit={setTermo} />

      {avaliacoesIniciais && <ResponsiveTable onClick={(item: any) => navigate(`/avaliacoes-iniciais/${item.item.id}`)} headers={headers} data={avaliacoesIniciais.data} />}

      {avaliacoesIniciais && <Pagination info={avaliacoesIniciais} paginate={setPage} />}
    </Layout>
  )
}