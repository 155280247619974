import { Equipamento } from "../types/equipamento";

export const novoEquipamento = (): Equipamento => {
  return {
    nome_referencia: "",
    endereco_registro: "",
    quantidade_leitura_vida_util: "0",
    quantidades_leituras_feitas: "0",
    tempo_espera_um_cilo_e_outro: 0,
    tempo_espera_purge: 0,
    tempo_espera_inicio_leitura: 0,
    tempo_espera_por_comando_enviado: 0,
    multiplo_p1: "",
    multiplo_p2: "",
    multiplo_p3: "",
    multiplo_p4: "",
    multiplo_p5: "",
    multiplo_p6: "",
    multiplo_p7: "",
    multiplo_p8: "",
    multiplo_p9: "",
    multiplo_p10: "",
    multiplo_p11: "",
    multiplo_p12: "",
    multiplo_p13: "",
    multiplo_p14: "",
    multiplo_p15: "",
    multiplo_p16: "",
  };
};
