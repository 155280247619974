import { PayloadAction } from "@reduxjs/toolkit";
import { put, all, takeLatest } from "redux-saga/effects";
import axios, { AxiosResponse } from "axios";
import { call } from "redux-saga/effects";
import { IApiResponse } from "./types/apiRes";
import { User } from "./types/user";
import { sistemaActions } from "./recucer";
import { APIURL, apiCall } from "../utils/apiCall";
import { formatError } from "../utils/formatError";
import { ListPayload } from "./types/listPayload";
import { ApiPaginationRes } from "./types/apiPaginationRes";
import { Cliente } from "./types/cliente";
import { novoCliente } from "./data/cliente";
import { Equipamento } from "./types/equipamento";
import { novoEquipamento } from "./data/equipamento";
import { novoUser } from "./data/user";
import { UsuarioUnidade } from "./types/user_unidades";
import { EquipamentoCiclo } from "./types/equipamento_ciclo";
import { Inseto } from "./types/inseto";
import { novoInseto } from "./data/inseto";
import { novaAvaliacaoInicialQuestao } from "./data/avaliacao_inicial_questao";
import { AvaliacaoInicialQuestao } from "./types/avaliacao_inicial_questao";
import { TipoFosfina } from "./types/tipo_fosfina";
import { novoTipoFosfina } from "./data/tipo_fosfina";
import { novoCaminhao } from "./data/caminhao";
import { Caminhao } from "./types/caminhao";
import { AvaliacaoInicial } from "../leituras/types/avaliacao_inicial";
import { novaAvaliacaoInicial } from "../leituras/data/avaliacao_inicial";

interface ILogin {
  email: string;
  password: string;
  userAgent?: string;
}

export interface TLoginRes extends IApiResponse {
  data: {
    user: User;
    token: string;
  };
}

function loginCall(payload: ILogin) {
  return axios.post(`${APIURL}/login`, {
    email: payload.email,
    password: payload.password,
    userAgent: payload.userAgent,
  });
}

function* loginWorker({ payload }: PayloadAction<ILogin>) {
  try {
    const res: AxiosResponse<TLoginRes> = yield call(loginCall, payload);
    yield put(sistemaActions.loginSuccess(res.data));
  } catch (error: any) {
    yield put(sistemaActions.loginError(formatError(error)));
  }
}

function* clientesWorker({ payload }: PayloadAction<ListPayload>) {
  try {
    const res: AxiosResponse<ApiPaginationRes<Cliente[]>> = yield call(
      apiCall,
      {
        url: `clientes?termo=${payload.termo}&page=${payload.page}`,
        method: "get",
        data: payload,
      }
    );
    yield put(sistemaActions.clientesRequestSuccess(res.data));
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* clienteWorker({ payload }: PayloadAction<string>) {
  try {
    if (payload === "novo") {
      yield put(sistemaActions.clienteRequestSuccess(novoCliente()));
      return;
    }

    const res: AxiosResponse<Cliente> = yield call(apiCall, {
      url: `clientes/${payload}?include=unidades,unidades.responsaveis,unidades.responsaveis.usuario,unidades.silos`,
      method: "get",
      data: payload,
    });
    yield put(
      sistemaActions.clienteRequestSuccess(res.data)
    );
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* responsaveisWorker() {
  try {
    const res: AxiosResponse<User[]> = yield call(apiCall, {
      url: `responsaveis`,
      method: "get",
    });
    yield put(sistemaActions.responsaveisRequestSuccess(res.data));
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* todosClientesWorker() {
  try {
    const res: AxiosResponse<Cliente[]> = yield call(apiCall, {
      url: `todos-clientes`,
      method: "get",
    });
    yield put(sistemaActions.todosClientesRequestSuccess(res.data));
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* saveUsuarioWorker({ payload }: PayloadAction<User>) {
  try {
    const res: AxiosResponse<User> = yield call(apiCall, {
      url: `usuario?include=acessos,unitsWithAccessInBodyCenter.clientUnit,unitsWithAccessInBodyCenter.client`,
      method: "post",
      data: payload,
    });
    yield put(
      sistemaActions.saveUsuarioRequestSuccess({
        ...res.data,
        administrador: (res.data.administrador as any) == "1" ? true : false,
        ativo: res.data.ativo == ("1" as any) ? true : false,
        e_desenvolvedor:
          res.data.e_desenvolvedor == ("1" as any) ? true : false,
        e_operador: res.data.e_operador == ("1" as any) ? true : false,
      })
    );

    const res2: AxiosResponse<User[]> = yield call(apiCall, {
      url: `responsaveis`,
      method: "get",
    });
    yield put(sistemaActions.responsaveisRequestSuccess(res2.data));
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* saveClientesWorker({ payload }: PayloadAction<Cliente>) {
  try {
    const res: AxiosResponse<Cliente> = yield call(apiCall, {
      url: `clientes?include=unidades,unidades.responsaveis,unidades.responsaveis.usuario,unidades.silos`,
      method: "post",
      data: {
        ...payload,
        changed: true,
        unidades: payload.unidades.map((u) => ({
          ...u,
          body_center_map_points: JSON.stringify(u.body_center_map_points ?? []),
          body_center_map_position: JSON.stringify(u.body_center_map_position ?? { scale: 1, x: 0, y: 0 }),
        })),
      },
    });
    yield put(
      sistemaActions.saveClientesRequestSuccess(res.data)
    );
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* saveEquipamentosRequestWorker({
  payload,
}: PayloadAction<Equipamento>) {
  try {
    const res: AxiosResponse<Equipamento> = yield call(apiCall, {
      url: `equipamentos`,
      method: "post",
      data: payload,
    });
    yield put(sistemaActions.saveEquipamentosRequestSuccess(res.data));
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* equipamentosRequestWorker({ payload }: PayloadAction<ListPayload>) {
  try {
    const res: AxiosResponse<ApiPaginationRes<Equipamento[]>> = yield call(
      apiCall,
      {
        url: `equipamentos?termo=${payload.termo}&page=${payload.page}`,
        method: "get",
        data: payload,
      }
    );
    yield put(sistemaActions.equipamentosRequestSuccess(res.data));
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* equipamentoRequestWorker({ payload }: PayloadAction<string>) {
  try {
    if (payload === "novo") {
      yield put(sistemaActions.equipamentoRequestSuccess(novoEquipamento()));
      return;
    }

    const res: AxiosResponse<Equipamento> = yield call(apiCall, {
      url: `equipamentos/${payload}`,
      method: "get",
      data: payload,
    });
    yield put(sistemaActions.equipamentoRequestSuccess(res.data));
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* deleteEquipamentosRequestWorker({ payload }: PayloadAction<string>) {
  try {
    yield call(apiCall, {
      url: `equipamentos/${payload}`,
      method: "delete",
      data: payload,
    });
    yield put(sistemaActions.deleteEquipamentosRequestSuccess());
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* deleteClientesRequestWorker({ payload }: PayloadAction<string>) {
  try {
    yield call(apiCall, {
      url: `clientes/${payload}`,
      method: "delete",
      data: payload,
    });
    yield put(sistemaActions.deleteClientesRequestSuccess());
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* usuariosUnidadesRequestWorker({ payload }: PayloadAction<string>) {
  try {
    const res: AxiosResponse<UsuarioUnidade[]> = yield call(apiCall, {
      url: `usuario-unidades/${payload}`,
      method: "get",
    });
    yield put(sistemaActions.usuariosUnidadesRequestSuccess(res.data));
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* deleteUsuariosRequestWorker({ payload }: PayloadAction<string>) {
  try {
    yield call(apiCall, {
      url: `usuarios/${payload}`,
      method: "delete",
      data: payload,
    });
    yield put(sistemaActions.deleteUsuariosRequestSuccess());
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* usuariosRequestWorker({ payload }: PayloadAction<ListPayload>) {
  try {
    const res: AxiosResponse<ApiPaginationRes<User[]>> = yield call(apiCall, {
      url: `usuarios?termo=${payload.termo}&page=${payload.page}`,
      method: "get",
      data: payload,
    });
    yield put(sistemaActions.usuariosRequestSuccess(res.data));
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* usuarioRequestWorker({ payload }: PayloadAction<string>) {
  try {
    if (payload === "novo") {
      yield put(sistemaActions.usuarioRequestSuccess(novoUser()));
      return;
    }

    const res: AxiosResponse<User> = yield call(apiCall, {
      url: `usuarios/${payload}?include=acessos,unitsWithAccessInBodyCenter.clientUnit,unitsWithAccessInBodyCenter.client`,
      method: "get",
      data: payload,
    });
    yield put(
      sistemaActions.usuarioRequestSuccess({
        ...res.data,
        administrador: (res.data.administrador as any) == "1" ? true : false,
        ativo: res.data.ativo == ("1" as any) ? true : false,
        e_desenvolvedor:
          res.data.e_desenvolvedor == ("1" as any) ? true : false,
        e_operador: res.data.e_operador == ("1" as any) ? true : false,
      })
    );
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* equipamentoUltimasLeiturasRequestWorker({
  payload,
}: PayloadAction<string | number>) {
  try {
    const res: AxiosResponse<EquipamentoCiclo[]> = yield call(apiCall, {
      url: `equipamentos-ciclos/${payload}`,
      method: "get",
    });
    yield put(
      sistemaActions.equipamentoUltimasLeiturasRequestSuccess(res.data)
    );
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* equipamentoUltimasLeiturasDeleteRequestWorker({
  payload,
}: PayloadAction<{ id: string | number; equipamento_id: string | number }>) {
  try {
    yield call(apiCall, {
      url: `equipamentos-ciclos/${payload.id}`,
      method: "delete",
    });
    const res: AxiosResponse<EquipamentoCiclo[]> = yield call(apiCall, {
      url: `equipamentos-ciclos/${payload.equipamento_id}`,
      method: "get",
    });
    yield put(
      sistemaActions.equipamentoUltimasLeiturasDeleteRequestSuccess(res.data)
    );
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* insetosRequestWorker({ payload }: PayloadAction<ListPayload>) {
  try {
    const res: AxiosResponse<ApiPaginationRes<Inseto[]>> = yield call(apiCall, {
      url: `insetos?termo=${payload.termo}&page=${payload.page}`,
      method: "get",
      data: payload,
    });
    yield put(sistemaActions.insetosRequestSuccess(res.data));
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* insetoRequestWorker({ payload }: PayloadAction<string>) {
  try {
    if (payload === "novo") {
      yield put(sistemaActions.insetoRequestSuccess(novoInseto()));
      return;
    }

    const res: AxiosResponse<Inseto> = yield call(apiCall, {
      url: `insetos/${payload}`,
      method: "get",
      data: payload,
    });
    yield put(
      sistemaActions.insetoRequestSuccess({
        ...res.data,
        ativo: (res.data as any).ativo == 1,
        imagem_grande: (res.data as any).imagem_grande == 1,
      })
    );
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* deleteInsetosRequestWorker({ payload }: PayloadAction<string>) {
  try {
    yield call(apiCall, {
      url: `insetos/${payload}`,
      method: "delete",
      data: payload,
    });
    yield put(sistemaActions.deleteInsetosRequestSuccess());
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* saveInsetosRequestWorker({ payload }: PayloadAction<Cliente>) {
  try {
    const res: AxiosResponse<Inseto> = yield call(apiCall, {
      url: `insetos`,
      method: "post",
      data: payload,
    });
    yield put(
      sistemaActions.saveInsetosRequestSuccess({
        ...res.data,
        ativo: (res.data as any).ativo == 1,
        imagem_grande: (res.data as any).imagem_grande == 1,
      })
    );
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* avaliacaoQuestoesRequestWorker({
  payload,
}: PayloadAction<ListPayload>) {
  try {
    const res: AxiosResponse<ApiPaginationRes<AvaliacaoInicialQuestao[]>> =
      yield call(apiCall, {
        url: `avaliacao-questoes?termo=${payload.termo}&page=${payload.page}`,
        method: "get",
        data: payload,
      });
    yield put(sistemaActions.avaliacaoQuestoesRequestSuccess(res.data));
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* avaliacaoQuestaoRequestWorker({ payload }: PayloadAction<string>) {
  try {
    if (payload === "novo") {
      yield put(
        sistemaActions.avaliacaoQuestaoSuccess(novaAvaliacaoInicialQuestao())
      );
      return;
    }

    const res: AxiosResponse<AvaliacaoInicialQuestao> = yield call(apiCall, {
      url: `avaliacao-questoes/${payload}`,
      method: "get",
      data: payload,
    });
    yield put(
      sistemaActions.avaliacaoQuestaoSuccess({
        ...res.data,
        ativo: (res.data as any).ativo == 1,
        e_sinaleiro: (res.data as any).e_sinaleiro == 1,
      })
    );
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* deleteAvaliacaoQuestaoRequestWorker({
  payload,
}: PayloadAction<string>) {
  try {
    yield call(apiCall, {
      url: `avaliacao-questoes/${payload}`,
      method: "delete",
      data: payload,
    });
    yield put(sistemaActions.deleteAvaliacaoQuestaoRequestSuccess());
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* saveAvaliacaoQuestaoRequestWorker({
  payload,
}: PayloadAction<Cliente>) {
  try {
    const res: AxiosResponse<AvaliacaoInicialQuestao> = yield call(apiCall, {
      url: `avaliacao-questoes`,
      method: "post",
      data: payload,
    });
    yield put(
      sistemaActions.saveAvaliacaoQuestaoRequestSuccess({
        ...res.data,
        ativo: (res.data as any).ativo == 1,
        e_sinaleiro: (res.data as any).e_sinaleiro == 1,
      })
    );
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* tiposFosfinaRequestWorker({ payload }: PayloadAction<ListPayload>) {
  try {
    const res: AxiosResponse<ApiPaginationRes<TipoFosfina[]>> = yield call(
      apiCall,
      {
        url: `tipo-fosfina?termo=${payload.termo}&page=${payload.page}`,
        method: "get",
        data: payload,
      }
    );
    yield put(sistemaActions.tiposFosfinaRequestSuccess(res.data));
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* tipoFosfinaRequestWorker({ payload }: PayloadAction<string>) {
  try {
    if (payload === "novo") {
      yield put(sistemaActions.tipoFosfinaSuccess(novoTipoFosfina()));
      return;
    }

    const res: AxiosResponse<TipoFosfina> = yield call(apiCall, {
      url: `tipo-fosfina/${payload}`,
      method: "get",
      data: payload,
    });
    yield put(
      sistemaActions.tipoFosfinaSuccess({
        ...res.data,
        ativo: (res.data as any).ativo == 1,
      })
    );
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* deleteTipoFosfinaRequestWorker({ payload }: PayloadAction<string>) {
  try {
    yield call(apiCall, {
      url: `tipo-fosfina/${payload}`,
      method: "delete",
      data: payload,
    });
    yield put(sistemaActions.deleteTipoFosfinaRequestSuccess());
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* saveTipoFosfinaRequestWorker({ payload }: PayloadAction<Cliente>) {
  try {
    const res: AxiosResponse<TipoFosfina> = yield call(apiCall, {
      url: `tipo-fosfina`,
      method: "post",
      data: payload,
    });
    yield put(
      sistemaActions.saveTipoFosfinaRequestSuccess({
        ...res.data,
        ativo: (res.data as any).ativo == 1,
      })
    );
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* caminhoesRequestWorker({ payload }: PayloadAction<ListPayload>) {
  try {
    const res: AxiosResponse<ApiPaginationRes<Caminhao[]>> = yield call(
      apiCall,
      {
        url: `caminhao?termo=${payload.termo}&page=${payload.page}`,
        method: "get",
        data: payload,
      }
    );
    yield put(sistemaActions.caminhoesRequestSuccess(res.data));
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* caminhaoRequestWorker({ payload }: PayloadAction<string>) {
  try {
    if (payload === "novo") {
      yield put(sistemaActions.caminhaoRequestSuccess(novoCaminhao()));
      return;
    }

    const res: AxiosResponse<Caminhao> = yield call(apiCall, {
      url: `caminhao/${payload}`,
      method: "get",
      data: payload,
    });
    yield put(
      sistemaActions.caminhaoRequestSuccess({
        ...res.data,
        ativo: (res.data as any).ativo == 1,
      })
    );
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* deleteCaminhaoRequestWorker({ payload }: PayloadAction<string>) {
  try {
    yield call(apiCall, {
      url: `caminhao/${payload}`,
      method: "delete",
      data: payload,
    });
    yield put(sistemaActions.deleteCaminhaoRequestSuccess());
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* saveCaminhaoRequestWorker({ payload }: PayloadAction<Cliente>) {
  try {
    const res: AxiosResponse<Caminhao> = yield call(apiCall, {
      url: `caminhao`,
      method: "post",
      data: payload,
    });
    yield put(
      sistemaActions.saveCaminhaoRequestSuccess({
        ...res.data,
        ativo: (res.data as any).ativo == 1,
      })
    );
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

export function* sistemaSaga() {
  yield all([
    takeLatest(
      "sistema/equipamentoUltimasLeiturasRequest",
      equipamentoUltimasLeiturasRequestWorker
    ),
  ]);
  yield all([takeLatest("sistema/loginRequest", loginWorker)]);
  yield all([takeLatest("sistema/clientesRequest", clientesWorker)]);
  yield all([takeLatest("sistema/clienteRequest", clienteWorker)]);
  yield all([takeLatest("sistema/responsaveisRequest", responsaveisWorker)]);
  yield all([takeLatest("sistema/todosClientesRequest", todosClientesWorker)]);
  yield all([takeLatest("sistema/saveUsuarioRequest", saveUsuarioWorker)]);
  yield all([takeLatest("sistema/saveClientesRequest", saveClientesWorker)]);
  yield all([
    takeLatest("sistema/equipamentosRequest", equipamentosRequestWorker),
  ]);
  yield all([
    takeLatest("sistema/equipamentoRequest", equipamentoRequestWorker),
  ]);
  yield all([
    takeLatest(
      "sistema/saveEquipamentosRequest",
      saveEquipamentosRequestWorker
    ),
  ]);
  yield all([
    takeLatest(
      "sistema/deleteEquipamentosRequest",
      deleteEquipamentosRequestWorker
    ),
  ]);
  yield all([
    takeLatest("sistema/deleteClientesRequest", deleteClientesRequestWorker),
  ]);
  yield all([
    takeLatest("sistema/deleteUsuariosRequest", deleteUsuariosRequestWorker),
  ]);
  yield all([takeLatest("sistema/usuarioRequest", usuarioRequestWorker)]);
  yield all([takeLatest("sistema/usuariosRequest", usuariosRequestWorker)]);
  yield all([
    takeLatest(
      "sistema/usuariosUnidadesRequest",
      usuariosUnidadesRequestWorker
    ),
  ]);
  yield all([
    takeLatest(
      "sistema/equipamentoUltimasLeiturasDeleteRequest",
      equipamentoUltimasLeiturasDeleteRequestWorker
    ),
  ]);
  yield all([
    takeLatest("sistema/insetosRequest", insetosRequestWorker),
    takeLatest("sistema/insetoRequest", insetoRequestWorker),
    takeLatest("sistema/saveInsetosRequest", saveInsetosRequestWorker),
    takeLatest("sistema/deleteInsetosRequest", deleteInsetosRequestWorker),
    takeLatest(
      "sistema/avaliacaoQuestoesRequest",
      avaliacaoQuestoesRequestWorker
    ),
    takeLatest(
      "sistema/avaliacaoQuestaoRequest",
      avaliacaoQuestaoRequestWorker
    ),
    takeLatest(
      "sistema/saveAvaliacaoQuestaoRequest",
      saveAvaliacaoQuestaoRequestWorker
    ),
    takeLatest(
      "sistema/deleteAvaliacaoQuestaoRequest",
      deleteAvaliacaoQuestaoRequestWorker
    ),
    takeLatest("sistema/tiposFosfinaRequest", tiposFosfinaRequestWorker),
    takeLatest("sistema/tipoFosfinaRequest", tipoFosfinaRequestWorker),
    takeLatest("sistema/saveTipoFosfinaRequest", saveTipoFosfinaRequestWorker),
    takeLatest(
      "sistema/deleteTipoFosfinaRequest",
      deleteTipoFosfinaRequestWorker
    ),
    takeLatest("sistema/caminhoesRequest", caminhoesRequestWorker),
    takeLatest("sistema/caminhaoRequest", caminhaoRequestWorker),
    takeLatest("sistema/saveCaminhaoRequest", saveCaminhaoRequestWorker),
    takeLatest("sistema/deleteCaminhaoRequest", deleteCaminhaoRequestWorker),
  ]);
}
