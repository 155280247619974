import { store } from "../../app/mainReducer";
import { getCurrentData } from "../../utils/data";
import { Leitura } from "../types/leitura";

export const getUser = () => store.getState().sistema.user;
export const getUsuarios = () => store.getState().leituras.leituraDadosSelectsInputs;

export const novaLeitura = (): Leitura => {
  return {
    data: getCurrentData(),
    data_abertura: "",
    data_fechamento: "",
    tempo_exposicao: 0,
    produto: "",
    volume: 0,
    finalizado: false,
    cliente_id: "",
    cliente_unidade_id: "",
    cliente_unidade_silo_id: "",
    equipamento_id: "",
    ordem_servico: "",
    tipo_leitura: "PH3 (Fosfina)",
    data_inicio_hermeticidade: "",
    range_calculo_concentracao: 400,
    range_calculo_concentracao2: 600,
    range_calculo_concentracao3: 800,
    range_eficiencia: 800,
    tipo_configuracao: 0,
    range_reaplicacao: 600,
    e_leitura_de_6_silos: false,
    token: '',
    dados: [],
    responsaveis: [
      {
        leitura_id: "",
        user_id: getUser()?.id as any,
        usuario: getUsuarios()?.administradores.find((i: any) => i.id == getUser()?.id),
      }
    ],
    sensores: [],
    e_leitura_de_silo_cilindrico_mas_com_preparacao_predio: false,
  };
};
